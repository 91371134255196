import axios from "axios";
import {
  CREATE_RESEARCH_DATA_URL,
  GET_RESEARCH_DATA_BY_ID_URL,
  UPDATE_RESEARCH_DATA_URL,
  DELETE_RESEARCH_DATA_URL,
  LIST_RESEARCH_DATA_URL,
  BLOB_UPLOAD,
  BLOB_DOWNLOAD
} from "./apiConfig";
import authHeader from "./AuthHeader";

class ResearchDataService {
  createResearchData(reportData) {
    return axios
      .post(CREATE_RESEARCH_DATA_URL, reportData, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getResearchDataById(id) {
    return axios
      .post(GET_RESEARCH_DATA_BY_ID_URL, { id }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateResearchData(id, reportData) {
    return axios.put(`${UPDATE_RESEARCH_DATA_URL}`, reportData, { headers: authHeader()})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error updating research data:", error);
      throw error; // Optionally handle the error appropriately
    });
  }
  

  deleteResearchData(id) {
    console.log("id", id);
    return axios
      .delete(DELETE_RESEARCH_DATA_URL + id, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  listReports() {
    return axios
      .get(LIST_RESEARCH_DATA_URL, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log("respons from list", response);
        return response;
      });
  }

  uploadBlob(formData) {
    return axios.post(
      BLOB_UPLOAD,
      formData, 
      {
        headers: {
          ...authHeader(), 
          'Content-Type': 'multipart/form-data', // Optional: axios usually handles this automatically
        },
      }
    );
  }

  downloadBlob(id){
    return axios.get(
      BLOB_DOWNLOAD+"/"+id,{
        headers: {
          ...authHeader(), 
          'Content-Type': 'multipart/form-data', // Optional: axios usually handles this automatically
        },
      }
    )
  }
  
}

export default new ResearchDataService();
