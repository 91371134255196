import { Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {
  Table,
  Button,
  Form,
  Image,
  Nav,
  Modal,
  Pagination,
} from "react-bootstrap";
import "../table.css";
import editIcon from "../assets/icons/edit.png";
import { useEffect, useState } from "react";
import researchDataService from "../services/researchDataService";
import deleteIcon from "../assets/icons/delete.png";
import LogoutAndRedirect from "./LogoutAndRedirect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DataReport = () => {
  const navigate = useNavigate();
  const [deleteItem, setDeleteItem] = useState("");

  const [reports, setReports] = useState([]);
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await researchDataService
      .listReports()
      .then((res) => {
        console.log("res", res.data.data);
        const sortedData = res.data.data.sort(
          (a, b) => b.updatedAt - a.updatedAt
        );
        // setReports(sortedReports);
        setReports(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCall = async () => {
    await researchDataService
      .deleteResearchData(deleteItem.id)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLastElement = (str) => {
    const parts = str.split(".");
    return parts.pop();
  };

  const handleDownload = async (blob, fileName) => {
    const filename = fileName;
    // e.preventDefault();
    try {
      console.log("in download", blob);
      const extension = getLastElement(blob);
      // Make an API call to download the file as a blob
      await researchDataService.downloadBlob(blob).then((res) => {
        const base64Data = res?.data?.data;
        if (!base64Data) {
          throw new Error("No data found to download");
        }
        // Convert base64 to a Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredReports =
    reports?.length > 0
      ? reports?.filter((profile) =>
          profile?.fileName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      : [];

  // Calculate total pages and data slice for Tab 1
  const totalPages = Math.ceil(filteredReports?.length / 10);
  const startIndex = (currentPage - 1) * 10;
  const currentReports = filteredReports?.slice(startIndex, startIndex + 10);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    const validPattern = /^[a-zA-Z0-9.@_,-]*$/;
    if (validPattern.test(value)) {
        setSearchQuery(value);
        setCurrentPage(1);
    } else {
      console.log("Invalid character detected. Only letters, numbers, ., @, -, and _ are allowed.");
    }
};

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-sm-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Research Data</h5>
                    </div>
                    <div class="ms-auto d-sm-flex align-items-center">
                    <div className="me-0 me-sm-2 mb-sm-0 mb-2 mt-2 mt-sm-0 mb-sm-0">
                        <Form.Group
                          style={{ position: "relative" }}
                          controlId="formBasicSearch"
                        >
                          <svg
                            style={{
                              position: "absolute",
                              top: "8px",
                              left: "10px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.5835 14.5831L18.3335 18.3331"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Form.Control
                            type="text"
                            className="form-search-field w-100"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => {
                              handleSearchChange(e);
                            }}
                          />
                        </Form.Group>
                      </div>
                      <Button
                        variant=""
                        style={{ fontSize: "14px", whiteSpace:'nowrap' }}
                        href="./AddDataReport"
                        className="d-inline-flex text-left align-items-center text-left"
                      >
                        <i class="fa-solid fa-circle-plus text-white me-2"></i> Add
                        Reseach Data
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <Row>
                    {/* <Col lg={12}>
                      <div>
                        <Form.Group
                          style={{ padding: "10px 10px", position: "relative" }}
                          controlId="formBasicSearch"
                        >
                          <svg
                            style={{
                              position: "absolute",
                              top: "18px",
                              left: "20px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.5835 14.5831L18.3335 18.3331"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Form.Control
                            type="text"
                            className="form-search-field w-25"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => {
                              handleSearchChange(e);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </Col> */}
                    <Col lg={12}>
                      <Table bordered hover responsive className="mt-3">
                        <thead className="table">
                          <tr>
                            <th className="no-wrap-header">File Name</th>
                            <th className="no-wrap-header">File Description</th>
                            {/* <th className="no-wrap-header">Workspace ID</th> */}
                            <th className="no-wrap-header">Total Subscribers</th>
                            <th className="no-wrap-header">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentReports.length > 0 ? (
                            currentReports.map((report, index) => (
                              <tr key={index}>
                                <td className="no-wrap-header">
                                  <Nav.Link
                                    className="text-decoration-underline"
                                    onClick={() => {
                                      navigate(`/AddDataReport/${report.id}`);
                                    }}
                                  >
                                    {report.fileName || "-"}
                                  </Nav.Link>
                                </td>
                                <td>
                                  <p className="overflow-description">
                                    {report.fileDescription}
                                  </p>
                                </td>
                                {/* <td>{report.workspaceID}</td> */}
                                <td>{report.researchDataCount}</td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Nav.Link
                                      variant="link"
                                      onClick={() => {
                                        navigate(`/AddDataReport/${report.id}`);
                                      }}
                                      className="me-3"
                                    >
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </Nav.Link>
                                    <Nav.Link
                                      onClick={(e) => {
                                        handleDownload(
                                          report.blobLocation,
                                          report.fileName
                                        );
                                      }}
                                      variant="link"
                                      className="text-danger"
                                    >
                                      <span
                                        className="fa fa-download me-2"
                                        style={{
                                          // width: "14px",
                                          fontSize: "12px",
                                          // paddingLeft: "20px",
                                        }}
                                      />
                                    </Nav.Link>
                                    <Nav.Link
                                      onClick={(e) => {
                                        setDeleteItem({
                                          id: report.id,
                                          name: report.fileName,
                                        });
                                        handleShow();
                                      }}
                                      variant="link"
                                      className="text-danger"
                                    >
                                      <Image
                                        style={{
                                          width: "14px",
                                          marginLeft: "9px",
                                        }}
                                        src={deleteIcon}
                                      />
                                    </Nav.Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan={4}>
                                <div className="d-flex justify-content-center">
                                  <p>No data found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg={12}>
                      <Pagination style={{ justifyContent: "end" }}>
                        <Pagination.First
                          onClick={() => handlePageChange(1)}
                          disabled={currentPage === 1}
                        />
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />

                        {/* Generate page numbers dynamically */}
                        {[...Array(totalPages)].map((_, pageNumber) => (
                          <Pagination.Item
                            key={pageNumber + 1}
                            active={pageNumber + 1 === currentPage}
                            onClick={() => handlePageChange(pageNumber + 1)}
                          >
                            {pageNumber + 1}
                          </Pagination.Item>
                        ))}

                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="text-center custom-modal"
      >
        <Modal.Header className="justify-content-center border-0">
          <Modal.Title>
            <h4 className="fw-700">Confirm Delete</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {deleteItem.name}? This action
            cannot be undone
          </p>{" "}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button
            className="btn-light"
            style={{ fontSize: "14px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-danger"
            style={{ fontSize: "14px" }}
            variant="danger"
            onClick={() => {
              deleteCall();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DataReport;
