import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Image, Form, Nav } from "react-bootstrap";
import "../../../src/dashboard.css";
import preview from "../../assets/icons/view.png";
import { useNavigate } from "react-router-dom";
import researchDataService from "../../services/researchDataService";

const CustomCardWithProduct = ({
  id,
  error,
  title,
  description,
  imageSrc,
  blobLocation,
  altText,
  onSelectProduct,
  onPreview,
  checkbox,
  showSelectButton = true,
  showPreviewButton = true,
  
}) => {
  const navigate = useNavigate();
  const [docUrl, setDocUrl] = useState('');

  useEffect(() => {
    if (blobLocation !== 'NoPreviewFound' && !docUrl) {
      try {
        researchDataService.downloadBlob(blobLocation).then((res) => {
          const base64Data = res?.data?.data
          if (base64Data) {
            // Convert base64 to a Blob
            const byteCharacters = atob(base64Data);
            const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const docUrl = URL.createObjectURL(blob);
            setDocUrl(docUrl);
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  }, [docUrl, blobLocation]);

  // console.log("checkbox.checked",title,checkbox.checked)
  return (
    <Col lg={4} sm={6} className="d-flex mb-4">
      {checkbox && (
        <Form.Check
        checked={checkbox.checked}
          type="checkbox"
          className="checkbox-custom mt-0"
          onChange={checkbox.onChange}
        />
      )}
      <Card className="custom-card inner-card w-100">
        <Card.Body className="p-0"  style={{display:'flex', flexDirection: 'column', height:'100%'}}>
             <div className="card-header-section">
                <div>
                  <Card.Title className="card-title custom-card-title">
                    {title}
                  </Card.Title>
                </div>
              </div>
              <div className="card-image m-0">
                <Image src={docUrl ? docUrl : imageSrc} alt="Report" className="image-hover" />
                <div className="preview-link">
                  <Image
                    src={preview}
                    alt="Click to Preview"
                    className="preview-image"
                  />
                  <Nav.Link
                    onClick={(e) => {
                      e.preventDefault();
                      navigate({ pathname: `/ViewReport/${blobLocation}` }, {
                        state: { title }
                      })
                    }}
                    className="text-danger p-0 fw-400"
                  >
                    Click to Preview
                  </Nav.Link>
                </div>
              </div>

              <Card.Text className="card-text">{description}</Card.Text>
              <div className="card-footer-section  mx-auto d-sm-flex d-md-block d-block d-sm-block" style={{
                marginTop: 'auto',
              }}>
                <Button
                  className={checkbox.checked ? "btn btn-light" : 'btn'}
                  onClick={onSelectProduct}
                  // onClick={() => setShowModal(true)}
                  style={{ fontSize: "12px" }}
                  disabled={!checkbox.checked}
                >
                  Select Filter
                </Button>
              </div>
        </Card.Body>
        {error && (
          <Card.Footer style={{ font: "small-caption", color: "red" }}>
            {error || null}
          </Card.Footer>
        )}
      </Card>
    </Col>
  );
};

export default CustomCardWithProduct;
