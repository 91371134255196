import React, { useEffect, useState } from "react";
import "../Header/Header.css"; // Import some basic styles
import { Dropdown } from "react-bootstrap";
import roles from "../../../request/role";
import { decryptData } from "../../../services/authService";
import userService from "../../../services/userService";
import subscriptionService from "../../../services/subscriptionService";
import { useNavigate } from "react-router-dom";

const Header = ({ children, prop, header }) => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(false);
  const [role, setRole] = useState(roles.MEMBER);
  const [initials, setInitials] = useState("");
  const [baseUrl, setBaseUrl] = useState("/Dashboards");
  
  // console.log("prop", prop)
  useEffect(() => {
    fetchRole();
    fetchUser();
  }, []);

  const fetchRole = () => {
    let fetchedrole = localStorage.getItem("role");
    let decryptedRole = decryptData(fetchedrole);
    setRole(decryptedRole);

    if (decryptedRole === roles.MEMBER) {
      setBaseUrl("/Dashboards");
    } else if (
      decryptedRole === roles.ADMIN ||
      decryptedRole === roles.SUPERADMIN
    ) {
      setBaseUrl("/ReportConfiguration");
    }

  };
  const fetchUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      await userService
        .getUserByEmail(email)
        .then(async (res) => {
          const initials =
            res.data.firstName.charAt(0) + res.data.lastName.charAt(0);

          setInitials(initials);
          if (res && res.data && res.data.id) {
            await subscriptionService
              .getSubscriptionByUserId(res.data.id)
              .then((res) => {
                // console.log("res", res);
                if (res?.data?.subscriptionId !== 0) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                } else if (
                  res.data.reports !== null &&
                  res.data.researchData !== null
                ) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <header className="header">
      <div className="d-flex align-items-center">
        {children}
        {role === roles.MEMBER && (
          <h5 className="fw-500 flex-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C21.8957 6.57684 21.9897 8.11799 21.999 11"
                stroke="#093B60"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 9H22"
                stroke="#093B60"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M14 18C14 18 15 18 16 20C16 20 19.1765 15 22 14"
                stroke="#D7282F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            {/* {subscription === false
              ? "Select reports to subscribe"
              : "Explore Subscriptions"} */}
            {header}
          </h5>
        )}

        {role === roles.ADMIN && (
          <h5 className="fw-700 flex-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C21.8957 6.57684 21.9897 8.11799 21.999 11"
                stroke="#093B60"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 9H22"
                stroke="#093B60"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M14 18C14 18 15 18 16 20C16 20 19.1765 15 22 14"
                stroke="#D7282F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            {/* Admin Configuration */}
            {header}
          </h5>
        )}

        {role === roles.SUPERADMIN && (
          <h5 className="fw-500 flex-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C21.8957 6.57684 21.9897 8.11799 21.999 11"
                stroke="#093B60"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 9H22"
                stroke="#093B60"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M14 18C14 18 15 18 16 20C16 20 19.1765 15 22 14"
                stroke="#D7282F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            {/* Super Admin Configuration */}
            {header}
          </h5>
        )}
      </div>

      <div className="d-flex align-items-center">
      <a  href={baseUrl}>
        <span className="profile-icon me-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.99931 22L8.74868 18.4911C8.61393 16.6046 10.108 15 11.9993 15C13.8906 15 15.3847 16.6046 15.2499 18.4911L14.9993 22"
              stroke="#D9282F"
              stroke-width="1.5"
            />
            <path
              d="M2.35139 13.2135C1.99837 10.9162 1.82186 9.76763 2.25617 8.74938C2.69047 7.73112 3.65403 7.03443 5.58114 5.64106L7.02099 4.6C9.41829 2.86667 10.6169 2 12 2C13.383 2 14.5817 2.86667 16.979 4.6L18.4188 5.64106C20.346 7.03443 21.3095 7.73112 21.7438 8.74938C22.1781 9.76763 22.0016 10.9162 21.6486 13.2135L21.3476 15.1724C20.8471 18.4289 20.5969 20.0572 19.429 21.0286C18.2611 22 16.5536 22 13.1388 22H10.8612C7.44634 22 5.73891 22 4.571 21.0286C3.40309 20.0572 3.15287 18.4289 2.65243 15.1724L2.35139 13.2135Z"
              stroke="#093B60"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        </a>

        <Dropdown className="right-menu">
          <Dropdown.Toggle className="admin-profile" id="dropdown-basic">
            <div>
              <span className="profile-icon fw-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.78256 17.1112C6.68218 17.743 3.79706 19.0331 5.55429 20.6474C6.41269 21.436 7.36872 22 8.57068 22H15.4293C16.6313 22 17.5873 21.436 18.4457 20.6474C20.2029 19.0331 17.3178 17.743 16.2174 17.1112C13.6371 15.6296 10.3629 15.6296 7.78256 17.1112Z"
                    stroke="#093B60"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5 10C15.5 11.933 13.933 13.5 12 13.5C10.067 13.5 8.5 11.933 8.5 10C8.5 8.067 10.067 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10Z"
                    stroke="#093B60"
                    stroke-width="1.5"
                  />
                  <path
                    d="M2.854 16C2.30501 14.7664 2 13.401 2 11.9646C2 6.46129 6.47715 2 12 2C17.5228 2 22 6.46129 22 11.9646C22 13.401 21.695 14.7664 21.146 16"
                    stroke="#D7282F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>{" "}
                <span className="fw-400" style={{verticalAlign:'middle'}}>{initials || "Profile"}</span>
              </span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="./AccountProfile" className="">
              Profile
            </Dropdown.Item>
            <Dropdown.Item
              className="border-0"
              onClick={(e) => {
                e.preventDefault();
                localStorage.clear();
                sessionStorage.clear();
                // window.location.href = "./login";
                navigate('/login');
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;
