import axios from "axios";
import CryptoJS from "crypto-js";
import authHeader from "./AuthHeader";
import {
  AUTHENTICATION_URL,
  GENERATE_OTP_URL,
  FORGOTPASSWORD_URL,
  REGISTRATION_URL,
  RESET_PASSWORD_URL,
} from "./apiConfig";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
};

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

class AuthService {
  login(username, password) {
    return axios
      .post(AUTHENTICATION_URL, { username, password })
      .then((response) => {
        return response.data;
      });
  }

  forgotPasswordSendOTP(emailId) {
    return axios.post(FORGOTPASSWORD_URL, { emailId }).then((response) => {
      return response.data;
    });
  }

  resetPassword(emailId, newpassword, token) {
    return axios
      .post(RESET_PASSWORD_URL, {
        email: emailId,
        token: token,
        newPassword: newpassword,
        confirmPassword: newpassword,
      })
      .then((response) => {
        return response.data;
      });
  }

  register(requestBody) {
    return axios
      .post(REGISTRATION_URL, requestBody, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AuthService();
