import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import reportService from "../services/reportService";
import userService from "../services/userService";
import subscriptionService from "../services/subscriptionService";
import FeedbackService from "../services/feedbackService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoutAndRedirect from "./LogoutAndRedirect";
import Spinner from "react-bootstrap/Spinner";

const Feedback = () => {
  const [reportList, setReportList] = useState();
  const [description, setDescription] = useState("");
  const [reportId, setReportId] = useState("");
  const [errors, setErrors] = useState({ reportId: "", description: "" });
  const [validated, setValidated] = useState(false);
  const [userId, setUserId] = useState("");
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const email = localStorage.getItem("email");
    await userService.getUserByEmail(email).then(async (res) => {
      let userId = res.data.id;
      setUserId(res?.data?.id);
      try {
        await subscriptionService
          .getSubscriptionByUserId(res.data.id)
          .then((apiResponse) => {
            setReportList(apiResponse?.data?.reports);
          })
          .catch(() => {});
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    });
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
    setDisable(false);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, description: "" }));
    }
  };

  const handleReportSelect = (e) => {
    setReportId(e.target.value);
    setDisable(false);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, reportId: "" }));
    }
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    
    const validationErrors = {};
    let isValid = true;

    // Validate report selection
    if (!reportId) {
      validationErrors.reportId = "Please select a report.";
      isValid = false;
    }

    // Validate description
    if (!description) {
      validationErrors.description = "Description cannot be blank.";
      isValid = false;
    } else if (description.length > 1000) {
      validationErrors.description = "Description cannot exceed 1000 character limit."
      isValid = false;
    }

    setErrors(validationErrors);

    if (isValid) {
      setDisable(true);
      setValidated(true);
      await FeedbackService.createFeedback({
        userId: userId,
        reportId: reportId,
        feedbackText: description,
        createdBy: localStorage.getItem("email"),
      })
        .then((res) => {
          toast.success("Feedback submitted successfully.", {
            position: "top-right",
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.reload(); // This will refresh the page
          }, 3000);
          console.log("res feedback", res);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error Occured", {
            position: "top-right",
            autoClose: 2000,
          });
          console.log("error", error);
        }).finally(() => { setLoader(false); });
        setTimeout(() => {
          setDisable(false);
        }, 2000);
      // Submit the form (perform API call or other actions)
      console.log("Form submitted with:", { reportId, description });
    } else {
      setLoader(false);
      setDisable(true);
    }
  };

  const handleCancel = (e) => {
    window.history.back();
  };

  return (
    <>
     {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item className="fw-500">
                <strong className="fw-500">Feedback</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={4} lg={6} md={6} sm={12}>
                      <Form.Group className="mb-2">
                        <Form.Label
                          className="fw-500"
                          style={{ fontSize: "14px" }}
                        >
                          Reports*
                        </Form.Label>
                        <Form.Select
                          name="company"
                          className="minimal select custom-field custom-select"
                          onChange={handleReportSelect}
                          isInvalid={!!errors.reportId}
                        >
                          <option value="">Select</option>
                          {reportList &&
                            reportList.length > 0 &&
                            reportList !== undefined &&
                            reportList.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.reportName}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.reportId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={6} md={6} sm={12}>
                      <Form.Group className="mb-2">
                        <Form.Label
                          className="fw-500"
                          style={{ fontSize: "14px" }}
                        >
                          Description*
                        </Form.Label>
                        <Form.Group controlId="formTextarea">
                          <Form.Control
                            name="fileDescription"
                            as="textarea"
                            className="custom-field overflow-hidden"
                            rows={5}
                            value={description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mt-2 mb-2">
                        <Button
                          className="btn-light me-2"
                          type="button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ fontSize: "14px" }}
                          className="btn"
                          type="submit"
                          disabled={disable || loader}
                        >
                          {loader && (
                              <Spinner
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                animation="border"
                                variant="danger"
                                size="sm" 
                                className="me-2"
                              />
                            )}
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Feedback;
