import axios from "axios";
import { 
  CREATE_FEEDBACK_URL, 
  GET_FEEDBACK_BY_ID_URL, 
  UPDATE_FEEDBACK_URL, 
  DELETE_FEEDBACK_URL, 
  LIST_FEEDBACK_URL 
} from "./apiConfig";
import authHeader from "./AuthHeader";

class FeedbackService {
  createFeedback(feedbackData) {
    return axios.post(CREATE_FEEDBACK_URL, feedbackData, {headers: authHeader()}).then((response) => {
      return response.data;
    });
  }

  getFeedbackById(id) {
    return axios.post(GET_FEEDBACK_BY_ID_URL, { id }).then((response) => {
      return response.data;
    });
  }

  updateFeedback(feedbackData) {
    return axios.put(UPDATE_FEEDBACK_URL, feedbackData).then((response) => {
      return response.data;
    });
  }

  deleteFeedback(id) {
    return axios.delete(DELETE_FEEDBACK_URL.replace("{id}", id)).then((response) => {
      return response.data;
    });
  }

  listFeedback() {
    return axios.get(LIST_FEEDBACK_URL).then((response) => {
      return response.data;
    });
  }
}

export default new FeedbackService();
