import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import logo from "../assets/d-logo.svg";
import logo from "../assets/logo.png";
import backIcon from "../assets/icons/backIcon.svg";
import "../login.css";
import "../main.css";
import externalIntegration from "../services/externalIntegration";
import Spinner from "react-bootstrap/Spinner";
import bgImage from "../assets/bg-image.png";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [validated, setValidated] = useState(false);
  const [modelMessage, setModelMessage] = useState("")
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // setLoader(false);
    } else {
      setLoader(true);
      event.preventDefault();
      await externalIntegration.contactUs(email, comment).then((res) => {
        console.log("res for comments", res)
        setModelMessage(res.data)
        setShowModal(true);
      }).catch((error) => {

      }).finally(() => { setLoader(false); });
      // Show modal when mail is sent
    }
    setValidated(true);
  };

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <div id="main-wrapper" className="login authentication-page">
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={6} xl={6} sm={12} className="d-flex flex-column align-items-center">
            <Container fluid className="pt-4">
              <Row className="g-0">
                <Col xs={12}>
                  <div className="logo px-sm-5 navlogo">
                    <a
                      className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                      href="/home"
                      title="Dubai Chambers"
                    >
                      <Image src={logo} alt="Logo" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container fluid className="my-auto py-5">
              <Row className="g-0">
                <Col xs={12} className="mx-auto px-sm-5">
                  <h5
                    className="d-flex align-items-center mb-3"
                  >
                    <Image
                      className="me-1"
                      src={backIcon}
                      style={{ width: "18", cursor: "pointer" }}
                      alt="back"
                      onClick={() => window.history.back()}
                    />
                    <span 
                      style={{ cursor: "pointer" }}
                      onClick={() => window.history.back()}
                    >Back</span>
                  </h5>
                  <div className="form-inner">
                    <h1 className="text-12 mb-4 text-center">Contact Us</h1>
                    <Form
                      id="loginForm"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Form.Group controlId="emailAddress">
                        <Form.Label className="fw-500">
                          Your Email Address
                        </Form.Label>
                        <div className="mb-3 icon-group icon-group-end">
                          <Form.Control
                            type="email"
                            className="bg-light border-light"
                            placeholder="a@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="formTextarea">
                        <Form.Label className="fw-500">Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder=""
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          minLength={1}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Comment cannot be empty.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="d-grid my-4">
                        <Button type="submit"
                          className="btn-dark btn-lg"
                          disabled={loader}
                        >
                          {loader && (
                            <Spinner
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              animation="border"
                              variant="danger"
                              size="sm"
                              className="me-2"
                            />
                          )}
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={6} lg={6} xl={6} sm={12} className="bg-light">
            <div className="auth-respo-col">
              <Image src={bgImage} style={{ width: '100%', height: '100%' }} />
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for mail sent confirmation */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mail Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your message has been successfully sent.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
