import React, { useState } from "react";
import "../dashboard.css";
import {
  Row,
  Form,
  Card,
  Col,
  Button,
  Image,
  Breadcrumb,
} from "react-bootstrap";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import adminService from "../services/adminService";
import LogoutAndRedirect from "./LogoutAndRedirect";
import { useNavigate } from "react-router-dom";
import backIcon from "../assets/backIcon.png";
import { InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const AddAdmin = () => {
  const navigate = useNavigate();
  const spinner = <Spinner animation="border" variant="danger" />;
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    company: "",
    memberNumber: "",
    password: "",
    confirmPassword: "",
    checkboxChecked: false, // Assuming the checkbox for Super Admin is checked by default
  });
  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null);

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      company,
      memberNumber,
      password,
      confirmPassword,
    } = formData;

    // First Name validation
    if (!firstName) {
      newErrors.firstName = "First Name is required";
    } else if (firstName.length < 2 || firstName.length > 30) {
      newErrors.firstName = "First Name must be between 2 and 30 characters";
    }

    // Last Name validation
    if (!lastName) {
      newErrors.lastName = "Last Name is required";
    } else if (lastName.length < 2 || lastName.length > 30) {
      newErrors.lastName = "Last Name must be between 2 and 30 characters";
    }

    // Phone Number validation
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^5\d{8}$/.test(phoneNumber)) {
      newErrors.phoneNumber =
        "Phone Number must be exactly 9 digits and start with 5";
    }

    // Email validation
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    // Company validation
    // if (!company) {
    //   newErrors.company = "Company is required";
    // } else if (company.length < 2 || company.length > 50) {
    //   newErrors.company = "Company name must be between 2 and 50 characters";
    // }

    // Member Number validation
    // if (!memberNumber) {
    //   newErrors.memberNumber = "Member Number is required";
    // } else if (!/^[a-zA-Z0-9]+$/.test(memberNumber)) {
    //   newErrors.memberNumber =
    //     "Member Number must contain only letters and numbers";
    // } else if (memberNumber.length < 1 || memberNumber.length > 15) {
    //   newErrors.memberNumber =
    //     "Member Number must be between 1 and 15 characters";
    // }

    // Password validation
    if (!password) {
      newErrors.password = "Password is required";
    } else if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      newErrors.password =
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a symbol";
    }

    // Confirm Password validation
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // No validation errors, proceed to make the API request
      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        role: formData.checkboxChecked ? "SUPERADMIN" : "ADMIN", // Admin role as per your API
        company: formData.company,
        memberNumber: formData.memberNumber,
        password: formData.password,
        createdBy: "superadmin",
        status: "ACTIVE",
      };
      console.log("payload", payload);

      await adminService
        .createUpdateAdmin(payload)
        .then((response) => {
          toast.success("Successful!", {
            position: "top-center", // Use string directly
            autoClose: 5000,
          });
          setTimeout(() => {
            // window.location.href = "/ManageAdmin"; // Refresh the page
            navigate('/ManageAdmin');
          }, 5000);
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(error?.response?.data?.message || "Error occured!", {
            position: "top-center", // Use string directly
            autoClose: 5000,
          });
          setTimeout(() => {
            window.location.reload(); // Refresh the page
          }, 5000);
        }).finally(() => {
          setLoader(false); // Stop the loader regardless of success or failure
        });
    } else {
      setLoader(false); // Stop the loader if there are validation errors
    }
  };

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (type) => {
    switch (type) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };


  return (
    <>
      {" "}
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => window.history.back()}
                  alt="back"
                />{" "}
                <a
                  onClick={() => {
                    navigate({ pathname: "/ManageAdmin" });
                  }}
                  className="fw-400"
                >
                  Manage Admins
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="fw-700 no-link"
                style={{ cursor: "unset" }}
              >
                <strong className="fw-500">Add Admin</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Admin</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form onSubmit={handleSubmit}>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Admin Details
                              </h5>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  First Name
                                </Form.Label>
                                <Form.Control
                                  className="custom-field"
                                  type="text"
                                  value={formData.firstName}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      firstName: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.firstName}
                                />
                                {errors.firstName && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Last Name
                                </Form.Label>
                                <Form.Control
                                  className="custom-field"
                                  type="text"
                                  value={formData.lastName}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      lastName: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.lastName}
                                />
                                {errors.lastName && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Phone Number
                                </Form.Label>
                                <Form.Control
                                  className="custom-field"
                                  type="text"
                                  value={formData.phoneNumber}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      phoneNumber: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.phoneNumber}
                                />
                                {errors.phoneNumber && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.phoneNumber}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Email
                                </Form.Label>
                                <Form.Control
                                  className="custom-field"
                                  type="email"
                                  value={formData.email}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      email: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.email}
                                />
                                {errors.email && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Company
                                </Form.Label>
                                <Form.Control
                                  className="custom-field"
                                  type="text"
                                  value={formData.company}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      company: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.company}
                                />
                                {errors.company && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.company}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Member Number
                                </Form.Label>
                                <Form.Control
                                  className="custom-field"
                                  type="text"
                                  value={formData.memberNumber}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      memberNumber: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.memberNumber}
                                />
                                {errors.memberNumber && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.memberNumber}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Password
                              </Form.Label>
                              <InputGroup className="mb-3 custom-group">
                                <Form.Control
                                  className="custom-field"
                                  style={{ paddingRight: '30px' }}
                                  type={showCurrentPassword ? "text" : "password"}
                                  value={formData.password}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      password: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.password}
                                />
                                <InputGroup.Text
                                  className="eyeIcons"
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    cursor: "pointer",
                                    right: errors.password ? "20px" : "0px",
                                    height:'35px'
                                  }}
                                  onClick={() =>
                                    togglePasswordVisibility("current")
                                  }
                                >
                                  {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                                </InputGroup.Text>
                                {errors.password && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                  </Form.Control.Feedback>
                                )}
                              </InputGroup>

                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Confirm Password
                              </Form.Label>
                              <InputGroup className="mb-3 custom-group">
                                <Form.Control
                                  className="custom-field"
                                  style={{ paddingRight: '30px' }}
                                  type={showConfirmPassword ? "text" : "password"}
                                  value={formData.confirmPassword}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      confirmPassword: e.target.value,
                                    })
                                  }
                                  isInvalid={!!errors.confirmPassword}
                                />
                                <InputGroup.Text className="eyeIcons" style={{
                                  position: 'absolute',
                                  top: '0',
                                  bottom: '0',
                                  cursor: "pointer",
                                  right: errors.confirmPassword ? "20px" : "0px",
                                  height:'35px'
                                }}
                                  onClick={() =>
                                    togglePasswordVisibility("confirm")
                                  }
                                >
                                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                </InputGroup.Text>
                                {errors.confirmPassword && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                  </Form.Control.Feedback>
                                )}
                              </InputGroup>
                            </Col>

                            <Row>
                              <Col lg={4} md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Check
                                    type="checkbox"
                                    className="text-light-grey mt-3 form_label"
                                    style={{ fontSize: "14px" }}
                                    label="Super Admin"
                                    checked={formData.checkboxChecked}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        checkboxChecked: e.target.checked,
                                      })
                                    }
                                  />
                                  {errors.checkboxChecked && (
                                    <Alert variant="danger">
                                      {errors.checkboxChecked}
                                    </Alert>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  type="button"
                                  className="btn-light me-2"
                                  onClick={() => navigate('/ManageAdmin')}
                                >
                                  Cancel
                                </Button>
                                <Button type="submit" className="btn"
                                  disabled={loader}
                                >
                                  {loader && (
                                    <Spinner
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      animation="border"
                                      variant="danger"
                                      size="sm"
                                      className="me-2"
                                    />
                                  )}
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Row>

                          {/* Submit Button */}
                        </div>
                      </Form>

                      {/* Display API response */}
                      {/* {apiResponse && (
                        <Alert variant="success">
                          Admin successfully created/updated!
                        </Alert>
                      )}
                      {errors.apiError && (
                        <Alert variant="danger">{errors.apiError}</Alert>
                      )} */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
