import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import keyIcon from "../assets/icons/key.png";
import userIcon from "../assets/icons/user.png";
import UserService from "../services/userService";
import { getUserByIdRequestBody } from "../request/requestBodies";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LogoutAndRedirect from "./LogoutAndRedirect";
import adminService from "../services/adminService";
import { toast, ToastContainer } from "react-toastify";

const AdminProfile = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [email, setEmail] = useState(state.email);
  const [user, setUser] = useState("");
  const [key, setKey] = useState("Account");
  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState(false);
  // State to store form field values
  const [formValues, setFormValues] = useState({
    createdAt: "",
    createdBy: "",
    email: "",
    firstName: "",
    gender: "",
    id: "",
    lastName: "",
    phoneNumber: "",
    role: "",
    status: "",
    updatedAt: "",
  });

  const handleToggle = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    // setIsActive(!isActive);
    // setFormValues({ ...formValues, status: !isActive });
    updateAdmin();
  };

  const updateAdmin = async () => {
    const reqBody = {
      ...formValues,
      status: formValues.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };

    // console.log("reqBody", reqBody);
    await adminService
      .createUpdateAdmin(reqBody)
      .then((res) => {
        console.log("res", res);
        if (res) {
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Error occured!", {
          position: "top-center", // Use string directly
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, 3000);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    console.log("emailId", email);
    const user = await UserService.getUserByEmail(email)
      .then((res) => {
        console.log("profile response", res);
        if (res && res.data && res.data) {
          setFormValues(res.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // State to manage whether the form is editable or not
  const [isEditable, setIsEditable] = useState(false);
  // Handle form input change
  const handleInputChange = (e) => {
    setChange(true);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Cancel form editing
  const handleCancelClick = () => {
    setIsEditable(false);
    navigate('/ManageAdmin');
  };
  // Save form changes
  const handleSaveClick = () => {
    console.log("Form submitted:", formValues);
    setIsEditable(false);
  };
  // Function to handle the toggle of the editable state
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  const handleSubmit = () => {};
  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content container">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item
                className="fw-700"
                onClick={() => window.history.back()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#D7282F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M13.5 16C13.5 16 10.5 13.054 10.5 12C10.5 10.9459 13.5 8 13.5 8"
                    stroke="#D7282F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <strong> Admin Profile</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={8} md={8}>
                      <Row>
                        <Col lg={12}>
                          <div class="d-sm-flex align-items-center gap-x-4">
                            <div className="profile-name mb-2 mb-sm-0">
                              <span style={{ verticalAlign: "middle" }}>{`${
                                formValues?.firstName?.charAt(0) || ""
                              }${formValues?.lastName?.charAt(0) || ""}`}</span>
                            </div>
                            <div>
                              <h5 class="text-base font-bold fw-500 ms-0 ms-sm-3">
                                {formValues?.firstName} {formValues?.lastName}
                              </h5>
                              <div className="d-sm-flex  align-items-center ms-0 ms-sm-3 mt-2">
                                <div className="ms-sm-0 mt-2 mt-sm-0">
                                  <div className="d-flex align-items-center toggle-switch">
                                    <Image
                                      style={{ width: "14px" }}
                                      src={userIcon}
                                      className="me-2"
                                    />
                                    <div className="toggle-wrap">
                                      <span
                                        className={
                                          formValues.status !== "ACTIVE"
                                            ? "inactive-text"
                                            : "active-text"
                                        }
                                      >
                                        Inactive
                                      </span>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        checked={formValues.status === "ACTIVE"}
                                        onChange={handleToggle}
                                        className={
                                          formValues.status === "ACTIVE"
                                            ? "switch-active"
                                            : "switch-inactive"
                                        }
                                        disabled={
                                          email === localStorage.getItem('email')
                                        }
                                      />
                                      <span
                                        className={
                                          formValues.status === "ACTIVE"
                                            ? "active-text"
                                            : "inactive-text"
                                        }
                                      >
                                        Active
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={12} md={12}>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="Account" title="Account">
                          <Row>
                            <Col lg={4} md={6}>
                              <Row>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    First Name
                                  </Form.Label>
                                  <InputGroup className="mb-3 custom-group">
                                    <FormControl
                                      value={formValues.firstName}
                                      onChange={handleInputChange}
                                      disabled={!isEditable}
                                      type="text"
                                      name="firstName"
                                      className={`custom-field custom-group-field ${
                                        !isEditable ? "text-muted" : ""
                                      }`}
                                      placeholder="First Name"
                                    />

                                    <InputGroup.Text
                                      onClick={handleEditClick}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Col>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Email ID
                                  </Form.Label>
                                  <InputGroup className="mb-3 custom-group">
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      className="custom-field"
                                      placeholder="Email"
                                      value={formValues.email}
                                      onChange={handleInputChange}
                                      disabled={true}
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={4} md={6}>
                              <Row>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Last Name
                                  </Form.Label>
                                  <Form.Group className="mb-2">
                                    <InputGroup className="mb-3 custom-group">
                                      <FormControl
                                        value={formValues.lastName}
                                        onChange={handleInputChange}
                                        disabled={!isEditable}
                                        type="text"
                                        name="lastName"
                                        className={`custom-field custom-group-field ${
                                          !isEditable ? "text-muted" : ""
                                        }`}
                                        placeholder="Last Name"
                                      />

                                      <InputGroup.Text
                                        onClick={handleEditClick}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Image
                                          style={{ width: "14px" }}
                                          src={editIcon}
                                        />
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Member ID
                                  </Form.Label>
                                  <InputGroup className="mb-3 custom-group">
                                    <Form.Control
                                      type="text"
                                      name="Member ID"
                                      className="custom-field"
                                      placeholder="Member ID"
                                      value={formValues.memberID}
                                      onChange={handleInputChange}
                                      disabled={true}
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={12} md={12}>
                              <div className="mt-2 mb-2">
                                <Button
                                  onClick={handleCancelClick}
                                  className="btn-light me-2"
                                >
                                  Cancel
                                </Button>
                                {isEditable && change && (
                                  <Button
                                    style={{ fontSize: "14px" }}
                                    onClick={handleSaveClick}
                                    className="btn"
                                  >
                                    Save
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="Transfer Access and Permissions"
                          title="Transfer Access and Permissions"
                          disabled
                        >
                          <Row>
                            <Col lg={4} md={6}>
                              <Row>
                                <Col lg={12} md={12}>
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Manage Permission
                                  </Form.Label>
                                  <Form.Select
                                    name="company"
                                    className=" minimal select custom-field custom-select"
                                  >
                                    <option value="1">View only</option>
                                    <option value="2">Can edit</option>
                                  </Form.Select>
                                </Col>
                                <Col lg={12} md={12}>
                                  <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                      required
                                      type="checkbox"
                                      className="text-light-grey mt-3 form_label"
                                      style={{ fontSize: "14px" }}
                                      label={
                                        <>
                                          Want to make Shantanu as Super Admin
                                        </>
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12} md={12}>
                                  <div className="mt-3 mb-2">
                                    <Button
                                      style={{ fontSize: "14px" }}
                                      onClick={handleSaveClick}
                                      className="btn"
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to set {formValues.firstName}{" "}
          {formValues.lastName}{" "}
          {formValues.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AdminProfile;
