import axios from "axios";
import {
  CREATE_REPORT_URL,
  GET_REPORT_BY_ID_URL,
  UPDATE_REPORT_URL,
  DELETE_REPORT_URL,
  LIST_REPORTS_URL,
  GET_REPORT,
} from "./apiConfig";
import authHeader from "./AuthHeader";

class ReportService {
  createReport(reportData) {
    return axios
      .post(CREATE_REPORT_URL, reportData, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getReportById(reportId) {
    let id = reportId;
    return axios
      .post(GET_REPORT_BY_ID_URL, id, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  updateReport(reportData) {
    return axios
      .put(UPDATE_REPORT_URL, reportData, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteReport(id) {
    return axios
      .delete(DELETE_REPORT_URL + id, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  listReports() {
    return axios
      .get(LIST_REPORTS_URL, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }

  getReport(id) {
    return axios
      .post(GET_REPORT, { reportId: id }, { headers: authHeader() })
      .then((res) => {
        return res.data;
      });
  }
}

export default new ReportService();
