import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
// import logo from "../assets/d-logo.svg";
import logo from "../assets/logo.png";
import "../login.css";
import "../main.css";
import AuthService from "../services/authService";
import { validateForgotPasswordForm as ValidateForgotPasswordForm } from "../request/validationFunctions";
import Spinner from "react-bootstrap/Spinner";
import backIcon from "../assets/icons/backIcon.svg";
import { useNavigate } from "react-router-dom";
import checkMailboxIcon from "../assets/icons/Check-mailbox-icon.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [showMailboxPopup, setShowMailboxPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    const newErrors = ValidateForgotPasswordForm(emailId);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const apiResponse = await AuthService.forgotPasswordSendOTP(emailId.toLowerCase())
        .then(res => {
          setErrors({});
          setFormSubmitted(true);
          setLoader(false);
          setShowMailboxPopup(true);
        })
        .catch(err => {
          setErrors({
            ...errors,
            emailId: 'Please enter valid email address'
          })
          toast.error("Error sending verification link", {
            position: "top-right",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoader(false);
        });
        console.log("apiResponse", apiResponse.statusCode);
        // toast.success("Verification link sent sucessfully", {
        //   position: "top-right",
        //   autoClose: 3000,
        // });
        console.log("Forgot password form submitted", { emailId });
      } catch (error) {}
    }
  };

  const handleCloseMailboxPopup = () => {
    setShowMailboxPopup(false); // Close registered mailbox popup
    navigate("/login");
  };

  return (
    <>
      <ToastContainer />
      <div id="main-wrapper" className="login authentication-page">
        <Container fluid className="px-0">
          <Row className="g-0 min-vh-100">
            <Col
              md={6}
              lg={6}
              className="d-flex flex-column align-items-center"
            >
              <Container fluid className="pt-4">
                <Row className="g-0">
                  <Col xs={12}>
                    <div className="logo  px-0 px-sm-5 navlogo">
                      <a
                        className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                        href="/home"
                        title="Dubai Chambers"
                      >
                        <Image src={logo} alt="Logo" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>

              <Container fluid className="my-auto py-5">
                <Row className="g-0">
                  {/* <Col xs={11} md={10} lg={9} className="mx-auto"> */}
                  <Col xs={12} className="mx-auto  px-0 px-sm-5">
                    <h5
                      className="d-flex align-items-center mb-3"
                      style={{ cursor: "pointer", width: "fit-content" }}
                      onClick={() => window.history.back()}
                    >
                      <Image
                        className="me-1"
                        src={backIcon}
                        style={{ width: "18" }}
                        alt="back"
                      />
                      Back
                    </h5>
                    <div className="form-inner">
                      <h1 className="text-12 mb-4 text-center">
                        Forgot password?
                      </h1>
                      <p className="text-center mb-4">
                        Please enter the email associated with your account and
                        you will get a reset password link.
                      </p>
                      <Form id="forgotPasswordForm" onSubmit={handleSubmit}>
                        <Form.Group controlId="forgotPassword">
                          <div className="mb-3 icon-group icon-group-end">
                            <Form.Control
                              type="email"
                              className="bg-white"
                              placeholder="a@example.com"
                              value={emailId}
                              onChange={(e) => {
                                setErrors({});
                                setEmailId(e.target.value)
                              }}
                              isInvalid={!!errors.emailId}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.emailId}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <div className="d-grid mt-5 mb-2">
                          <Button
                            type="submit"
                            className="btn-secondary btn btn-lg"
                            onClick={handleSubmit}
                            disabled={loader}
                          >
                            {loader ? (
                              <Spinner
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                animation="border"
                                variant="danger"
                                size="sm" // You can adjust the size if needed
                              />
                            ) : (
                              "Submit"
                            )}
                          </Button>

                          {/* <Button
                              type="submit"
                              className="btn-secondary btn btn-lg"
                              onClick={handleSubmit}
                              disabled={loader}
                            >
                              Submit
                            </Button> */}
                          {/* {loader ? (
                            <Spinner style={{justifyContent:"center", alignContent:"center"}} animation="border" variant="danger" />
                          ) : (
                            <Button
                              type="submit"
                              className="btn-secondary btn btn-lg"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          )} */}
                        </div>
                      </Form>
                      {formSubmitted && (
                        <p className="text-center text-success mt-3">
                          If this email exists, a reset link has been sent.
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={6} lg={6} className="bg-light">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="login-image-container hero-bg hero-bg-scroll"></div>
              </div>
            </Col>
          </Row>
          <Modal
            centered
            show={showMailboxPopup}
            onHide={handleCloseMailboxPopup}
          >
            <Modal.Header className="border-0 m-auto pb-0">
              <Modal.Title>
                <h1 className="text-12 mb-0 text-center">
                  Check registered mailbox
                </h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "35px" }}>
              <div className="text-center mb-3">
                <Image
                  src={checkMailboxIcon}
                  height={54}
                  alt="Check mailbox icon"
                />
              </div>
              <h5 className="text-2 text-center mb-3">
                Please check your mail id for the link to reset password
              </h5>
              <div className="note-area">
                <p className="text-secondary">
                  <span className="text-danger fw-500">Note:</span> Please be
                  patient with the time taken to receive the email as it can
                  vary. If you can’t find the email from Dubai Chambers in your
                  inbox, please check your “Junk/Spam” folder.
                </p>
              </div>
              <div className="d-grid mt-4">
                <Button
                  onClick={handleCloseMailboxPopup}
                  className="btn btn-secondary btn-lg fs-14"
                >
                  Ok
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
