import React, { useEffect, useState } from "react";
import "./Sidebar.css"; // Import some basic styles
// import logo from "../../../assets/w-logo.png";
import logo from "../../../assets/dubai-chamber-commerce-small-logo.svg";
import { Image } from "react-bootstrap";
import sidbarFooterImage from "../../../assets/bg-image-transparent.png";
import roles from "../../../request/role";
import { decryptData } from "../../../services/authService";
import ReportConfiguration2 from "../../../assets/icons/report-configuration-white-icon.svg";
import ReportConfiguration from "../../../assets/icons/report-configuration-blue-icon.svg";
import DataReport from "../../../assets/icons/data-report-blue-icon.svg";
import DataReport2 from "../../../assets/icons/data-report-white-icon.svg";
import BiIntegration from "../../../assets/icons/bi-integration-blue-icon.svg";
import BiIntegration2 from "../../../assets/icons/bi-integration-white-icon.svg";
import MySubscription from "../../../assets/icons/my-subscription-blue-icon.svg";
import MySubscription2 from "../../../assets/icons/my-subscription-white.svg";
import Dashboard from "../../../assets/icons/dashboard-blue-icon.svg";
import Dashboard2 from "../../../assets/icons/dashboard-white-icon.svg";
import ProfileSetting from "../../../assets/icons/profile-setting-blue.svg";
import ProfileSetting2 from "../../../assets/icons/profile-setting-white-icon.svg";
import Feedback2 from "../../../assets/icons/feedback-white.svg";
import Feedback from "../../../assets/icons/feedback-blue.svg";
import ManageAdmin from "../../../assets/icons/manage-admin-blue-icon.svg";
import ManageAdmin2 from "../../../assets/icons/manage-admin-white-icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import userService from "../../../services/userService";
import subscriptionService from "../../../services/subscriptionService";

const Sidebar = (prop) => {
  const location = useLocation();
  console.log("location", location.pathname);
  const isAccountProfile = location.pathname.includes("/AccountProfile");
  console.log("prop", prop.prop);
  const [isOpen, setIsOpen] = useState(true);
  const [role, setRole] = useState(roles.MEMBER);
  const [menu, setMenu] = useState("first");
  const [subscription, setSubscription] = useState(false);
  const [baseUrl, setBaseUrl] = useState("/Dashboards");
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchRole();
    fetchUser();
  }, []);

  const fetchRole = () => {
    let fetchedrole = localStorage.getItem("role");
    let decryptedRole = decryptData(fetchedrole);
    setRole(decryptedRole);
    if (decryptedRole === roles.MEMBER) {
      setBaseUrl("/Dashboards");
    } else if (
      decryptedRole === roles.ADMIN ||
      decryptedRole === roles.SUPERADMIN
    ) {
      setBaseUrl("/ReportConfiguration");
    }
  };

  const fetchUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      await userService
        .getUserByEmail(email)
        .then(async (res) => {
          // console.log("user", res.data.id);
          if (res && res.data && res.data.id) {
            await subscriptionService
              .getSubscriptionByUserId(res.data.id)
              .then((res) => {
                // console.log("res", res);
                if (res?.data?.subscriptionId !== 0) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                } else if (
                  res.data.reports !== null &&
                  res.data.researchData !== null
                ) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                }
              })
              .catch((error) => { });
          }
        })
        .catch((error) => { });
    }
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="logo">
          <a href={baseUrl}>
            <Image src={logo} alt="Logo" />
          </a>
        </div>
      </div>
      <nav className="menu">
        {/* <ul>
          <li>
            <i onClick={toggleSidebar}>Menu</i>
          </li>
        </ul> */}
        {isOpen && (
          <ul className="sidebar-menus">
            <li className="menu-label">
              <div className="menu-divider">
                <span>Menu</span>
              </div>
            </li>

            {role === roles.MEMBER && (
              <>
                <li
                  className={
                    location.pathname === "/Dashboards"
                      ? "active"
                      : "inactive"
                  }
                >
                  <a
                    href="/Dashboards"
                  >
                    <Image
                      width={"16px"}
                      className="me-2"
                      src={
                        location.pathname === "/Dashboards"
                          ? Dashboard // Dashboard images (you can add conditions if needed)
                          : Dashboard2
                      }
                    />
                    Explore Subscriptions
                  </a>
                </li>

              </>
            )}
            {role === roles.MEMBER && subscription === true && (
              <>
                <li
                  className={
                    location.pathname === "/MySubscription"
                      ? "active"
                      : "inactive"
                  }
                >
                  <a
                    href="/MySubscription"
                  >
                    <Image
                      width={"16px"}
                      className="me-2"
                      src={
                        location.pathname === "/MySubscription"
                          ? MySubscription
                          : MySubscription2
                      }
                    />
                    My Subscriptions
                  </a>
                </li>
                <li
                  className={
                    location.pathname === "/Feedback" ? "active" : "inactive"
                  }
                >
                  <a href="/Feedback">
                    <Image
                      width={"16px"}
                      className="me-2"
                      src={
                        location.pathname === "/Feedback" ? Feedback : Feedback2
                      } // Conditional image
                    />
                    Feedback
                  </a>
                </li>
              </>
            )}
            {(role === roles.ADMIN || role === roles.SUPERADMIN) && (
              <>
                <li
                  className={
                    location.pathname === "/ReportConfiguration" ||
                    location.pathname.startsWith("/AddReport")
                    
                      ? "active"
                      : "inactive"
                  }
                >
                  <a
                    href="/ReportConfiguration"
                    onClick={(e) => {
                      e.preventDefault();
                      setMenu("ReportConfiguration");
                      navigate("/ReportConfiguration");
                    }}
                  >
                    <Image
                      width={"16px"}
                      className="me-2"
                      src={
                        menu === "ReportConfiguration" ||
                         location.pathname === "/ReportConfiguration" ||
                         location.pathname.startsWith("/AddReport")
                          ? ReportConfiguration
                          : ReportConfiguration2
                      }
                    />
                    Report Configuration
                  </a>
                </li>
                <li
                  className={
                    location.pathname === "/DataReport" ||
                      location.pathname.startsWith("/AddDataReport")
                      ? "active" : "inactive"
                  }
                // className={
                //   location.pathname === "/DataReport" ? "active" : "inactive"
                // }
                >
                  <a
                    href="/DataReport"
                    onClick={(e) => {
                      e.preventDefault();
                      setMenu("DataReport");
                      navigate("/DataReport");
                    }}
                  >
                    <Image
                      width={"16px"}
                      className="me-2"
                      // src={location.pathname === "/DataReport" ? DataReport : DataReport2}
                      src={(menu === "DataReport" || location.pathname === "/DataReport" || location.pathname.startsWith("/AddDataReport")) ? DataReport : DataReport2}
                    />
                    Research Data
                  </a>
                </li>
                <li
                  className={
                    location.pathname === "/BiIntegration" ? "active" : "inactive"
                  }
                >
                  <a
                    href="/BiIntegration"
                    onClick={(e) => {
                      e.preventDefault();
                      setMenu("BiIntegration");
                      navigate("/BiIntegration");
                    }}
                  >
                    <Image
                      width={"16px"}
                      className="me-2"
                      src={
                        menu === "BiIntegration"
                          ? BiIntegration
                          : BiIntegration2
                      }
                    />
                    BI Integration
                  </a>
                </li>
              </>
            )}
            {role === roles.SUPERADMIN && (
              <>
                <li className={
                  location.pathname === "/ManageAdmin" ||
                  location.pathname.startsWith("/AddAdmin") ||
                  location.pathname.startsWith("/AdminProfile") 
                  ? "active" : "inactive"
                }
                >
                  <a
                    href="/ManageAdmin"
                    onClick={(e) => {
                      e.preventDefault();
                      setMenu("ManageAdmin");
                      navigate("/ManageAdmin");
                    }}
                    s
                  >
                    <Image
                      width={"16px"}
                      className="me-2"
                      // src={menu === "ManageAdmin" ? ManageAdmin : ManageAdmin2}
                      // src={(menu === "ManageAdmin" || location.pathname === "/ManageAdmin" || location.pathname.startsWith("/AddAdmin")) ? ManageAdmin : ManageAdmin2}
                      src={location.pathname === "/ManageAdmin" || 
                        location.pathname.startsWith("/AddAdmin") || 
                        location.pathname.startsWith("/AdminProfile") 
                     ? ManageAdmin : ManageAdmin2}

                    />
                    Manage Admins
                  </a>
                </li>
              </>
            )}
            <li className="menu-label mb-0">
              <div className="menu-divider">
                <span>Settings</span>
              </div>
            </li>

            <li className={isAccountProfile ? "active" : "inactive"}>
              <a href="/AccountProfile">
                <Image
                  src={isAccountProfile ? ProfileSetting : ProfileSetting2}
                  width={"16px"}
                  className="me-2"
                  alt=""
                />{" "}
                Profile Settings
              </a>
            </li>
          </ul>
        )}
      </nav>
      <div className="logout" style={{}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.02331 5.5C4.59826 7.11238 3 9.86954 3 13C3 17.9706 7.02944 22 12 22C16.9706 22 21 17.9706 21 13C21 9.86954 19.4017 7.11238 16.9767 5.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 2V10"
            stroke="#D7282F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            localStorage.clear();
            sessionStorage.clear();
            // window.location.href = "./login";
            navigate('/login');
          }}
        >
          Logout
        </span>
      </div>
      <div className="sidebar-footer-image">
        <Image src={sidbarFooterImage} alt="sidbar Footer Image" />
      </div>
    </div>
  );
};

export default Sidebar;
