import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import UserService from "../services/userService";
import authService from "../services/authService";
import { getUserByIdRequestBody } from "../request/requestBodies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LogoutAndRedirect from "./LogoutAndRedirect";
import checked from "../assets/icons/checked.png";
import roles from "../request/role";
import backIcon from "../assets/backIcon.png";
import { decryptData } from "../services/authService";

const AccountProfile = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState(roles.MEMBER);
  const [baseUrl, setBaseUrl] = useState("/Dashboards");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [errorForProfile, setErrorForProfile] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isEditablePassword, setIsEditablePassword] = useState(false);
  const [initials, setInitials] = useState("");
  const [enableSave, setEnableSave] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const email = localStorage.getItem("email");
  const [loader, setLoader] = useState(false);

  const [loaderSubmit, setLoaderSubmit] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (type) => {
    switch (type) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchRole();
    fetchData();
  }, []);

  const fetchRole = () => {
    let fetchedrole = localStorage.getItem("role");
    let decryptedRole = decryptData(fetchedrole);
    setRole(decryptedRole);

    if (decryptedRole === roles.MEMBER) {
      setBaseUrl("/Dashboards");
    } else if (
      decryptedRole === roles.ADMIN ||
      decryptedRole === roles.SUPERADMIN
    ) {
      setBaseUrl("/ReportConfiguration");
    }
  };

  const fetchData = async () => {
    const user = await UserService.getUserByEmail(email)
      .then((res) => {
        if (res && res.data) {
          setFormValues({
            id: res.data.id,
            firstName: res.data.firstName || "",
            lastName: res.data.lastName || "",
            email: res.data.email || "",
            phoneNumber: res.data.phoneNumber || "",
            role: res.data.role,
            createdBy: res.data.createdBy,
            status: res.data.status,
          });
          const initials =
            res.data.firstName.charAt(0) + res.data.lastName.charAt(0);
          setInitials(initials.toUpperCase());
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // Handle form input change
  const handleInputChange = async (e) => {
    e.preventDefault();
    setEnableSave(true);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCancelClick = () => {
    fetchData();
    setIsEditable(false); // Disable editing
    window.location.reload();
  };

  const validatePersonalDetails = () => {
    const newErrors = {};
    const { firstName, lastName, phoneNumber, email } = formValues;

    // Validation logic
    if (!firstName) {
      newErrors.firstName = "First Name is required";
    } else if (firstName.length < 2 || firstName.length > 30) {
      newErrors.firstName = "First Name must be between 2 and 30 characters";
    }

    if (!lastName) {
      newErrors.lastName = "Last Name is required";
    } else if (lastName.length < 2 || lastName.length > 30) {
      newErrors.lastName = "Last Name must be between 2 and 30 characters";
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone Number must be exactly 10 digits";
    }

    if (!email) {
      newErrors.email = "Email or Username is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    return newErrors;
  };

  const validateProfile = () => {
    const newErrors = {};
    const { firstName, lastName } = formValues;

    if (!firstName) {
      newErrors.firstName = "First Name is required";
    } else if (firstName.length < 2 || firstName.length > 30) {
      newErrors.firstName = "First Name must be between 2 and 30 characters";
    } else if (/\s/.test(firstName)) {
      newErrors.firstName = "First Name cannot contain spaces";
    } else if (!/^[a-zA-Z]+$/.test(firstName)) {
      newErrors.firstName = "First Name must contain only letters";
    }

    if (!lastName) {
      newErrors.lastName = "Last Name is required";
    } else if (lastName.length < 2 || lastName.length > 30) {
      newErrors.lastName = "Last Name must be between 2 and 30 characters";
    } else if (/\s/.test(lastName)) {
      newErrors.lastName = "Last Name cannot contain spaces";
    } else if (!/^[a-zA-Z]+$/.test(lastName)) {
      newErrors.lastName = "Last Name must contain only letters";
    }

    return newErrors;
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setEnableSave(false);
    setLoader(true);

    // Validate profile and get errors
    const errors = validateProfile();
    console.log("errors");
    if (Object.keys(errors).length > 0) {
      // If there are errors, handle them (e.g., by setting state)
      setErrorForProfile(errors);
      setLoader(false);
      setEnableSave(true); // Allow save button to be re-enabled
      return;
    }

    try {
      const res = await authService.register(formValues);
      console.log("response", res);
      toast.success("Success!", {
        position: "top-right",
        autoClose: 3000,
      });
      setIsEditable(false);
      setErrorForProfile({});
      setTimeout(() => {
        window.location.reload(); // This will refresh the page
      }, 3000);
    } catch (error) {
      if (error) {
        console.log("error", error);
      }
      toast.error("Error occurred while saving!", {
        position: "top-right",
        autoClose: 3000,
      });
      console.error("Error:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleEditClickForPassword = () => {
    setIsEditablePassword(!isEditablePassword);
  };

  const validateForm = () => {
    let formErrors = {};
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

    if (!currentPassword) {
      formErrors.currentPassword = "Current Password is required";
    }
    if (!currentPassword) {
      formErrors.currentPassword = "Current Password is required";
    }

    // New password validation following your pattern
    if (!newPassword) {
      formErrors.newPassword = "New Password is required";
    } else if (
      newPassword.length < 8 ||
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/[0-9]/.test(newPassword) ||
      !/[!@#$%^&*]/.test(newPassword)
    ) {
      formErrors.newPassword =
        "New Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a symbol";
    }

    if (confirmPassword !== newPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    if (validateForm()) {
      setLoaderSubmit(true);
      console.log("passwords", currentPassword, newPassword);
      await UserService.changePassword({
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
        .then((res) => {
          toast.success("Password changed successfully", {
            position: "top-right",
            autoClose: 3000,
          });
          setIsEditablePassword(false);
          setDisabled(false);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setTimeout(() => {
            window.location.reload(); // This will refresh the page
          }, 3000);
        })
        .catch((error) => {
          console.log("error", error);
          const error1 = error?.response?.data?.detail;
          if (error) {
            toast.error(error1 || "Error occurred while saving!", {
              position: "top-right", // Use string directly
              autoClose: 3000,
            });
            setIsEditablePassword(false);
            setDisabled(false);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
          }
        })
        .finally(() => {
          setLoaderSubmit(false);
        });
    }
  };

  const handleCancelPassword = () => {
    // Reset password fields
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");

    // Reset any validation errors
    setErrors({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    // Optionally, disable the password form if it's part of the logic
    setIsEditablePassword(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Ensure the phone number is a string and has 12 digits
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    if (cleaned.length === 12) {
      // Format the number as +971-50-1234567
      return `+${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(
        5
      )}`;
    }
    return phoneNumber;
  };

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => {
                    navigate({ pathname: baseUrl });
                  }}
                  alt="back"
                />{" "}
                <a
                  onClick={() => {
                    navigate({ pathname: baseUrl });
                  }}
                  className="fw-400"
                >
                  Personal Information
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={12} md={12}>
                      <Row>
                        <Col lg={12}>
                          <div class="d-sm-flex align-items-center gap-x-4 mb-3">
                            <div className="profile-name mb-2 mb-sm-0">
                              <span>{initials}</span>
                            </div>
                            <div>
                              <h5 class="text-base font-bold fw-500 ms-sm-3 mb-1">
                                {formValues.firstName} {formValues.lastName}
                              </h5>
                              <div class="d-sm-flex align-items-center ms-sm-3">
                                <p class="text-base fw-400 me-4 fs-14 mb-2  mb-sm-0">
                                  <span
                                    className="fa fa-envelope me-2"
                                    style={{ fontSize: "12px" }}
                                  ></span>{" "}
                                  <span className="fw-400 me-2">
                                    {formValues.email}
                                  </span>
                                  <span className="text-success fs-14  me-3 fw-400 d-inline-block">
                                    <Image src={checked} width="12" /> Verified
                                  </span>
                                </p>

                                <p class="text-base fw-400 fs-14">
                                  <span
                                    className="fa fa-phone me-2"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  <span className="fw-400 me-2">
                                    {formatPhoneNumber(formValues.phoneNumber)}
                                  </span>
                                  <span className="text-success fs-14  me-3 fw-400 d-inline-block">
                                    <Image src={checked} width="12" /> Verified
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={8}>
                          <Row>
                            <Col lg={5} md={5}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                First Name
                              </Form.Label>

                              <InputGroup
                                className="mb-3 custom-group"
                                onClick={handleEditClick}
                              >
                                <FormControl
                                  value={formValues.firstName}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 30) {
                                      handleInputChange(e); // Only update if input length is <= 30
                                    }
                                  }}
                                  disabled={!isEditable}
                                  type="text"
                                  name="firstName"
                                  className={`custom-field custom-group-field ${
                                    isEditable
                                      ? "editable-field"
                                      : "non-editable-field"
                                  }`}
                                  placeholder="First Name"
                                  onFocus={handleEditClick}
                                  maxLength={30} // Set max length to 30
                                  isInvalid={!!errorForProfile?.firstName}
                                />
                                {/* <InputGroup.Text
                              onClick={handleEditClick}
                              style={{ cursor: "pointer" }}
                            >
                              <Image style={{ width: "14px" }} src={editIcon} />
                            </InputGroup.Text> */}
                                <Form.Control.Feedback type="invalid">
                                  {errorForProfile.firstName}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>

                            <Col lg={6} md={6}>
                              <Form.Label
                                className="fw-500"
                                style={{ fontSize: "14px" }}
                              >
                                Last Name
                              </Form.Label>

                              <Form.Group className="mb-2">
                                <InputGroup className="mb-3 custom-group">
                                  <FormControl
                                    value={formValues.lastName}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 30) {
                                        handleInputChange(e); // Only update if input length is <= 30
                                      }
                                    }}
                                    disabled={!isEditable}
                                    type="text"
                                    name="lastName"
                                    className={`custom-field custom-group-field ${
                                      isEditable
                                        ? "editable-field"
                                        : "non-editable-field"
                                    }`}
                                    placeholder="Last Name"
                                    onFocus={(e) => {
                                      handleEditClick();
                                      e.stopPropagation(); // Prevents event bubbling
                                    }}
                                    onClick={(e) => e.stopPropagation()} // Prevents event bubbling
                                    maxLength={30} // Set max length to 30
                                    isInvalid={!!errorForProfile.lastName}
                                  />
                                  <InputGroup.Text
                                    onClick={(e) => {
                                      handleEditClick();
                                      e.stopPropagation(); // Prevents event bubbling
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {/* <Image
                                  style={{ width: "14px" }}
                                  src={editIcon}
                                /> */}
                                    <span className="fw-400 me-2 fs-14">
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />{" "}
                                      Edit
                                    </span>
                                  </InputGroup.Text>
                                  <Form.Control.Feedback type="invalid">
                                    {errorForProfile.lastName}
                                  </Form.Control.Feedback>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={6} md={6}>
                          <div className="mt-2 mb-2">
                            <Button
                              onClick={handleCancelClick}
                              className="btn-light me-2"
                            >
                              Cancel
                            </Button>

                            <Button
                              style={{ fontSize: "14px" }}
                              onClick={handleSaveClick}
                              className="btn"
                              disabled={!enableSave}
                            >
                              {loader && (
                                <Spinner
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  animation="border"
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                />
                              )}
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="card-title-danger d-flex align-items-center mb-3 mt-4">
                <h3 className="outer-card-title">Change Password</h3>
              </div>
            </Col>
            <Col lg={12}>
              <Card className="outer-card">
                <Form>
                  <Row>
                    <Col lg={4} md={8}>
                      <Row>
                        <Col lg={12} md={12}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Current Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type={showCurrentPassword ? "text" : "password"}
                              name="currentPassword"
                              className={`custom-field custom-group-field ${
                                isEditablePassword
                                  ? "editable-field"
                                  : "non-editable-field"
                              }`}
                              isInvalid={!!errors.currentPassword}
                              onChange={(e) => {
                                e.preventDefault();
                                setCurrentPassword(e.target.value);
                              }}
                              disabled={!isEditablePassword}
                              value={currentPassword}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                togglePasswordVisibility("current")
                              }
                            >
                              {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors.currentPassword}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col lg={12} md={12}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            New Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type={showNewPassword ? "text" : "password"}
                              name="newPassword"
                              className={`custom-field custom-group-field ${
                                isEditablePassword
                                  ? "editable-field"
                                  : "non-editable-field"
                              }`}
                              onChange={(e) => {
                                e.preventDefault();
                                setNewPassword(e.target.value);
                              }}
                              isInvalid={!!errors.newPassword}
                              disabled={!isEditablePassword}
                              value={newPassword}
                            />
                            <InputGroup.Text
                              onClick={() => togglePasswordVisibility("new")}
                            >
                              {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors.newPassword}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col lg={12} md={12}>
                          <Form.Label
                            className="fw-500"
                            style={{ fontSize: "14px" }}
                          >
                            Confirm Password
                          </Form.Label>

                          <InputGroup className="mb-3 custom-group">
                            <FormControl
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              className={`custom-field custom-group-field ${
                                isEditablePassword
                                  ? "editable-field"
                                  : "non-editable-field"
                              }`}
                              isInvalid={!!errors.confirmPassword}
                              onChange={(e) => {
                                e.preventDefault();
                                setConfirmPassword(e.target.value);
                              }}
                              disabled={!isEditablePassword}
                              value={confirmPassword}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                togglePasswordVisibility("confirm")
                              }
                            >
                              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmPassword}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col lg={12} md={12}>
                          <div className="mt-2 mb-2">
                            <Button
                              className="btn-light me-2"
                              onClick={handleCancelPassword}
                            >
                              Cancel
                            </Button>
                            {isEditablePassword ? (
                              <Button
                                style={{ fontSize: "14px" }}
                                onClick={handleSubmit}
                                className="btn"
                                disabled={loaderSubmit}
                              >
                                {loaderSubmit && (
                                  <Spinner
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    animation="border"
                                    variant="danger"
                                    size="sm"
                                    className="me-2"
                                  />
                                )}
                                Change Password
                              </Button>
                            ) : (
                              <Button
                                style={{ fontSize: "14px" }}
                                onClick={handleEditClickForPassword}
                                className="btn"
                              >
                                Edit
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AccountProfile;
