import { useEffect, useRef, useState } from "react";
import { REFRESH_TOKEN } from "../services/apiConfig";
import axios from "axios";
import { Modal, Button, Image } from "react-bootstrap";
import userIcon from "../assets/icons/user.png";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

class RefreshTokenAPI {
  static refresh(email, refreshToken) {
    const jwtToken = localStorage.getItem("token");

    return axios
      .post(
        REFRESH_TOKEN,
        { email, refreshToken },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then(async (response) => {
        const validTillMillis = response.data.data.validTill;
        localStorage.setItem(
          "validTill",
          new Date(validTillMillis).toISOString()
        );
        localStorage.setItem("token", response.data.data.jwtToken);
        console.log("Called Refresh Token API");
      })
      .catch((error) => {
        console.error("Error refreshing token:", error);
      });
  }
}

class RefreshTokenUsingRefreshAPI {
  static refresh(email, refreshToken) {
    return axios
      .post(
        REFRESH_TOKEN,
        { email, refreshToken },
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      )
      .then(async (response) => {
        const validTillMillis = response.data.data.validTill;
        localStorage.setItem(
          "validTill",
          new Date(validTillMillis).toISOString()
        );
        localStorage.setItem("token", response.data.data.jwtToken);
        console.log("Called Refresh Token API 2");
      })
      .catch((error) => {
        console.error("Error refreshing token:", error);
      });
  }
}

export function LogoutAndRedirect(props) {
  const timerRef = useRef(null);
  const channelRef = useRef(null);
  const isRefreshing = useRef(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const handleLogoutTimer = () => {
    timerRef.current = setTimeout(() => {
      resetTimer();
      // console.log("Setting logout timer for 10 minutes");
      // console.log("10 minutes of inactivity reached, showing logout confirmation dialog");
      // setShowLogoutConfirm(prev => !prev ? true : prev);
      if (
        ![
          "/",
          "/home",
          "/HomeTwo",
          "/HomeThree",
          "/about",
          "/register",
          "/login",
          "/ForgotPassword",
          "/ResetPassword/verify-link",
          "/Contact",
        ].includes(window.location.pathname)
      ) {
        logoutAction();
      }
    }, 10 * 60 * 1000);
  };

  const resetTimer = () => {
    if (timerRef.current) {
      // console.log("Resetting timer");
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const setupBroadcastChannel = () => {
    channelRef.current = new BroadcastChannel("logout-channel");

    channelRef.current.onmessage = (event) => {
      // console.log("Broadcast channel message received, resetting timer");
      resetTimer();
      handleLogoutTimer();
    };
  };

  const logoutAction = () => {
    // console.log("User chose to logout");
    // localStorage.clear();
    // sessionStorage.clear();
    // window.location.pathname = "/";
    setShowLogoutConfirm((prev) => true);
  };

  const handleConfirmLogout = (confirm) => {
    setShowLogoutConfirm(false);
    if (confirm) {
      // console.log("User chose to logout");
      localStorage.clear();
      sessionStorage.clear();
      window.location.pathname = "/";
    } else {
      // console.log("User chose to stay logged in, refreshing token and restarting timer");
      const email = localStorage.getItem("email");
      const refreshToken = localStorage.getItem("refreshToken");
      // Call refresh API when user wants to stay logged in
      RefreshTokenAPI.refresh(email, refreshToken).finally(() => {
        handleLogoutTimer(); // Restart the inactivity timer after refreshing
      });
    }
  };

  const checkValidTillAndRefresh = () => {
    const validTillString = localStorage.getItem("validTill");

    if (!validTillString) {
      // console.log("validTill not found in local storage.");
      return;
    }

    const validTill = new Date(validTillString);
    const currentTime = new Date();
    const fifteenMinusSixMinutesPassed = new Date(
      validTill.getTime() - 6 * 60 * 1000
    );
    if (currentTime >= fifteenMinusSixMinutesPassed && !isRefreshing.current && currentTime < validTill) {
      const email = localStorage.getItem("email");
      const refreshToken = localStorage.getItem("refreshToken");
      isRefreshing.current = true; // Set ref to true
      RefreshTokenAPI.refresh(email, refreshToken).finally(() => {
        isRefreshing.current = false; // Reset ref when done
      });
      console.log("If Called");
      console.log(validTill);
      console.log(fifteenMinusSixMinutesPassed);
    }
    else if(currentTime >= validTill && !isRefreshing.current)
    {
      const email = localStorage.getItem("email");
      const refreshToken = localStorage.getItem("refreshToken");
      isRefreshing.current = true; // Set ref to true
      RefreshTokenUsingRefreshAPI.refresh(email, refreshToken).finally(() => {
      isRefreshing.current = false; // Reset ref when done
      console.log("Else Called");
      console.log(validTill);
      console.log(fifteenMinusSixMinutesPassed);
    });
  }
}

  const handleUserActivity = () => {
    // console.log("User activity detected, resetting timer");
    resetTimer();
    handleLogoutTimer();
    checkValidTillAndRefresh();
    if (channelRef.current && channelRef.current.readyState === "open") {
      channelRef.current.postMessage("activity");
    }
  };

  useEffect(() => {
    setupBroadcastChannel();
    Object.values(events).forEach((item) => {
      window.addEventListener(item, handleUserActivity);
    });

    handleLogoutTimer();

    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, handleUserActivity);
      });

      if (channelRef.current) {
        channelRef.current.close();
      }
    };
  }, []);

  return (
    <>
      {showLogoutConfirm && (
        <Modal
          centered
          backdrop="static"
          show={showLogoutConfirm}
          onHide={() => setShowLogoutConfirm(false)}
        >
          {/* <Modal.Header className="border-0 m-auto pb-0">
            <Modal.Title>
              <h1 className="text-12 mb-0 text-center">Email Unverified</h1>
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body style={{ padding: "35px" }}>
            <div className="text-center mb-3">
              <Image src={userIcon} height={25} alt="Check mailbox icon" />
            </div>
            <h5 className="text-2 text-center mb-3">
              You have been inactive for a while. Do you want to stay logged in?
            </h5>
            <div className="d-flex justify-content-center gap-3 mt-4">
              <Button
                onClick={() => handleConfirmLogout(true)}
                className="btn btn-secondary btn-lg fs-14"
              >
                Logout
              </Button>
              <Button
                onClick={() => handleConfirmLogout(false)}
                className="btn btn-secondary btn-lg fs-14"
              >
                Stay Logged In
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
  // return null;
}

export default LogoutAndRedirect;
