import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import "../login.css";
import "../main.css";
import {loginRequestBody} from "../request/requestBodies"
import {validateLoginForm as ValidateLoginForm} from "../request/validationFunctions"
import AuthService from "../services/authService";

const Login2 = ({ onSignUpClick }) =>  {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword] = useState(true)

  // const validateForm = ValidateLoginForm(email,password)

  const handleSubmit = async (e) => {
    e.preventDefault();

  //  const newErrors = ValidateLoginForm(email, password); 
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //   } else {
      try {
        const apiResponse=await AuthService.login(email,password)
        console.log("login apiResponse",apiResponse)
        // localStorage.setItem("token",apiResponse.token)
        // alert("Submitted!");
        // setErrors({});
        // setFormSubmitted(true);
      } catch (error) {
        alert(
          "Please check your email & password. If you are unable to log in, kindly contact the administrator for assistance."
        );
        console.error("Login failed:", error);
      }
    // }
  };

  const onCaptchaChange = (value) => {
    console.log("recaptcha");
    setCaptchaVerified(!!value); // set to true if captcha is verified
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (

    
    <div id="main-wrapper" className="login authentication-page">
    
      <Container fluid className="px-0">
        <Row className="g-0 align-items-center">
          <Col md={12} lg={12}>
           
          <div className="form-inner">
                    <h1 className="text-12 mb-4 text-center">Hi, Welcome!</h1>
                    <Form id="loginForm" onSubmit={handleSubmit}>
                      <Form.Group controlId="emailAddress">
                        <Form.Label className="fw-500">Email</Form.Label>
                        <div className="mb-3 icon-group icon-group-end">
                          <Form.Control
                            type="email"
                            className="bg-white"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={!!errors.email}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                          <span className="icon-inside text-muted">
                            <i className="fas fa-envelope"></i>
                          </span>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="loginPassword">
                        <Form.Label className="fw-500">Password</Form.Label>
                        <div className="mb-0 icon-group icon-group-end">
                          <Form.Control
                             type={!showConfirmPassword ? 'text' : 'password'}
                            className="form-control-lg bg-white"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={!!errors.password}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                          <span className="icon-inside text-muted" onClick={toggleConfirmPasswordVisibility}>
                          <i className={`fas ${showConfirmPassword ? 'fa-lock' : 'fa-unlock'}`}></i>
                          </span>
                        </div>
                      </Form.Group>

                      <Row className="my-0">
                        <Col className="text-end">
                          <a
                            href="/ForgotPassword"
                            className="forgot text-danger"
                          >
                            Forgot Password?
                          </a>
                        </Col>
                      </Row>
                      <Form.Group
                        controlId="formCaptcha"
                        className="captcha-placeholder mt-3"
                      >
                        <ReCAPTCHA
                          sitekey="your-site-key"
                          onChange={onCaptchaChange}
                        />
                      </Form.Group>
                      <div className="d-grid my-4 mb-2">
                        <Button
                          type="submit"
                          className="btn-secondary btn btn-lg"
                          onClick={handleSubmit}
                        >
                          Login
                        </Button>
                      </div>
                      <p className="text-2 text-muted text-center">
                        Don't have an account?{" "}
                        <a onClick={onSignUpClick} className="text-danger">
                          Sign up
                        </a>
                      </p>
                    </Form>
                  </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login2;
