import axios from "axios";
import CryptoJS from "crypto-js";
import authHeader from "./AuthHeader";
import {
  GET_ALL_COMPANY_LIST,
  VALIDATE_MEMBER,
  CHECK_MEMBER_EXIST,
  VERIFY_OTP,
  GENERATE_LINK,
  VERIFY_LINK,
  CONTACT_US,
} from "./apiConfig";

class externalIntegrationService {
  //done
  getAllCompany() {
    return axios.get(GET_ALL_COMPANY_LIST).then((response) => {
      return response.data;
    });
  }

  
  validateMember(memberNo, companyName) {
    return axios
      .post(
        VALIDATE_MEMBER,
        { memberNo: memberNo, companyName: companyName }
      )
      .then((response) => {
        return response.data;
      });
  }

  checkMemberExist(email, phoneNumber, company, memberName) {
    const phoneNo=`971`+phoneNumber
    return axios
      .post(CHECK_MEMBER_EXIST, { 
        email,
        phoneNumber: phoneNo,
        company,
        memberName,
      })
      .then((response) => {
        return response.data;
      });
  }

  verifyOtp(phoneNumber, otp) {

    const phoneNo=`971`+phoneNumber
    return axios
      .post(VERIFY_OTP, { otp: otp, phoneNumber: phoneNo })
      .then((response) => {
        return response.data;
      });
  }

  generateLink(email) {
    return axios.post(GENERATE_LINK, { email: email }).then((response) => {
      return response.data;
    });
  }

  generateLinkToken(token) {
    return axios.get(GENERATE_LINK+`?token=`+token).then((response) => {
      return response.data;
    });
  }

  verifyLink(token) {
    return axios.post(VERIFY_LINK, { token: token }).then((response) => {
      return response.data;
    });
  }

  //done
  contactUs(email, comment) {
    return axios
      .post(CONTACT_US, { emailId: email, comment: comment })
      .then((response) => {
        return response.data;
      });
  }
}

export default new externalIntegrationService();
