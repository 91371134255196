import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {
  Table,
  Button,
  Form,
  Image,
  Nav,
  Modal,
  Pagination,
} from "react-bootstrap";
import "../table.css";
import editIcon from "../assets/icons/edit.png";
import deleteIcon from "../assets/icons/delete.png";
import { NavLink, useNavigate } from "react-router-dom";
import adminService from "../services/adminService";
import LogoutAndRedirect from "./LogoutAndRedirect";
import { toast } from "react-toastify";

const ManageAdmin = () => {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  // const fetchData = async () => {
  //   await adminService.getAdminsUsers().then((res) => {
  //     console.log("res", res.data);
  //     setAdmins(res.data);
  //   });
  // };

  const fetchData = async () => {
    try {
      // Fetch both admin and super admin data concurrently
      const [adminRes, superAdminRes] = await Promise.all([
        adminService.getAdminsUsers(),
        adminService.getSuperAdminsUsers(),
      ]);

      // Add the 'role' field to each record in admin and super admin data
      const adminsWithRole = adminRes.data.map((admin) => ({
        ...admin,
        role: "Admin",
      }));

      const superAdminsWithRole = superAdminRes.data.map((superAdmin) => ({
        ...superAdmin,
        role: "Super Admin",
      }));

      // Combine both arrays into a single array
      const combinedData = [...adminsWithRole, ...superAdminsWithRole]
        .sort((a, b) => b.createdAt - a.createdAt);

      // Update state with the combined data
      setAdmins(combinedData);
      console.log("Combined Data", combinedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredProfiles =
    admins?.length > 0
      ? admins?.filter(
          (profile) =>
            profile?.firstName
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            profile?.lastName
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
        )
      : [];

  // Calculate total pages and data slice for Tab 1
  const totalPages = Math.ceil(filteredProfiles?.length / 10);
  const startIndex = (currentPage - 1) * 10;
  const currentProfiles = filteredProfiles?.slice(startIndex, startIndex + 10);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    const validPattern = /^[a-zA-Z0-9.@_,-]*$/;
    if (validPattern.test(value)) {
        setSearchQuery(value);
        setCurrentPage(1);
    } else {
      console.log("Invalid character detected. Only letters, numbers, ., @, -, and _ are allowed.");
    }
};

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-sm-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Manage Admin</h5>
                    </div>
                    <div class="ms-auto d-sm-flex align-items-center">
                      <div className="me-0 me-sm-2 mb-sm-0 mb-2 mt-2 mt-sm-0 mb-sm-0">
                        <Form.Group
                          style={{position: "relative" }}
                          controlId="formBasicSearch"
                        >
                          <svg
                            style={{
                              position: "absolute",
                              top: "8px",
                              left: "10px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.5835 14.5831L18.3335 18.3331"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Form.Control
                            type="text"
                            className="form-search-field w-100"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => {
                              handleSearchChange(e);
                            }}
                          />
                        </Form.Group>
                      </div>
                      <Button
                        variant=""
                        style={{ fontSize: "14px" }}
                        href="./AddAdmin"
                      >
                        <i class="fa-solid fa-circle-plus text-white"></i> Add
                        Admin
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <Row>
                    {/* <Col lg={12}>
                      <div>
                        <Form.Group
                          style={{ padding: "10px 10px", position: "relative" }}
                          controlId="formBasicSearch"
                        >
                          <svg
                            style={{
                              position: "absolute",
                              top: "18px",
                              left: "20px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14.5835 14.5831L18.3335 18.3331"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                              stroke="#093B60"
                              stroke-width="1.25"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Form.Control
                            type="text"
                            className="form-search-field w-25"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => {
                              handleSearchChange(e);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </Col> */}
                    <Col lg={12}>
                      <Table bordered hover responsive className="mt-3">
                        <thead className="table">
                          <tr>
                            <th className="no-wrap-header">Member ID</th>
                            <th className="no-wrap-header">Name</th>
                            <th className="no-wrap-header">Role</th>
                            <th className="no-wrap-header">Created Date</th>
                            <th className="no-wrap-header">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentProfiles &&
                            currentProfiles.length !== 0 &&
                            currentProfiles.map((report, index) => (
                              <tr key={index}>
                                <td>
                                  <Nav.Link
                                    className="text-decoration-underline"
                                    onClick={() => {
                                      navigate("/AdminProfile", {
                                        state: { email: report.email },
                                      });
                                    }}
                                  >
                                    {report.id}
                                  </Nav.Link>
                                </td>
                                <td>
                                  <Nav.Link
                                    className="text-decoration-underline"
                                    onClick={() => {
                                      navigate("/AdminProfile", {
                                        state: { email: report.email },
                                      });
                                    }}
                                  >
                                    {report.firstName} {report.lastName}
                                  </Nav.Link>
                                </td>
                                <td>{report.role}</td>

                                <td>
                                  <p className="overflow-description">
                                    {new Date(
                                      report.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Nav.Link
                                      onClick={() => {
                                        navigate("/AdminProfile", {
                                          state: { email: report.email },
                                        });
                                      }}
                                      variant="link"
                                      className="me-3"
                                    >
                                      <Image
                                        style={{ width: "14px" }}
                                        src={editIcon}
                                      />
                                    </Nav.Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          {currentProfiles.length === 0 && (
                            <tr>
                              <td colSpan={5}>
                                <div className="d-flex justify-content-center">
                                  <p>No data found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg={12}>
                      <Pagination style={{ justifyContent: "end" }}>
                        <Pagination.First
                          onClick={() => handlePageChange(1)}
                          disabled={currentPage === 1}
                        />
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />

                        {/* Generate page numbers dynamically */}
                        {[...Array(totalPages)].map((_, pageNumber) => (
                          <Pagination.Item
                            key={pageNumber + 1}
                            active={pageNumber + 1 === currentPage}
                            onClick={() => handlePageChange(pageNumber + 1)}
                          >
                            {pageNumber + 1}
                          </Pagination.Item>
                        ))}

                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="text-center custom-modal"
      >
        <Modal.Header className="justify-content-center border-0">
          <Modal.Title>
            <h4 className="fw-700">Confirm Delete</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete TDM report from system? This action
            cannot be undone
          </p>{" "}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button
            className="btn-light"
            style={{ fontSize: "14px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-danger"
            style={{ fontSize: "14px" }}
            variant="danger"
            onClick={() => {
              // Your delete logic here
              handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageAdmin;
