import React, { useState, useEffect } from "react";
import "../dashboard.css";
import { Row, Form, Breadcrumb, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { createResearchDataRequestBody } from "../request/requestBodies";
import {
  validateCreateResearchDataForm,
  validateCreateResearchDataFormForEdit,
} from "../request/validationFunctions";
import researchDataService from "../services/researchDataService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../assets/backIcon.png";
import { Alert } from "react-bootstrap";
import deleteIcon from "../assets/icons/delete.png";
import uploadIcon from "../assets/icons/upload.png";
import Spinner from "react-bootstrap/Spinner";
import LogoutAndRedirect from "./LogoutAndRedirect";
import { Nav } from "react-bootstrap";

const AddDataReport = () => {
  const [image, setImage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("id", id);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState(createResearchDataRequestBody);
  const [loader, setLoader] = useState(false);
  //const [errorMessage,]

  useEffect(() => {
    if (id) {
      // Fetch data if id exists (edit mode)
      researchDataService
        .getResearchDataById(id)
        .then((res) => {
          const data = res.data;
          setFormData({
            blobLocation: data.blobLocation,
            workspaceID: data.workspaceID,
            tenantID: data.tenantID,
            fileName: data.fileName,
            fileDescription: data.fileDescription,
            status: data.status, // Optional, depending on if you need to show it
            createdBy: data.createdBy, // Optional, if required in the form
            updatedBy: data.updatedBy,
            thumbnailFileName: data.thumbnailFileName,
            thumbnailBlobLocation: data.thumbnailBlobLocation,
          });
        })
        .catch((error) => {
          toast.error("Error fetching report data!");
        });
    }
  }, [id]);

  console.log("formData", formData);

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.target.files[0]; // Get the first file dropped
    if (file.size > 4194304) {
      // 4MB
      setError({
        ...error,
        image: "File size should be less than 4MB",
      });
    } else {
      const newError = { ...error };
      delete newError.image;
      setError(newError);
    }
    handleFile(file);
  };

  const handleDropThumbnail = (event) => {
    event.preventDefault();
    const file = event.target.files[0]; // Get the first file dropped
  
    if (file.size > 4194304) {
      // File size greater than 4MB
      setError({
        ...error,
        thumbnailImage: "File size should be less than 4MB",
      });
      return; // Stop further processing
    } else {
      // Clear the error if size is valid
      const newError = { ...error };
      delete newError.thumbnailImage;
      setError(newError);
    }
  
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const validExtensions = ['png', 'jpg', 'jpeg', 'gif'];
  
    if (!validExtensions.includes(fileExtension)) {
      setError({
        ...error,
        thumbnailImage: "Invalid file type. Please upload an image (PNG, JPG, JPEG, GIF).",
      });
      return;
    } else {
      const newError = { ...error };
      delete newError.thumbnailImage;
      setError(newError);
    }
    handleThumbnailFile(file);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };
  const handleFile = (file) => {
    setImage(file);
  };
  const handleThumbnailFile = (file) => {
    setThumbnail(file);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const getLastElement = (str) => {
    const parts = str.split(".");
    return parts.pop();
  };

  const handleDownload = async (blobLocation, fileName) => {
    const filename = fileName;
    // e.preventDefault();
    try {
      console.log("in download", blobLocation);
      const extension = getLastElement(blobLocation);
      // Make an API call to download the file as a blob
      await researchDataService.downloadBlob(blobLocation).then((res) => {
        const base64Data = res?.data?.data;
        if (!base64Data) {
          throw new Error("No data found to download");
        }

        const extension = blobLocation.split('.').pop().toLowerCase();

        // Convert base64 to a Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);

        // Create a link element and trigger download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the Object URL after download
        URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const handleSaveClick = async () => {
    setLoader(true);
    let newErrors;
    if (id) {
      newErrors = validateCreateResearchDataFormForEdit(
        formData.workspaceID,
        formData.tenantID,
        formData.fileName,
        formData.fileDescription
      );
    } else {
      newErrors = validateCreateResearchDataForm(
        formData.workspaceID,
        formData.tenantID,
        formData.fileName,
        formData.fileDescription,
        image,
        thumbnail
      );
    }

    setError(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        if (id) {
          const requestBody = {
            ...formData,
            status: "Active",
            id: id,
            updatedBy: localStorage.getItem("email"),
          };
          if (
            (image !== null && image !== undefined) ||
            (thumbnail !== null && thumbnail !== undefined)
          ) {
            if (image != null && image !== undefined) {
              const file = image; // Get the selected file from input
              const formDataDoc = new FormData(); // Create new FormData object
              formDataDoc.append("file", file);
              // Upload the blob (file)
              const uploadResponse = await researchDataService.uploadBlob(
                formDataDoc
              );
              requestBody.blobLocation = uploadResponse.data.data;
            }
            if (thumbnail !== null && thumbnail !== undefined) {
              const file = thumbnail; // Get the selected file from input
              const formDataDoc = new FormData(); // Create new FormData object
              formDataDoc.append("file", file);
              // Upload the blob (file)
              const uploadResponse = await researchDataService.uploadBlob(
                formDataDoc
              );
              requestBody.thumbnailBlobLocation = uploadResponse.data.data;
            }
            // Log the request body in a more readable way
            console.log("Req Body:", requestBody);
            // Update existing report (do not stringify here, send the object directly)
            await researchDataService.updateResearchData(id, requestBody);
            // Show success toast
            toast.success("Report updated successfully!", {
              position: "top-right",
              autoClose: 2000,
            });

            setTimeout(() => {
              navigate("/DataReport"); // Navigate to the DataReport page after 3 seconds
            }, 2000);
          } else {
            const requestBody = {
              ...formData,
              status: "Active",
              id: id,
              updatedBy: localStorage.getItem("email"),
            };
            // Log the request body in a more readable way
            console.log("Req Body:", requestBody);
            // Update existing report (do not stringify here, send the object directly)
            await researchDataService.updateResearchData(id, requestBody);
            // Show success toast
            toast.success("Report updated successfully!", {
              position: "top-right",
              autoClose: 2000,
            });

            setTimeout(() => {
              navigate("/DataReport"); // Navigate to the DataReport page after 3 seconds
            }, 2000);
          }
        } else {
          // Create new report
          try {
            const file = image; // Get the selected file from input
            const formDataDoc = new FormData(); // Create new FormData object
            formDataDoc.append("file", file);

            // Upload the blob (file)
            const uploadResponse = await researchDataService.uploadBlob(
              formDataDoc
            );
            console.log("res after upload blob", uploadResponse);

            // Create requestBody for the research data
            const requestBody = {
              ...formData,
              blobLocation: uploadResponse.data.data, // Assuming the response contains blob URL
              status: "Active",
              createdBy: localStorage.getItem("email"),
            };

            const thumbnailFile = thumbnail;
            const formDataDocThumbnail = new FormData(); // Create new FormData object
            formDataDocThumbnail.append("file", thumbnailFile);

            // Upload the blob (file)
            const uploadThumbnailResponse =
              await researchDataService.uploadBlob(formDataDocThumbnail);
            requestBody.thumbnailBlobLocation =
              uploadThumbnailResponse.data.data;
            requestBody.thumbnailFileName = formData.fileName;

            // Create research data with the uploaded blob info
            const createResponse = await researchDataService.createResearchData(
              requestBody
            );
            console.log("res after research data", createResponse);

            // Show success toast and redirect after success
            toast.success("Report created successfully!", {
              position: "top-right",
              autoClose: 2000,
            });

            setTimeout(() => {
              navigate("/DataReport"); // Navigate to the DataReport page after 3 seconds
            }, 2000);
          } catch (error) {}
        }
        //navigate("/DataReport");
      } catch (error) {
        console.log("error", error);
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        toast.error("Error occurred while saving!", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } else {
      setLoader(false);
    }
  };

  console.log("error", error);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    const newError = { ...error };
    delete newError[name];
    setError(newError);
  };

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => window.history.back()}
                  alt="back"
                />{" "}
                <a
                  className="fw-400"
                  onClick={() => {
                    navigate({ pathname: "/DataReport" });
                  }}
                >
                  Research Data
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="fw-500 no-link"
                style={{ cursor: "unset" }}
              >
                <strong className="fw-500">Add Reseach Data</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Research Data</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form onSubmit={handleSaveClick}>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Research Data Details
                              </h5>
                            </Col>

                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Workspace ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="workspaceID"
                                  className="custom-field"
                                  value={formData.workspaceID}
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.workspaceID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.workspaceID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Tenant ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="tenantID"
                                  className="custom-field"
                                  value={formData.tenantID}
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.tenantID}
                                  maxLength={20}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.tenantID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            

                                                     </Row>
                        </div>

                        <div className="form-inner position-relative p-3 mt-4 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                File Details
                              </h5>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  File Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="fileName"
                                  value={formData.fileName}
                                  className="custom-field"
                                  onChange={(e) => handleInputChange(e)}
                                  isInvalid={!!error?.fileName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.fileName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Upload Document
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  name="uploadDocument"
                                  className="custom-field"
                                  onChange={(e) => handleDrop(e)}
                                  isInvalid={!!error?.image}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.image}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                           
                            {id && (
                              <>
                                <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                                  <Form.Group className="mb-2">
                                    <Form.Label
                                      className="fw-500"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Download Document
                                    </Form.Label>
                                    <Nav.Link
                                      onClick={(e) => {
                                        handleDownload(
                                          formData.blobLocation,
                                          formData.fileName
                                        );
                                      }}
                                      variant="link"
                                      className="text-danger"
                                    >
                                      <span
                                        className="fa fa-download me-2"
                                        style={{
                                          // width: "14px",
                                          fontSize: "12px",
                                          // paddingLeft: "20px",
                                        }}
                                      />
                                    </Nav.Link>
                                  </Form.Group>
                                </Col>
                              </>
                            )}
                            
                           

                            </Row>
                            <Row>
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  File Description
                                </Form.Label>
                                <Form.Group controlId="formTextarea">
                                  <Form.Control
                                    name="fileDescription"
                                    as="textarea"
                                    value={formData.fileDescription}
                                    className="custom-field overflow-hidden"
                                    rows={1}
                                    placeholder=""
                                    onChange={(e) => handleInputChange(e)}
                                    isInvalid={!!error?.fileDescription}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {error?.fileDescription}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Form.Group>
                            </Col>
                            </Row>
                            </div>

                            <div className="form-inner position-relative p-3 mt-4 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Thumbnail
                              </h5>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Upload Thumbnail
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  name="uploadThumbnail"
                                  className="custom-field"
                                  onChange={(e) => handleDropThumbnail(e)}
                                  isInvalid={!!error?.thumbnailImage}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {error?.thumbnailImage}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            {id && (
                              <>
                           
                                <Col xxl={4} xl={4} lg={4} md={6} sm={12}>
                                  <Form.Group className="mb-2">
                                    <Form.Label
                                      className="fw-500"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Download Thumbnail
                                    </Form.Label>
                                    <Nav.Link
                                      onClick={(e) => {
                                        handleDownload(
                                          formData.thumbnailBlobLocation,
                                          formData.thumbnailFileName,
                                        );
                                      }}
                                      variant="link"
                                      className="text-danger"
                                    >
                                      <span
                                        className="fa fa-download me-2"
                                        style={{
                                          // width: "14px",
                                          fontSize: "12px",
                                          // paddingLeft: "20px",
                                        }}
                                      />
                                    </Nav.Link>
                                  </Form.Group>
                                </Col>
                              </>
                            )}

                            </Row>
                            </div>

                        <Row className="mt-4">
                          <Col
                            lg={12}
                            className="add-product-footer-btn text-end"
                          >
                            <Button
                              className="btn-light me-2"
                              onClick={() => {
                                navigate({ pathname: "/DataReport" });
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              style={{ fontSize: "14px" }}
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSaveClick();
                              }}
                              disabled={
                                loader || (error && Object.keys(error).length)
                              }
                            >
                              {loader && (
                                <Spinner
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  animation="border"
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                />
                              )}
                              Save
                              {/* {loader && (
                              <Spinner animation="border" variant="danger" />
                            )} */}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddDataReport;
