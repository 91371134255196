import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import logo from "../assets/d-logo.svg";
import logo from "../assets/logo.png";
import checkMailboxIcon from "../assets/icons/Check-mailbox-icon.png";
import "../login.css";
import "../main.css";
import CRMService from "../services/crmService";
import AuthService from "../services/authService";
import roles from "../request/role";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import checked from "../assets/icons/checked.png";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import externalIntegration from "../services/externalIntegration";
import Spinner from "react-bootstrap/Spinner";

const Register = () => {
  const navigate = useNavigate();
  const [ip, setIP] = useState("");
  const [errorCounter, setErrorCounter] = useState(1);
  const [errorCounter1, setErrorCounter1] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);
  const [popupMessage1, setPopupMessage1] = useState(null);
  const [verificationStage, setVerificationStage] = useState(1);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [showMailboxPopup, setShowMailboxPopup] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    company: "",
    memberNumber: "",
    password: "",
    confirmPassword: "",
    role: roles.MEMBER,
    checkboxChecked: false,
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [criteria, setCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [businessBlank, setBusinessBlank] = useState(false);
  const [personalBlank, setPersonalBlank] = useState(false);
  const [personalPhone, setPersonalPhone] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [crmVerifyFlag, setCrmVerifyFlag] = useState(false);
  const [loader, setLoader] = useState(false);
const [loader1, setLoader1]=useState(false)
const [loader2, setLoader2]=useState(false)
const [errorOnVerify,setErrorOnVerify]=useState(false);

  const validateForm = () => {
    const newErrors = {};
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      company,
      memberNumber,
      password,
      confirmPassword,
      checkboxChecked,
    } = formData;

    // Validation logic
    if (!firstName) {
      newErrors.firstName = "First Name is required";
    } else if (firstName.length < 2 || firstName.length > 30) {
      newErrors.firstName = "First Name must be between 2 and 30 characters";
    }

    if (!lastName) {
      newErrors.lastName = "Last Name is required";
    } else if (lastName.length < 2 || lastName.length > 30) {
      newErrors.lastName = "Last Name must be between 2 and 30 characters";
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^(5\d{8})$/.test(phoneNumber)) {
      newErrors.phoneNumber =
        "Phone Number must be exactly 9 digits and start with 5";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!company) {
      newErrors.company = "Company is required";
    } else if (company.length < 2 || company.length > 50) {
      newErrors.company = "Company name must be between 2 and 50 characters";
    }

    if (!memberNumber) {
      newErrors.memberNumber = "Member Number is required";
    } else if (!/^[a-zA-Z0-9]+$/.test(memberNumber)) {
      newErrors.memberNumber =
        "Member Number must contain only letters and numbers";
    } else if (memberNumber.length < 1 || memberNumber.length > 15) {
      newErrors.memberNumber =
        "Member Number must be between 1 and 15 characters";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      newErrors.password =
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a symbol";
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!checkboxChecked) {
      newErrors.checkboxChecked = true;
    }

    return newErrors;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //console.log("kkk");
    await externalIntegration.getAllCompany().then((res) => {
      console.log("companies", res.data);
      setCompanyList(res.data);
    });
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    //  console.log("newOtp", newOtp);
    // Move to next input if available
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleVerifyClick = async (e) => {
    setLoader1(true)
    if (formData.email !== "" && formData.phoneNumber !== "") {
      if (!/^5\d{8}$/.test(formData.phoneNumber)) {
        setPersonalBlank(false);
        setPersonalPhone(true);
        setLoader1(false)
      } else {
        await externalIntegration
          .checkMemberExist(
            formData.email,
            formData.phoneNumber,
            formData.company,
            `${formData.firstName} ${formData.lastName}`
          )
          .then((res) => {
            console.log("res", res?.data);
            if (res?.data?.isEmailExits) {
              setEmailError(res?.data?.isEmailExits);
            }
            if (res?.data?.isPhoneExits) {
              setPhoneError(res?.data?.isPhoneExits);
            }
            if (!res?.data?.isPhoneExits && !res?.data?.isEmailExits) {
              setShowOtpPopup(true);
            }
            setLoader1(false)
          })
          .catch((err) => {console.log(err); setLoader1(false)});

        // setErrorCounter1(true);
        // e.preventDefault();
        // setShowOtpPopup(true);
        // setPersonalBlank(false);
      }
    } else {
      setLoader1(false)
      setPersonalBlank(true);
    }
    // Reset the flag if phone number is valid
  };

  const handleOtpVerify = async () => {
    // setShowOtpPopup(false); // Close OTP popup
    // setShowMailboxPopup(true); // Open registered mailbox popup
    const OTP = otp.join("");
    await externalIntegration
      .verifyOtp(formData.phoneNumber, OTP)
      .then((res) => {
        if (res?.data?.isVerified) {
          setOtpError("");
          setShowOtpPopup(false); // Close OTP popup
          generateEmailVerifyLink(formData.email);
          setShowMailboxPopup(true); // Open registered mailbox popup
        } else {
          setOtpError("Invalid OTP");
        }
      })
      .catch((err) => console.log(err));
  };

  const generateEmailVerifyLink = (email) => {
    externalIntegration.generateLink(email);
  };

  const handleCloseMailboxPopup = () => {
    setErrorCounter1(true);
    setShowMailboxPopup(false); // Close registered mailbox popup
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(name, value);
    if (name === "password") {
      setPassword(value);
      validatePassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
    if (name === "phoneNumber") {
      setPersonalPhone(false);
      setPhoneError(false);
    }
    if (name === "email") {
      setEmailError(false);
    }
  };
  const validatePassword = (value) => {
    const minLength = value.length >= 8;
    const uppercase = /[A-Z]/.test(value);
    const lowercase = /[a-z]/.test(value);
    const number = /[0-9]/.test(value);
    const specialChar = /[@!&$?%*]/.test(value);

    setCriteria({
      minLength,
      uppercase,
      lowercase,
      number,
      specialChar,
    });

    setErrors({
      ...errors,
      password:
        !minLength || !uppercase || !lowercase || !number || !specialChar,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader2(true)
    if (errorCounter === 0) {
      console.log("Formdata", formData);
      const newErrors = validateForm();
      if (Object.keys(newErrors).length > 0) {
        console.log("newErrors", newErrors);
        setErrors(newErrors);
      } else {
        const requestBody = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: `971`+formData.phoneNumber,
          email: formData.email,
          role: formData.role,
          company: formData.company,
          memberNumber: formData.memberNumber,
          password: formData.password,
          createdBy: formData.role,
          status: "ACTIVE",
        };
        try {
          const apiResponse = await AuthService.register(requestBody);
          console.log("apiResponse", apiResponse.data);

          setPopupMessage(apiResponse.data.email);
          setPopupMessage1(apiResponse.data.password);
          setShowPopup(true);

          // alert(
          //   "Submitted!\n" +
          //   "Email: " + apiResponse.data.email + "\n" +
          //   "Password: " + apiResponse.data.password
          // );

          window.location.href = "/login";
          // navigate('/login');
          setErrors({});
          setBusinessBlank(false);
        } catch (error) {
          console.log(error.response.data.data["invalid request"]);
          const errormsg = error.response.data.data["invalid request"];
          toast.error(errormsg || "Error Occured while Register", {
            position: "top-center", // Use string directly
            autoClose: 5000,
          });
          // alert(error.response.data.message || "Error Occured while Register")
          console.log("error", error.response.data.message);
          // alert(error);
        }
      }
      setLoader2(false)
    } else if (!setCrmVerifyFlag) {
      setLoader2(false)
      setBusinessBlank(true);
    } else {
      setLoader2(false)
      setBusinessBlank(true);
    }
  };

  const handleCRMVerify = async (e) => {
    setLoader(true);
    e.preventDefault();
    const requestBody = {
      company: formData.company,
      memberNumber: formData.memberNumber,
    };
    if (formData.memberNumber === "" || formData.company === "") {
      setBusinessBlank(true);
    } else {
      await externalIntegration
        .validateMember(formData.memberNumber, formData.company)
        .then((res) => {
          console.log("res from validate", res);
          if (res?.data?.isVerified) {
            setErrorCounter(0);
            setBusinessBlank(false);
            setCrmVerifyFlag(true);
          }
          else {
            setErrorCounter(errorCounter + 1);
            if (errorCounter === 6) {
              // const response = await axios.get("https://api.ipify.org?format=json");
              // setIP(response.data.ip);
              navigate("/Contact");
            }
            setBusinessBlank(false);
          }
          setErrorOnVerify(false)
        }).catch((error)=>{
          if(error){
            setErrorOnVerify(error?.response?.data?.data?.['invalid request'] || "Unable to verify at this moment. Please try again later.")
          }
        })
        .finally(() => { setLoader(false); });
    }

    //console.log("formData.memberNumber", formData.memberNumber);
    // if (formData.memberNumber === "12345678" && formData.company) {
    //   setErrorCounter(0);
    //   setBusinessBlank(false);
    //   setCrmVerifyFlag(true);
    // } else if (formData.memberNumber === "" || formData.company === "") {
    //   setBusinessBlank(true);
    // } else {
    //   setErrorCounter(errorCounter + 1);
    //   if (errorCounter === 6) {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     setIP(response.data.ip);
    //     navigate("/Contact");
    //   }
    //   setBusinessBlank(false);
    // }

    // alert("crm request body", requestBody);
    setVerificationStage(2);
  };

  const handleCheckboxChange = (event) => {
    console.log("event.target.checked", event.target.checked);
    setFormData({ ...formData, checkboxChecked: event.target.checked });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href = "/login";
    // navigate('/login');
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleBackspace = (index) => {
    if (index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";  
      setOtp(newOtp);
      document.querySelectorAll('.otp-input')[index - 1].focus();
    } else {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };
  

  return (
    <div id="main-wrapper" className="login authentication-page">
      <ToastContainer />
      <Container fluid className="px-0">
        <Row className="g-0 min-vh-100">
          <Col md={6} lg={6} className="d-flex flex-column align-items-center">
            <Container fluid className="pt-4">
              <Row className="g-0">
                <Col xs={12}>
                  <div className="logo  px-0 px-sm-5 navlogo">
                    <a
                      className="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                      href="/home"
                      title="Dubai Chambers"
                    >
                      <Image src={logo} alt="Logo" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid className="my-auto py-5 container-pls">
              <Row className="g-0">
                {/* <Col xs={11} md={11} lg={11} xl={10} xxl={11} className="mx-auto"> */}
                <Col xs={12} className="mx-auto  px-0 px-sm-5">
                  <div className="card p-25 mb-3 form-inner-width-0 form-inner">
                    <h1 className="text-12 mb-4">Registration</h1>
                    <Form onSubmit={handleSubmit}>
                      <div className="form-inner position-relative p-3 pt-4 mb-4">
                        <Row>
                          <Col lg={12}>
                            <h5 className="text-12 mb-4 overlay-title">
                              Business details
                            </h5>
                          </Col>
                          <Col md={12} lg={6}>
                            <Form.Group controlId="company">
                              <div className="mb-3 icon-group icon-group-end">
                                <Form.Label className="fw-500">
                                  Company Name
                                </Form.Label>
                                <Form.Select
                                  name="company"
                                  style={{ 
                                    height: '42px',
                                    textOverflow: 'ellipsis',
                                    paddingRight: '45px',
                                    textTransform: 'capitalize'
                                   }}
                                  className="bg-white minimal select custom-select"
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.company}
                                  disabled={errorCounter === 0}
                                >
                                  <option value="" className="first-option">
                                    <span
                                      style={{ color: "#CCCBC8 !important" }}
                                    >
                                      Select
                                    </span>
                                  </option>
                                  {companyList?.length > 0 &&
                                    companyList.map((item) => (
                                      <option
                                        key={item.id}
                                        value={item.company_name}
                                      >
                                        {item.company_name}
                                      </option>
                                    ))}
                                  {/* <option value="Mainland">Company1</option>
                                  <option value="Freezone">Company2</option> */}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {errors.company}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12} lg={6}>
                            <Form.Group controlId="memberNumber">
                              <Form.Label className="fw-500">
                                Member / License Number
                              </Form.Label>
                              <div className="mb-0 icon-group icon-group-end">
                                <Form.Control
                                  type="text"
                                  name="memberNumber"
                                  className="form-control-lg bg-white"
                                  placeholder="17847"
                                  required
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.memberNumber}
                                  maxLength={15}
                                  disabled={errorCounter === 0}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.memberNumber}
                                </Form.Control.Feedback>
                              </div>

                              <p className="text-2 text-end mt-2">
                                {errorCounter !== 0 ? (
                                  <Button
                                    onClick={handleCRMVerify}
                                    className="btn btn-red btn-sm fs-14"
                                    disabled={loader}
                                  >
                                    {loader && (
                                      <Spinner
                                        style={{ justifyContent: "center", alignItems: "center" }}
                                        animation="border"
                                        variant="danger"
                                        className="me-2"
                                        size="sm"
                                      />

                                    )}
                                    Verify*
                                  </Button>
                                ) : (
                                  <p className="text-success fs-14">
                                    <Image src={checked} width="14" /> Verified
                                  </p>
                                )}
                              </p>
                            </Form.Group>
                          </Col>
                          {businessBlank && (
                            <p className="text-danger">
                              Kindly fill with the Business Details and Verify
                            </p>
                          )}
                          {errorCounter > 1 && (
                            <p className="text-danger">
                              Incorrect Company Name/Member Number
                            </p>
                          )}
                          {errorOnVerify && (
                            <p className="text-danger">
                              {errorOnVerify}
                            </p>
                          )}
                        </Row>
                      </div>
                      {errorCounter === 0 && (
                        <div className="form-inner position-relative p-3 pt-4 mb-4">
                          <Row>
                            <Col lg={12}>
                              <h5 className="text-12 mb-4 overlay-title">
                                Personal Details
                              </h5>
                            </Col>

                            <Col md={12} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="firstName"
                              >
                                <Form.Label className="fw-500">
                                  First Name
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    className="form-control-lg bg-white"
                                    placeholder="John"
                                    required
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                      // Replace any non-letter characters
                                      e.target.value = e.target.value.replace(
                                        /[^a-zA-Z]/g,
                                        ""
                                      );
                                    }}
                                    isInvalid={!!errors.firstName}
                                    maxLength={50}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                              <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label className="fw-500">
                                  Last Name
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="text"
                                    name="lastName"
                                    className="form-control-lg bg-white"
                                    placeholder="Doe"
                                    required
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                      // Replace any non-letter characters
                                      e.target.value = e.target.value.replace(
                                        /[^a-zA-Z]/g,
                                        ""
                                      );
                                    }}
                                    isInvalid={!!errors.lastName}
                                    maxLength={50}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                              <Form.Group className="mb-3" controlId="email">
                                <Form.Label className="fw-500">
                                  Email Address
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    className="form-control-lg bg-white"
                                    placeholder="abc@abc.com"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.email}
                                    disabled={errorCounter1}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                              <Form.Group controlId="phoneNumber">
                                <Form.Label className="fw-500">
                                  Mobile Number
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end">
                                  <span
                                    className=" translate-middle-y ps-2"
                                    style={{
                                      position: "absolute",
                                      top: "30px",
                                      height: "42px",
                                    }}
                                  >
                                    +971
                                  </span>
                                  <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    className="form-control-lg bg-white ps-5"
                                    placeholder="599999999"
                                    required
                                    onInput={(e) => {
                                      // Remove any non-numeric characters
                                      e.target.value = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      );

                                      // Limit the value to 9 digits
                                      if (e.target.value.length > 9) {
                                        e.target.value = e.target.value.slice(
                                          0,
                                          9
                                        );
                                      }
                                    }}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.phoneNumber}
                                    maxLength={10}
                                    disabled={errorCounter1}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.phoneNumber}
                                  </Form.Control.Feedback>
                                </div>
                                <p className="text-2 text-end mt-2">
                                  {errorCounter1 === false ? (
                                    <Button
                                      onClick={handleVerifyClick}
                                      className="btn btn-red btn-sm fs-14"
                                      disabled={loader1}
                                    >
                                      {loader1 && (
                                      <Spinner
                                        style={{ justifyContent: "center", alignItems: "center" }}
                                        animation="border"
                                        variant="danger"
                                        className="me-2"
                                        size="sm"
                                      />

                                    )}
                                      Verify*
                                    </Button>
                                  ) : (
                                    <p className="text-success fs-14">
                                      <Image src={checked} width="14" />{" "}
                                      Verified
                                    </p>
                                  )}

                                  {/* <Button
                                    onClick={handleVerifyClick}
                                    className="btn btn-red btn-sm fs-14"
                                  >
                                    Verify*
                                  </Button> */}
                                </p>
                              </Form.Group>
                            </Col>
                            {personalBlank && (
                              <p className="text-danger">
                                Kindly fill with the Personal Details
                              </p>
                            )}
                            {personalPhone && (
                              <p className="text-danger">
                                Incorrect Phone Number
                              </p>
                            )}
                            {phoneError && !emailError && (
                              <p className="text-danger">
                                Phone Number already exists
                              </p>
                            )}
                            {!phoneError && emailError && (
                              <p className="text-danger">
                                Email already exists
                              </p>
                            )}
                            {phoneError && emailError && (
                              <p className="text-danger">
                                Email and Phone Number already exist
                              </p>
                            )}
                            {errorCounter1 > 1 && (
                              <p className="text-danger">
                                Incorrect Company Name/Member Number
                              </p>
                            )}
                          </Row>
                          <div>
                            {/* OTP Modal */}
                            <Modal
                              centered
                              show={showOtpPopup}
                              onHide={() => setShowOtpPopup(false)}
                            >
                              <Modal.Header className="border-0 m-auto pb-0">
                                <Modal.Title>
                                  <h1 className="text-12 mb-0 text-center">
                                    OTP
                                  </h1>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ padding: "35px" }}>
                                <Form.Group controlId="formOtp">
                                  <h5 className="text-2 text-center">
                                    Complete the registration by entering the
                                    OTP <br />
                                    we sent to mobile no. +971 {formData.phoneNumber}
                                  </h5>
                                  <div className="otp-container mb-3">
                                    {otp.map((data, index) => {
                                      return (
                                        <input
                                          className="otp-input"
                                          type="text"
                                          maxLength="1"
                                          key={index}
                                          value={data}
                                          onChange={(e) => {
                                            setOtpError("");
                                            handleChange(e.target, index);
                                          }}
                                          onFocus={(e) => e.target.select()}
                                          onKeyDown={(e) => {
                                            if (e.key === "Backspace" || e.key === "Delete") {
                                              handleBackspace(index);
                                            }
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                  
                                  {otpError !== "" && (
                                    <p className="text-danger text-center mb-2">{otpError}</p>
                                  )}
                                  <div className="d-grid">
                                    <Button
                                      onClick={handleOtpVerify}
                                      className="btn btn-secondary btn-lg fs-14"
                                    >
                                      Verify*
                                    </Button>
                                  </div>
                                </Form.Group>
                              </Modal.Body>
                            </Modal>

                            {/* Mailbox Modal */}
                            <Modal
                              centered
                              show={showMailboxPopup}
                              onHide={handleCloseMailboxPopup}
                            >
                              <Modal.Header className="border-0 m-auto pb-0">
                                <Modal.Title>
                                  <h1 className="text-12 mb-0 text-center">
                                    Check registered mailbox
                                  </h1>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ padding: "35px" }}>
                                <div className="text-center mb-3">
                                  <Image
                                    src={checkMailboxIcon}
                                    height={54}
                                    alt="Check mailbox icon"
                                  />
                                </div>
                                <h5 className="text-2 text-center mb-3">
                                  Please verify your mail id from the link sent
                                  on the registered email address
                                </h5>
                                <div className="note-area">
                                  <p className="text-secondary">
                                    <span className="text-danger fw-500">
                                      Note:
                                    </span>{" "}
                                    Please be patient with the time taken to
                                    receive the email as it can vary. If you
                                    can’t find the email from Dubai Chambers in
                                    your inbox, please check your “Junk/Spam”
                                    folder.
                                  </p>
                                </div>
                                <div className="d-grid mt-4">
                                  <Button
                                    onClick={handleCloseMailboxPopup}
                                    className="btn btn-secondary btn-lg fs-14"
                                  >
                                    Done
                                  </Button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      )}
                      {errorCounter1 === true && (
                        <div className="form-inner position-relative p-3 pt-4">
                          <Row>
                            <Col lg={12}>
                              <h5 className="text-12 mb-4 overlay-title">
                                Set Password
                              </h5>
                            </Col>

                            <Col md={12} lg={6}>
                              <Form.Group className="mb-3" controlId="password">
                                <Form.Label className="fw-500">
                                  New password
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end position-relative">
                                  <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    className={`form-control-lg bg-white ${errors.password ? "is-invalid" : ""
                                      }`}
                                    placeholder="Pasw*123"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.password}
                                  />
                                  <span
                                    className="position-absolute"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                      cursor: "pointer",
                                      right: errors.password ? "32px" : "10px",
                                      fontSize: "12px",
                                      top: "13px",
                                    }}
                                  >
                                    {/* { showPassword ? <faEyeSlash/> : <faEye/>} */}
                                    {showPassword ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </span>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>

                            <Col md={12} lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="confirmPassword"
                              >
                                <Form.Label className="fw-500">
                                  Confirm password
                                </Form.Label>
                                <div className="mb-0 icon-group icon-group-end position-relative">
                                  <Form.Control
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    name="confirmPassword"
                                    className={`form-control-lg bg-white ${errors.confirmPassword ? "is-invalid" : ""
                                      }`}
                                    placeholder="Pasw*123"
                                    required
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.confirmPassword}
                                  />
                                  <span
                                    className="position-absolute"
                                    onClick={toggleConfirmPasswordVisibility}
                                    style={{
                                      cursor: "pointer",
                                      right: errors.confirmPassword
                                        ? "32px"
                                        : "10px",
                                      fontSize: "12px",
                                      top: "13px",
                                    }}
                                  >
                                    {/* { showConfirmPassword ? <faEyeSlash/> : <faEye/>} */}
                                    {showConfirmPassword ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </span>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                  </Form.Control.Feedback>
                                </div>
                              </Form.Group>
                            </Col>

                            <Col lg={12}>
                              <ul className="ms-3">
                                <li
                                  className={
                                    criteria.minLength ? "text-success" : ""
                                  }
                                >
                                  Minimum 8 characters
                                </li>
                                <li
                                  className={
                                    criteria.uppercase ? "text-success" : ""
                                  }
                                >
                                  At least 1 uppercase (A-Z)
                                </li>
                                <li
                                  className={
                                    criteria.lowercase ? "text-success" : ""
                                  }
                                >
                                  At least 1 lowercase (a-z)
                                </li>
                                <li
                                  className={
                                    criteria.number ? "text-success" : ""
                                  }
                                >
                                  At least 1 number (0-9)
                                </li>
                                <li
                                  className={
                                    criteria.specialChar ? "text-success" : ""
                                  }
                                >
                                  At least 1 special character: @ ! & $ ? % *
                                  (use any out of 7)
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      )}

                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          required
                          type="checkbox"
                          className="text-light-grey mt-3"
                          label={
                            <>
                              I accept the terms
                              <a
                                href="https://www.dubaichamber.com/en/privacy-policy/"
                                className="text-light-blue fw-400"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                Privacy Policy{" "}
                              </a>
                              and
                              <a
                                href="https://www.dubaichamber.com/en/terms-conditions/"
                                className="text-dark-blue  fw-400"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                T&Cs!{" "}
                              </a>
                            </>
                          }
                          onChange={handleCheckboxChange}
                          isInvalid={!!errors.checkboxChecked}
                        />

                        {errors.checkboxChecked && (
                          <span className="text-danger">
                            {" "}
                            "You must accept the terms and conditions"{" "}
                          </span>
                        )}
                      </Form.Group>
                      <Col lg={12}>
                        <div className="d-grid justify-content-center my-4 mb-2">
                          <Button
                            // disabled={
                            //   !(errorCounter === 0 && errorCounter1 === true)
                            // }
                            type="submit"
                            className="w-auto btn-secondary btn btn-lg"
                            onClick={handleSubmit}
                            disabled={loader2}
                          >
                            {loader2 && (
                                      <Spinner
                                        style={{ justifyContent: "center", alignItems: "center" }}
                                        animation="border"
                                        variant="danger"
                                        className="me-2"
                                        size="sm"
                                      />

                                    )}
                            Sign up
                          </Button>
                        </div>
                        <p className="d-flex justify-content-center text-2 text-muted">
                          You already have an account?{" "}
                          <a href="/login" className="text-danger ps-1">
                            Log in
                          </a>
                        </p>
                      </Col>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={6} lg={6} className="bg-light">
            <div className="hero-wrap d-flex align-items-center h-100">
              <div className="login-image-container hero-bg hero-bg-scroll"></div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* {showPopup && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Registration Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>Email : {popupMessage}</Modal.Body>
          <Modal.Body>Password : {popupMessage1}</Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="primary-btn"
              onClick={handleClosePopup}
            >
              Okay!
            </Button>
          </Modal.Footer>
        </Modal>
      )} */}
    </div>
  );
};

export default Register;
