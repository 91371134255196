import React, { useEffect, useState } from "react";
import { Image, Breadcrumb, Row, Col, Card } from 'react-bootstrap';
import PowerbiReport from "./PowerbiReport";
import reportService from "../services/reportService";
import { useLocation, useParams } from "react-router-dom";
import backIcon from "../assets/backIcon.png";
import ReportNotFound from "../assets/report-not-found.png";

const ViewPowerBIDash = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [embedData, setEmbedData] = useState(null);
  const [error, setError] = useState(false);

  const [productTableName, setProductTableName] = useState("");
  const [productTableColumnName, setProductTableColumnName] = useState("");
  const [productTableFilterOperator, setProductTableFilterOperator] =
    useState("Contains");
  const [products, setProducts] = useState([]);
  const [productVisualName, setProductVisualName] = useState('');

  useEffect(() => {
    if (state && state.productTableName) {
      setProductTableName(state.productTableName);
    }
    if (state && state.productTableColumnName) {
      setProductTableColumnName(state.productTableColumnName);
    }
    if (state && state.productTableFilterOperator) {
      setProductTableFilterOperator(state.productTableFilterOperator);
    }
    if (state && state.products) {
      setProducts(state.products);
    }
    if (state && state.productVisualName) {
      setProductVisualName(state.productVisualName);
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    await reportService
      .getReport(id)
      .then((res) => {
        setEmbedData(res);
      })
      .catch((error) => {
        setError(true);
        console.log("error", error);
      });
  };

  return (
    <>
      {embedData !== null && !error && (
        <PowerbiReport
          embedData={embedData}
          productTableName={productTableName}
          productTableColumnName={productTableColumnName}
          productTableFilterOperator={productTableFilterOperator}
          products={products}
          productVisualName={productVisualName}
        />
      )}
      {error && embedData === null && (
        <>
          <div className="content page-wrapper pb-5">
            <div className="page-content">
              <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
                <Breadcrumb className="mb-0">
                  <Breadcrumb.Item>
                    <Image
                      className="me-1"
                      src={backIcon}
                      style={{
                        width: "16px",
                        cursor: "pointer",
                        verticalAlign: "text-bottom",
                      }}
                      onClick={() => window.history.back()}
                      alt="back"
                    />{" "}
                    <a
                      onClick={() => {
                        window.history.back();
                      }}
                      className="fw-400"
                    >
                      Home
                    </a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{state?.title}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              
              
              <Row className="justify-content-center">
                <Col lg={12}>
                <Card className="outer-card text-center h-100">
                  <Image style={{maxWidth:'100%',width:'100px'}} className="mx-auto mt-4" src={ReportNotFound} alt="Report Not Found"/>
                  <p className="ms-3 fs-6 mt-3 text-center pb-4">Report Not Found !</p>
                </Card>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewPowerBIDash;
