import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Image, Form, Nav } from "react-bootstrap";
import "../../../src/dashboard.css";
import preview from '../../assets/icons/view.png'
import { useNavigate } from "react-router-dom";
import researchDataService from "../../services/researchDataService";

const CustomCard = ({
  title,
  description,
  blobLocation,
  imageSrc,
  altText,
  onPreview,
  checkbox = false,
  showPreviewButton = true,
}) => {
  const navigate = useNavigate();
  const [docUrl, setDocUrl] = useState('');

  useEffect(() => {
    if (blobLocation !== 'NoPreviewFound' && !docUrl) {
      try {
        researchDataService.downloadBlob(blobLocation).then((res) => {
          const base64Data = res?.data?.data
          if (base64Data) {
            // Convert base64 to a Blob
            const byteCharacters = atob(base64Data);
            const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const docUrl = URL.createObjectURL(blob);
            setDocUrl(docUrl);
          }
        })
        .catch((error) => {
          setDocUrl('');
        })
      } catch (error) {
        console.error(error);
      }
    }
  }, [docUrl, blobLocation]);

  return (
    <Col lg={4} sm={6} className="d-flex mb-4">
      {checkbox && (
        <Form.Check
        checked={checkbox.checked}
          type="checkbox"
          className="checkbox-custom mt-0"
          onChange={checkbox.onChange}
        />
      )}
      <Card className="custom-card inner-card w-100">
        <Card.Body className="p-0">
          <Row>
            <Col lg={12}>
              <div className="card-header-section">
                <div>
                  <Card.Title className="card-title custom-card-title">
                    {title}
                  </Card.Title>
                </div>
              </div>
              <div className="card-image">
                <Image src={docUrl ? docUrl : imageSrc} alt="Report" className="image-hover" />
                <div className="preview-link">
                  <Image
                    src={preview}
                    alt="Click to Preview"
                    className="preview-image"
                  />
                  <Nav.Link  onClick={(e) => {
                      e.preventDefault();
                      navigate({ pathname: `/ViewReport/${blobLocation}` }, {
                        state: { title }
                      })
                    }} className="text-danger p-0 fw-400">
                    Click to Preview
                  </Nav.Link>
                </div>
              </div>

              <Card.Text className="card-text">{description}</Card.Text>
            </Col>
          </Row>
         
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CustomCard;
