import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Row,
  Form,
  Pagination,
  Badge,
  Button,
  Modal,
  ToastContainer,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CustomCardForSunscribedReport from "../components/ui/CustomCardForSunscribedReport";
import reportImage from "../assets/dc-report.png";
import preview from "../assets/icons/view.png";
import "../dashboard.css";
import userService from "../services/userService";
import subscriptionService from "../services/subscriptionService";
import { useNavigate } from "react-router-dom";
import countryImage from "../assets/country-fact-sheet.png";
import businessImage from "../assets/business-analitics.png";
import tdmImage from "../assets/TDM.png";
import LogoutAndRedirect from "./LogoutAndRedirect";
import productService from "../services/productService";
import reportService from "../services/reportService";
import researchDataService from "../services/researchDataService";
import reportImage2 from "../assets/pdf.png";
import CustomCardWithProduct from "../components/ui/CustomCardWithProduct";
import CustomCard from "../components/ui/CustomCard";
import CustomCardForData from "../components/ui/CustomCardForData";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import cardLoader from "../assets/loading-chain.gif";

const Resubscribe = () => {
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [researchData, setResearchData] = useState([]);
  const [sortOrder, setSortOrder] = useState("a-z"); // For sorting selection
  const [mappedDataForCards, setMappedDataForCards] = useState([]);
  const [mappedDataForResearch, setMappedDataForResearch] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [cardDataForReport, setCardDataForReport] = useState([]);
  const [cardDataForResearch, setCardDataForResearch] = useState([]);

  //const [mappedDataForCard, setMappedDataForCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [dataResponse, setDataResponse] = useState([""]);
  const [checkedCards, setCheckedCards] = useState(new Set());
  const [checkedCardsForData, setCheckedCardsForData] = useState(new Set());
  const [products, setProducts] = useState([]);
  const [reportId, setReportId] = useState("");
  const [productSelections, setProductSelections] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [requestBody, setRequestBody] = useState([]);
  const [reports, setReports] = useState([]);
  const [excludedReports, setExcludedReports] = useState([]);
  const [excludedProducts, setExcludedProducts] = useState([]);
  const [excludedResearchData, setExcludedResearchData] = useState([]);
  const [activeTab, setActiveTab] = useState("report");
  const [disable, setDisable] = useState(false);
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");

  useEffect(() => {
    fetchData();
    fetchAllData();
  }, []);

  const fetchData = async () => {
    const email = localStorage.getItem("email");
    await userService.getUserByEmail(email).then(async (res) => {
      let userId = res.data.id;
      try {
        const apiResponse = await subscriptionService
          .getSubscriptionByUserId(res.data.id)
          .then((apiResponse) => {
            if (
              apiResponse.statusCode === 200 &&
              apiResponse?.data?.subscriptionId !== 0
            ) {
              setSubscriptionId(apiResponse?.data?.subscriptionId);
              setReportData(apiResponse?.data?.reports);
              console.log(
                "apiResponse?.data?.reports?",
                apiResponse?.data?.reports
              );
              const mappedCardData = apiResponse?.data?.reports?.map(
                (report) => ({
                  products: report.product,
                  biId: report.id,
                  blobLocation: report.sampleImage,
                  id: apiResponse.data.subscriptionId,
                  title: report.reportName,
                  imageSrc: cardLoader,
                  // report.reportName.trim() === `Country Fact Sheet`
                  //   ? countryImage
                  //   : report.reportName.trim() ===
                  //     "Dubai Chambers Members' Exports"
                  //   ? businessImage
                  //   : report.reportName.trim() ===
                  //     "Trade Data Monitoring Report"
                  //   ? tdmImage
                  //   : reportImage,
                  previewSrc: preview,
                  description: `${report.reportDescription}. `,
                  buttonLink: `/ViewReport/${report.id}`,
                  createdAt: report.createdAt, // Assuming you have createdAt in your data
                  productTableName: report.productTableName,
                  productTableColumnName: report.productTableColumnName,
                  productTableFilterOperator: report.productTableFilterOperator,
                  productVisualName: report.productVisualName,
                })
              );

              const mappedResearchData = apiResponse?.data?.researchData?.map(
                (item) => ({
                  id: item.id,
                  blobLocation: item.blobLocation,
                  title: item.fileName,
                  imageSrc: cardLoader,
                  // item?.fileName?.trim() === `Country Fact Sheet`
                  //   ? countryImage
                  //   : item?.fileName?.trim() ===
                  //     "Dubai Chambers Members' Exports"
                  //   ? businessImage
                  //   : item?.fileName?.trim() ===
                  //     "Trade Data Monitoring Report"
                  //   ? tdmImage
                  //   : reportImage,
                  previewSrc: preview,
                  description: `${item.fileDescription}.`,
                  buttonLink: `/ViewResearchData/${item.id}`,
                  createdAt: item.createdAt, // Assuming you have createdAt in your data
                  thumbnailBlobLocation: item.thumbnailBlobLocation,
                  thumbnailFileName: item.thumbnailFileName,
                })
              );
              console.log("mappedCardData", mappedCardData);
              setCardData(mappedCardData);
              setResearchData(mappedResearchData);
            } else {
              navigate("/subscription");
            }
          });
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    });
  };
  const mapReportsWithProducts = (reports, products) => {
    return reports.map((report) => {
      const mappedProducts = report.productIds
        .map((productId) => {
          const product = products.find((prod) => prod.id === productId);
          return product ? { id: product.id, name: product.productName } : null;
        })
        .filter(Boolean);
      return {
        reportId: report.id,
        title: report.reportName,
        description: report.reportDescription,
        sampleImage: report.sampleImage,
        products: mappedProducts,
      };
    });
  };

  const fetchAllData = async () => {
    try {
      let reportList = await reportService.listReports();
      let productList = await productService.listProducts();
      let mappedDataafterfunction = mapReportsWithProducts(
        reportList?.data,
        productList?.data
      );
      setMappedDataForCards(mappedDataafterfunction);
      // console.log("aaaaaaaaaaaaa",mappedDataafterfunction)
      // filterDataForNewSub(mappedDataafterfunction, researchData);
      let researchDataList = await researchDataService.listReports();
      setMappedDataForResearch(researchDataList?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(
      "mappedDataForCards,reportData ",
      mappedDataForCards,
      reportData
    );
    if (mappedDataForCards && researchData) {
      filterDataForNewSub();
    }
  }, [mappedDataForCards, reportData]);

  const filterDataForNewSub = () => {
    console.log("reportdata", reportData);
    console.log("mapped data", mappedDataForCards);
    const reportIds = reportData?.map((report) => report.id);
    console.log("reportIds", reportIds);

    const filteredMappedData = mappedDataForCards
      .map((mappedItem) => {
        const matchingReport = reportData.find(
          (report) => report.id === mappedItem.reportId
        );

        if (matchingReport) {
          // Check if the matching report has products
          if (matchingReport.product.length > 0) {
            // Remove the products from `mappedItem` that are present in `matchingReport.product`
            console.log(
              "mappedItem and matchingReport ",
              mappedItem,
              matchingReport
            );

            const filteredProducts = mappedItem.products.filter(
              (product) =>
                !matchingReport.product.some(
                  (matchingProduct) => matchingProduct.id === product.id
                )
            );

            console.log("Filtered Products:", filteredProducts);

            // Return the report with remaining products
            if (filteredProducts && filteredProducts.length) {
              return { ...mappedItem, products: filteredProducts };
            }
          }
          // If no products, exclude this report entirely
          return null;
        }
        // If no matching report in `reportData`, keep the report as is
        return mappedItem;
      })
      .filter((item) => item !== null); // Remove null values (reports that were excluded)
    setCardDataForReport(filteredMappedData);
    console.log("filteredMappedData", filteredMappedData);
  };

  useEffect(() => {
    if (mappedDataForResearch && researchData) {
      console.log(
        "mappedDataForResearch,researchData",
        mappedDataForResearch,
        researchData
      );
      filterResearchDataForNewSub();
    }
  }, [mappedDataForResearch, researchData]);

  const filterResearchDataForNewSub = () => {
    // Extract the IDs from researchData to identify matching entries
    const researchIds = researchData.map((research) => research.id);
    console.log("researchIds", researchIds);

    // Filter out items from mappedDataForResearch that have a corresponding ID in researchData
    const filteredMappedData = mappedDataForResearch.filter(
      (mappedItem) => !researchIds.includes(mappedItem.id)
    );
    setCardDataForResearch(filteredMappedData);
    console.log("filteredMappedData", filteredMappedData);
  };

  // Function to handle sorting
  const handleSortChange = (value) => {
    setSortOrder(value);
  };

  // Function to sort card data
  const sortCards = (cards) => {
    switch (sortOrder) {
      case "a-z":
        return cards.sort((a, b) => a.title.localeCompare(b.title));
      case "z-a":
        return cards.sort((a, b) => b.title.localeCompare(a.title));
      case "latest":
        return cards.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "oldest":
        return cards.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      default:
        return cards;
    }
  };

  const previewImage = () => {
    console.log("preview clicked");
  };

  const handleCheckboxSelect = (card, isChecked) => {
    const cardId = card.reportId;
    setCheckedCards((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);
      if (isChecked) {
        newCheckedCards.add(cardId);
      } else {
        newCheckedCards.delete(cardId);
      }
      return newCheckedCards;
    });

    const cardInfo = { id: card.reportId, name: card.title }; // Include cardId and name

    setReports((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);

      if (isChecked) {
        newCheckedCards.add(cardInfo); // Store both id and name
      } else {
        newCheckedCards.forEach((item) => {
          if (item.id === cardInfo.id) {
            newCheckedCards.delete(item); // Find and delete the card based on id
          }
        });
      }
      return newCheckedCards;
    });

    // If unchecking, remove any product selections for this card
    if (!isChecked) {
      setProductSelections((prev) => {
        const newSelections = { ...prev };
        delete newSelections[cardId];
        return newSelections;
      });
    }
  };

  const selectProduct = (card) => {
    setReportId(card.reportId);
    setProducts(card.products);
    // console.log("productSelections????????????", productSelections.length);
    const selectedReport =
      productSelections &&
      productSelections.length !== undefined &&
      productSelections?.length !== 0
        ? productSelections.find(
            (selection) => selection.reportId === card.reportId
          )
        : "";
    if (selectedReport) {
      setSelectedProducts(selectedReport.products);
    } else {
      setSelectedProducts([]);
    }
    setShowModal(true);
  };

  const handleSelectAllProducts = (filteredProducts, flag) => {
    if (flag) {
      filteredProducts.forEach((product) => {
        if (
          selectedProducts.findIndex(
            (selectedProduct) => selectedProduct.id === product.id
          ) === -1
        ) {
          handleCheckboxChange(product, flag);
        }
      });
    } else {
      setSelectedProducts([]);
      setProductSelections([]);
    }
  };

  const handleCheckboxChange = (product, isChecked) => {
    setSelectedProducts((prevSelected) => {
      let newSelected;
      // prevSelected.find((item) => item.)
      if (isChecked) {
        newSelected = [...prevSelected, product];
      } else {
        newSelected = prevSelected.filter((item) => item !== product);
      }
      console.log("newSelected.length", newSelected.length);
      // if (newSelected.length > 5) {
      //   setValidationErrors((prev) => ({
      //     ...prev,
      //     [reportId]: "You can select at most 5 products.",
      //   }));
      // } else {
      //   setValidationErrors((prev) => {
      //     const newErrors = { ...prev };
      //     delete newErrors[reportId];
      //     return newErrors;
      //   });
      // }
      return newSelected;
    });
  };

  useEffect(() => {
    saveSession();
  }, [productSelections]);

  const handleSave = () => {
    setProductSelections((prevCheckedCards) => {
      const prevArray = Array.from(prevCheckedCards);
      const existingReport = prevArray.find(
        (item) => item.reportId === reportId
      );
      if (existingReport) {
        existingReport.products = selectedProducts;
      } else {
        prevArray.push({
          reportId: reportId,
          products: selectedProducts,
        });
      }
      setSelectedProducts([]);
      return prevArray;
    });
    delete validationErrors[reportId];
    setShowModal(false);
  };
  //   console.log("product selection", productSelections)

  const handleCheckboxSelectForData = (card, isChecked) => {
    const cardDetails = { id: card.id, fileName: card.fileName };

    setCheckedCardsForData((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);

      if (isChecked) {
        newCheckedCards.add(cardDetails);
      } else {
        newCheckedCards.forEach((checkedCard) => {
          if (
            checkedCard.id === card.id &&
            checkedCard.fileName === card.fileName
          ) {
            newCheckedCards.delete(checkedCard);
          }
        });
      }
      return newCheckedCards;
    });
  };

  const saveSession = async () => {
    let hasError = false;
    const newErrors = {};

    reports?.forEach((cardId) => {
      const card = cardDataForReport?.find((c) => c.reportId === cardId.id);
      if (card && card.products.length > 0) {
        if (productSelections && productSelections.length > 0) {
          const selectedProductsForCard =
            productSelections?.find(
              (selection) => selection?.reportId === cardId.id
            )?.products || [];
          if (selectedProductsForCard.length === 0) {
            newErrors[
              cardId.id
            ] = `Please select atleast 1 filter for this report.`;
            hasError = true;
          }
        } else {
          newErrors[
            cardId.id
          ] = `Please select atleast 1 filter for this report.`;
          hasError = true;
        }
      }
    });
    console.log("newErrors", newErrors);

    setValidationErrors(newErrors);

    // if (!hasError) {
    // Proceed with saving the session
    const email = localStorage.getItem("email");
    const res = await userService.getUserByEmail(email);
    const userId = res?.data?.id;
    const expirationDate = Date.now() + 90 * 24 * 60 * 60 * 1000;
    const createdBy = localStorage.getItem("email");
    const modifiedBy = localStorage.getItem("email");

    const reportProductMapping = Array.from(reports).map((cardId) => ({
      reportId: cardId.id,
      reportName: cardId.name,
      productIds:
        productSelections && productSelections.length > 0
          ? (
              productSelections.find(
                (selection) => selection.reportId === cardId.id
              )?.products || []
            ).map((product) => ({
              id: product.id,
              name: product.name,
            }))
          : [], // Fallback if productSelections is not available or no matching reportId
    }));
    console.log("reportProductMapping", reportProductMapping);

    const filteredReportProductMapping = reportProductMapping.filter((report) => {
      // Find the corresponding report in mappedReportForCards
      const mappedReport = mappedDataForCards.find(
        (mapped) => mapped.reportId === report.reportId
      );
    
      // If the mapped report has products, ensure the report in reportProductMapping also has productIds
      return !(mappedReport && mappedReport.products.length > 0 && report.productIds.length === 0);
    });

    console.log("reportProductMapping", filteredReportProductMapping);
    

    const requestBody = {
      userId,
      reportProductMapping: filteredReportProductMapping,
      expirationDate,
      createdBy,
      modifiedBy,
    };

    setRequestBody((prevReq) => requestBody);
    // setActiveTab("researchData");
    // }

    if (checkedCardsForData && checkedCardsForData.size) {
      setRequestBody((prev) => ({
        ...prev,
        researchDataIds: Array.from(checkedCardsForData),
      }));
    }
  };

  const handleSaveForData = async () => {
    setRequestBody((prev) => ({
      ...prev,
      researchDataIds: Array.from(checkedCardsForData),
      // Ensure it's an array
    }));
  };

  const handleToggleResearch = (dataId) => {
    setExcludedResearchData((prev) =>
      prev.includes(dataId)
        ? prev.filter((id) => id !== dataId)
        : [...prev, dataId]
    );
    // When research data is unchecked, exclude all products under this research
    setExcludedProducts((prev) =>
      prev.filter((item) => item.researchId !== dataId)
    );
  };
  const handleToggleReport = (reportId) => {
    setExcludedReports((prev) =>
      prev.includes(reportId)
        ? prev.filter((id) => id !== reportId)
        : [...prev, reportId]
    );
    // Remove all associated products when a report is unchecked
    setExcludedProducts((prev) =>
      prev.filter((item) => item.reportId !== reportId)
    );
  };
  const handleToggleProduct = (reportId, productId) => {
    setExcludedProducts((prev) => {
      const reportExcluded = prev.find((item) => item.reportId === reportId);

      if (reportExcluded) {
        const newProductIds = reportExcluded.productIds.includes(productId)
          ? reportExcluded.productIds.filter((id) => id !== productId)
          : [...reportExcluded.productIds, productId];

        if (newProductIds.length === 0) {
          return prev.filter((item) => item.reportId !== reportId);
        } else {
          return prev.map((item) =>
            item.reportId === reportId
              ? { ...item, productIds: newProductIds }
              : item
          );
        }
      } else {
        return [...prev, { reportId, productIds: [productId] }];
      }
    });
  };

  const transformRequestBody = async (input) => {
    // console.log("sub id", subscriptionId)
    const email = localStorage.getItem("email");
    const res = await userService.getUserByEmail(email);
    return {
      id: subscriptionId,
      userId: input.userId ? String(input.userId) : res?.data?.id,
      reportProductMapping:
        input.reportProductMapping &&
        input.reportProductMapping
          .filter((report) => !excludedReports.includes(report.reportId)) // Exclude unchecked reports
          .map((report) => ({
            reportId: report.reportId,
            productIds: report.productIds
              .map((product) => String(product.id))
              .filter((productId) => {
                // Check if the product is not excluded in excludedProducts
                const excludedForReport = excludedProducts.find(
                  (excluded) => excluded.reportId === report.reportId
                );
                return excludedForReport
                  ? !excludedForReport.productIds.includes(productId)
                  : true;
              }),
          })),
      researchDataIds: input?.researchDataIds
        ?.filter((data) => !excludedResearchData.includes(data.id)) // Filter out unchecked research data
        .map((data) => data.id),
      createdBy: "userAdmin",
      modifiedBy: "userAdmin",
    };
  };

  const handleSubscribe = async () => {
    setDisable(true);

    const transformedBody = await transformRequestBody(requestBody);
    // console.log(
    //   "transformedBody",
    //   transformedBody,
    //   "subscriptionId",
    //   subscriptionId
    // );
    await subscriptionService
      .createSubscription(transformedBody)
      .then((res) => {
        console.log(res);
        // window.location.reload()
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
        });

        window.location.href = "/MySubscription";
        //  navigate('/MySubscription');

        //setDisable(false)
      })
      .catch((error) => {
        setDisable(false);
        console.log("Error", error);
      });
  };

  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  // State to track which section is expanded ("subscriptions" or "explore")
  const [expandedSection, setExpandedSection] = useState("explore");

  const toggleExpand = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const filteredProducts = products.length
    ? products.filter((product) =>
        product.name.toLowerCase().includes(productSearchQuery.toLowerCase())
      )
    : [];

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper pb-5">
        {/* <LogoutAndRedirect /> */}

        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Row>
                  <Col lg={10} md={10}>
                    <div className="card-title-danger d-flex align-items-center">
                      <h3
                        className="outer-card-title"
                        onClick={() => toggleExpand("subscriptions")}
                        style={{ cursor: "pointer" }}
                      >
                        {expandedSection === "subscriptions" ? (
                          <IoIosArrowDropdownCircle className="me-1" />
                        ) : (
                          <IoIosArrowDroprightCircle className="me-1" />
                        )}
                        My Subscriptions
                      </h3>
                    </div>
                  </Col>
                  {expandedSection === "subscriptions" && (
                    <>
                      {cardData && cardData.length !== 0 && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <div className="card-title-danger d-flex align-items-center mb-4 mt-4">
                                <h3 className="outer-card-title">
                                  Business Reports
                                </h3>
                              </div>
                            </Col>
                          </Row>
                          {sortCards(cardData).map((card, index) => (
                            <CustomCardForSunscribedReport
                              subscribed={true}
                              key={index}
                              {...card}
                              buttonLabel={"View"}
                            />
                          ))}
                        </>
                      )}

                      {researchData && researchData.length !== 0 && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <div className="card-title-danger d-flex align-items-center mb-4 mt-3">
                                <h3 className="outer-card-title">
                                  Research Data
                                </h3>
                              </div>
                            </Col>
                          </Row>{" "}
                          {sortCards(researchData).map((card, index) => (
                            <CustomCardForSunscribedReport
                              subscribed={true}
                              key={index}
                              {...card}
                              buttonLabel={"Download"}
                            />
                          ))}
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="page-content pt-0">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Row>
                  <Col lg={12} md={12}>
                    <div className="card-title-danger d-md-flex align-items-center justify-content-between">
                      <h3
                        className="outer-card-title"
                        onClick={() => toggleExpand("explore")}
                        style={{ cursor: "pointer" }}
                      >
                        {expandedSection === "explore" ? (
                          <IoIosArrowDropdownCircle className="me-1" />
                        ) : (
                          <IoIosArrowDroprightCircle className="me-1" />
                        )}
                        Explore More
                      </h3>

                      <div className="d-md-flex mb-0 mt-2 mt-md-0">
                        <strong
                          style={{ fontSize: "12px", marginRight: "2px" }}
                        >
                          Note:{" "}
                        </strong>
                        <div>
                          <p className="" style={{ fontSize: "11px" }}>
                            1. Select the checkbox next to the report's and
                            choose product if required.
                          </p>
                          <p style={{ fontSize: "11px" }}>
                            2. Click "Pin to Subscribe" and click on "Subscribe
                            Now" to finish the subscription.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {expandedSection === "explore" && (
                  <Tabs
                    id="uncontrolled-tab-example"
                    className="mb-4 mt-3"
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key)}
                  >
                    <Tab eventKey="report" title="Business Report">
                      <>
                        <Row>
                          {cardDataForReport !== null &&
                          cardDataForReport !== undefined &&
                          cardDataForReport?.length !== 0 ? (
                            <>
                              {cardDataForReport?.map((card, index) =>
                                card?.products?.length > 0 ? (
                                  <>
                                    {card?.products &&
                                      card?.products?.length > 0 && (
                                        <CustomCardWithProduct
                                          error={
                                            validationErrors[card.reportId]
                                          }
                                          key={index}
                                          title={card.title}
                                          description={card.description}
                                          imageSrc={cardLoader}
                                          altText={card.altText}
                                          onSelectProduct={() =>
                                            selectProduct(card)
                                          }
                                          blobLocation={
                                            card.sampleImage !== ""
                                              ? card.sampleImage
                                              : "NoPreviewFound"
                                          }
                                          thumbnailBlobLocation={
                                            card.thumbnailBlobLocation !== ""
                                              ? card.thumbnailBlobLocation
                                              : "NoPreviewFound"
                                          }
                                          onPreview={previewImage}
                                          checkbox={{
                                            checked: checkedCards.has(
                                              card.reportId
                                            ),
                                            onChange: (e) =>
                                              handleCheckboxSelect(
                                                card,
                                                e.target.checked
                                              ),
                                          }}
                                        />
                                      )}
                                  </>
                                ) : (
                                  <CustomCard
                                    key={index}
                                    title={card.title}
                                    description={card.description}
                                    blobLocation={
                                      card.sampleImage !== ""
                                        ? card.sampleImage
                                        : "NoPreviewFound"
                                    }
                                    imageSrc={
                                      cardLoader
                                      // card.title === `Country Fact Sheet`
                                      //   ? countryImage
                                      //   : card.title ===
                                      //     `Dubai Chambers Members' Exports`
                                      //   ? businessImage
                                      //   : reportImage
                                    }
                                    altText={card.altText}
                                    onPreview={previewImage}
                                    checkbox={{
                                      checked: checkedCards.has(card.reportId),
                                      onChange: (e) =>
                                        handleCheckboxSelect(
                                          card,
                                          e.target.checked
                                        ),
                                    }}
                                  />
                                )
                              )}
                            </>
                          ) : (
                            <div className="d-flex justify-content-center mb-3">
                              <p>No data found</p>
                            </div>
                          )}
                        </Row>
                        {cardDataForReport !== null &&
                          cardDataForReport !== undefined &&
                          cardDataForReport?.length !== 0 && (
                            <Row className="align-items-center">
                              <Col lg={12}>
                                <Button
                                  variant="btn"
                                  className="btn-light ms-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    saveSession();
                                  }}
                                >
                                  Pin for Subscription
                                </Button>
                              </Col>
                            </Row>
                          )}
                      </>
                    </Tab>

                    <Tab eventKey="researchData" title="Research Data">
                      <Row>
                        {cardDataForResearch.length !== 0 ? (
                          <>
                            {cardDataForResearch.map((report) => (
                              <CustomCardForData
                                key={report.id}
                                title={report.fileName}
                                description={report.fileDescription}
                                blobLocation={
                                  report.blobLocation !== ""
                                    ? report.blobLocation
                                    : "NoPreviewFound"
                                }
                                imageSrc={cardLoader}
                                onPreview={() => console.log("Preview clicked")}
                                checkbox={{
                                  onChange: (e) =>
                                    handleCheckboxSelectForData(
                                      report,
                                      e.target.checked
                                    ),
                                }}
                                imageStyle={{ objectFit: "none" }}
                                thumbnailBlobLocation={
                                  report.thumbnailBlobLocation
                                }
                              />
                            ))}
                          </>
                        ) : (
                          <div className="d-flex justify-content-center mb-3">
                            <p>No Data Found</p>
                          </div>
                        )}
                      </Row>
                      {cardDataForResearch.length !== 0 && (
                        <Row className="align-items-center">
                          <Col lg={3}>
                            <Button
                              variant="btn"
                              className="btn-light"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSaveForData();
                              }}
                            >
                              Pin for Subscription
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Tab>
                  </Tabs>
                )}
              </Card>
            </Col>
          </Row>
          {requestBody &&
            requestBody.length !== 0 &&
            (requestBody?.reportProductMapping?.length > 0 ||
              requestBody?.researchDataIds?.length > 0) && (
              <Row className="mt-4">
                <Col lg={12}>
                  <div className="card-title-danger d-flex align-items-center mb-3">
                    <h3 className="outer-card-title">Preview</h3>
                  </div>
                </Col>
                <Col lg={12}>
                  <Card className="outer-card">
                    <div className="mb-2">
                      {/* Business Report Section */}
                      {requestBody && requestBody.reportProductMapping && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <div className="card-title-success d-flex align-items-center mb-4">
                                <h3 className="outer-card-title">
                                  Business Report
                                </h3>
                                <Badge variant={""}>My Selection</Badge>
                              </div>
                            </Col>
                          </Row>
                          <Row className="preview-row">
                            {requestBody
                              ? requestBody?.reportProductMapping?.map(
                                  (report, index) => (
                                    <Col
                                      className="mb-2 mb-sm-0"
                                      key={index}
                                      xs={12}
                                      xl={3}
                                      lg={4}
                                      md={6}
                                      sm={6}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label={report.reportName}
                                        checked={
                                          !excludedReports.includes(
                                            report.reportId
                                          )
                                        }
                                        onChange={() =>
                                          handleToggleReport(report.reportId)
                                        }
                                      />
                                      {report.productIds.length > 0 && (
                                        <div className="sub-products mt-2 mt-sm-0">
                                          <Row className="ms-0">
                                            {report.productIds.map(
                                              (product, idx) => (
                                                <Col
                                                  key={idx}
                                                  xs={12}
                                                  xl={6}
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <Form.Check
                                                    type="checkbox"
                                                    label={product.name}
                                                    checked={
                                                      !excludedProducts.some(
                                                        (excluded) =>
                                                          excluded.reportId ===
                                                            report.reportId &&
                                                          excluded.productIds.includes(
                                                            String(product.id)
                                                          )
                                                      )
                                                    }
                                                    onChange={() =>
                                                      handleToggleProduct(
                                                        report.reportId,
                                                        String(product.id)
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              )
                                            )}
                                          </Row>
                                        </div>
                                      )}
                                    </Col>
                                  )
                                )
                              : ""}
                          </Row>
                        </>
                      )}
                      {requestBody && requestBody.researchDataIds && (
                        <>
                          <Row className="mt-3">
                            <Col lg={12}>
                              <div className="card-title-success d-flex align-items-center mb-4">
                                <h3 className="outer-card-title">
                                  Research Data
                                </h3>
                                <Badge variant={""}>My Selection</Badge>
                              </div>
                            </Col>
                          </Row>
                          <Row className="preview-row">
                            {requestBody?.researchDataIds?.map(
                              (research, index) => (
                                <Col key={index} xs={12} md={3}>
                                  <Form.Check
                                    type="checkbox"
                                    label={research.fileName}
                                    checked={
                                      !excludedResearchData.includes(
                                        research.id
                                      )
                                    }
                                    onChange={() =>
                                      handleToggleResearch(research.id)
                                    }
                                  />
                                </Col>
                              )
                            )}
                          </Row>{" "}
                        </>
                      )}

                      {/* Subscribe Button */}
                      <Row className="mt-4">
                        <Col>
                          <Button
                            className="fw-500 fs-14"
                            variant=""
                            onClick={(e) => {
                              e.preventDefault();
                              handleSubscribe();
                            }}
                            disabled={disable}
                            // title="You can only click this button once"
                          >
                            Subscribe Now
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
        </div>
      </div>

      <Modal
        className="product-modal product-popup"
        show={showModal}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
      >
        {/* <Modal.Header closeButton>
</Modal.Header> */}
        <Modal.Body className="p-0">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group
              style={{ padding: "10px 10px", position: "relative" }}
              controlId="formBasicSearch"
            >
              <svg
                style={{ position: "absolute", top: "18px", left: "20px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.5835 14.5831L18.3335 18.3331"
                  stroke="#093B60"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                  stroke="#093B60"
                  stroke-width="1.25"
                  stroke-linejoin="round"
                />
              </svg>
              <Form.Control
                type="text"
                className="form-search-field"
                placeholder="Search"
                value={productSearchQuery}
                onChange={(e) => setProductSearchQuery(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                style={{ margin: "0 10px" }}
                className="border:0"
                label="Select All"
                onChange={(e) => {
                  handleSelectAllProducts(filteredProducts, e.target.checked);
                }}
                checked={selectedProducts.length === filteredProducts.length}
              />
            </Form.Group>
            <div className="product-list">
              {filteredProducts.map((product, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  className="cus-form-check-lable"
                  label={product.name}
                  onChange={(e) => {
                    handleCheckboxChange(product, e.target.checked);
                  }}
                  checked={selectedProducts.includes(product)}
                />
              ))}
            </div>
          </Form>
          {validationErrors[reportId] !== undefined &&
            validationErrors[reportId] !== null && (
              <Modal.Body style={{ font: "small-caption", color: "red" }}>
                {validationErrors[reportId] || null}
              </Modal.Body>
            )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button variant="" className="btn w-100 m-0" onClick={handleSave}>
            Save for Subscription
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Resubscribe;
