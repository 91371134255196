import axios from "axios";
import CryptoJS from "crypto-js";
import authHeader from "./AuthHeader";
import {BI_CONFIGURE, GET_BI_CONFIG} from "./apiConfig";
  import roles from "../request/role";

  class BiConfigureService {
    createBiConfig(reportData) {
      return axios
        .post(BI_CONFIGURE, reportData, {
          headers: authHeader(),
        })
        .then((response) => {
          return response.data;
        });
    }

    getAllConfiguration(){
      return axios.get(GET_BI_CONFIG, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
    }

  }
  
  export default new BiConfigureService();
  