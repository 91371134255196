import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Image, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import editIcon from "../assets/icons/edit.png";
import biConfigureService from "../services/biConfigure";
import { ToastContainer, toast } from "react-toastify";
import LogoutAndRedirect from "./LogoutAndRedirect";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

const BiIntegration = () => {
  const navigate = useNavigate();
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [tenantID, setTenantId] = useState("");
  const [clientIDError, setClientIDError] = useState("");
  const [clientSecretError, setClientSecretError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderCancel, setLoaderCancel] = useState(false);
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    await biConfigureService.getAllConfiguration().then((res) => {
      console.log("res", res);
      if (res) {
        if (res?.data && res?.data.length) {
          res.data.sort((a, b) => b.id - a.id);
          setClientSecret(res?.data[0]?.appSecret);
          setClientID(res?.data[0]?.clientID);
          setTenantId(res?.data[0]?.tenantID);
        }
      }
    });
  };

  // Validation Functions
  const validateClientID = (clientId) => {
    const clientIdRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return clientIdRegex.test(clientId);
  };

  const validateClientSecret = (clientSecret) => {
    const clientSecretRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{32,128}$/;
    return clientSecretRegex.test(clientSecret);
  };

  // Handlers with Validation
  const handleClientIDChange = (e) => {
    const inputValue = e.target.value;
    if (!validateClientID(inputValue)) {
      setClientIDError("Invalid Client ID format. Please use a valid UUID.");
    } else {
      setClientIDError(""); // Clear error if valid
    }
    setClientID(inputValue);
  };

  const handleClientSecretChange = (e) => {
    const inputValue = e.target.value;

    if (!validateClientSecret(inputValue)) {
      setClientSecretError(
        "Invalid Client Secret. Must be 32-128 characters with uppercase, lowercase, number, and symbol."
      );
    } else {
      setClientSecretError(""); // Clear error if valid
    }
    setClientSecret(inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const e1 = validateClientID(clientID);
    const e2 = validateClientSecret(clientSecret);
    if (!e1) {
      setClientIDError("Invalid Client ID format. Please use a valid UUID.");
    }
    if (!e2) {
      setClientSecretError(
        "Invalid Client Secret. Must be 32-128 characters with uppercase, lowercase, number, and symbol."
      );
    }
    if (e1 && e2) {
      setShowModal(true);
    }
  };

  const handleConfirm = async () => {
    setLoader(true);
    setDisable(!disable);
    // setEdit(!edit);
    setShowModal(false); // Close the modal

    await biConfigureService
      .createBiConfig({
        appSecret: clientSecret,
        clientID: clientID,
        tenantID: tenantID,
        createdBy: localStorage.getItem("email"),
        updatedBy: localStorage.getItem("email"),
      })
      .then((res) => {
        console.log("res", res);
        if (res?.statusCode === 201) {
          toast.success("Successfully Configured", {
            position: "top-center",
            autoClose: 3000,
          });
          setTimeout(() => {
            // window.location.href = "/BiIntegration"; // Refresh the page
            navigate('/BiIntegration');
          }, 3000);
        }
      })
      .catch((error) => {
        toast.error("Error Occurred", {
          position: "top-center",
          autoClose: 3000,
        });
        setTimeout(() => {
          // window.location.href = "/BiIntegration"; // Refresh the page
          navigate('/BiIntegration');
        }, 3000);
      }).finally(() => {
        setLoader(false); // Stop loader
      });
  };

  const handleCancel = () => {
    setLoader(true);
    setShowModal(false); // Close the modal
    setClientID(""); // Reset client ID
    setClientSecret(""); // Reset client secret
    setLoader(false);
  };

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="mb-0 fw-500">BI Integration</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Details
                              </h5>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Client ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="ClientID"
                                  value={clientID}
                                  onChange={handleClientIDChange}
                                  isInvalid={!!clientIDError}
                                  disabled={!edit}
                                  className={`custom-field custom-group-field ${
                                    edit ? "editable-field" : "non-editable-field"
                                  }`} 
                                />
                                <Form.Control.Feedback type="invalid">
                                  {clientIDError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Client Secret
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={clientSecret}
                                  onChange={handleClientSecretChange}
                                  name="ClientSecret"
                                  className={`custom-field custom-group-field ${
                                    edit ? "editable-field" : "non-editable-field"
                                  }`} 
                                  isInvalid={!!clientSecretError}
                                  disabled={!edit}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {clientSecretError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Tenant ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={tenantID}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setTenantId(e.target.value);
                                  }}
                                  name="TanentID"
                                  className={`custom-field custom-group-field ${
                                    edit ? "editable-field" : "non-editable-field"
                                  }`} 
                                  disabled={!edit}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <Row className="mt-4">
                          <Col
                            lg={12}
                            className="add-product-footer-btn text-end"
                          >
                            <Button
                              className="btn-light me-2"
                              // onClick={(e) => {
                              //   window.history.back();
                              // }}
                              href="/biIntegration"
                            >
                              Cancel
                            </Button>
                            {!edit ? (
                              <Button
                                className="btn-light me-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEdit(!edit);
                                }}
                              >
                                <Image
                                  style={{ width: "14px" }}
                                  src={editIcon}
                                  className="me-1"
                                />
                                Edit
                              </Button>
                            ) : (
                              <Button
                                style={{ fontSize: "14px" }}
                                className="btn"
                                onClick={handleSubmit}
                                disabled={disable}
                              >
                                Save
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to submit the configuration?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}
          disabled= {loaderCancel}
          >
          {loaderCancel && (
                              <Spinner
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                animation="border"
                                variant="danger"
                                size="sm" 
                                className="me-2"
                              />
                            )}
            No
          </Button>
          <Button variant="primary" onClick={handleConfirm}
          disabled= {loader}
          >
          {loader && (
                              <Spinner
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                animation="border"
                                variant="danger"
                                size="sm" 
                                className="me-2"
                              />
                            )}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BiIntegration;
