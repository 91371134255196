import React, { useEffect, useState } from "react";
import "../dashboard.css";
import {
  Row,
  Form,
  Pagination,
  Badge,
  Button,
  Modal,
  ToastContainer,
} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import reportImage from "../assets/dc-report.png";
import reportImage2 from "../assets/pdf.png";
import CustomCardWithProduct from "../components/ui/CustomCardWithProduct";
import CustomCard from "../components/ui/CustomCard";
import CustomCardForData from "../components/ui/CustomCardForData";
import preview from "../assets/icons/view.png";
import { decryptData } from "../services/authService";
import productService from "../services/productService";
import reportService from "../services/reportService";
import userService from "../services/userService";
import subscriptionService from "../services/subscriptionService";
import researchDataService from "../services/researchDataService";
import { useNavigate } from "react-router-dom";
import countryImage from "../assets/country-fact-sheet.png";
import businessImage from "../assets/business-analitics.png";
import tdmImage from "../assets/TDM.png";
import { toast } from "react-toastify";
import LogoutAndRedirect from "./LogoutAndRedirect";
import roles from "../request/role";

const Dashboard = () => {
  const [mappedDataForCard, setMappedDataForCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [dataResponse, setDataResponse] = useState([""]);
  const [checkedCards, setCheckedCards] = useState(new Set());
  const [checkedCardsForData, setCheckedCardsForData] = useState(new Set());
  const [products, setProducts] = useState([]);
  const [reportId, setReportId] = useState("");
  const [productSelections, setProductSelections] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [requestBody, setRequestBody] = useState([]);
  const [reports, setReports] = useState([]);
  const [excludedReports, setExcludedReports] = useState([]);
  const [excludedProducts, setExcludedProducts] = useState([]);
  const [excludedResearchData, setExcludedResearchData] = useState([]);
  const [activeTab, setActiveTab] = useState("report");
  const [disable, setDisable] = useState(false);
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [error, setError] = useState("");

  // Filter products based on the product search query
  const filteredProducts = products?.filter((product) =>
    product?.name?.toLowerCase().includes(productSearchQuery?.toLowerCase())
  );

  const navigate = useNavigate();

  const [subscription, setSubscription] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [role, setRole] = useState(roles.MEMBER);
  const [initials, setInitials] = useState("");

  useEffect(() => {
    fetchRole();
    fetchUser();
  }, []);

  const fetchRole = () => {
    let fetchedrole = localStorage.getItem("role");
    let decryptedRole = decryptData(fetchedrole);
    setRole(decryptedRole);
  };

  const fetchUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      await userService
        .getUserByEmail(email)
        .then(async (res) => {
          const initials =
            res.data.firstName.charAt(0) + res.data.lastName.charAt(0);

          setInitials(initials);
          if (res && res.data && res.data.id) {
            await subscriptionService
              .getSubscriptionByUserId(res.data.id)
              .then((res) => {
                // console.log("res", res);
                if (res?.data?.subscriptionId !== 0) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                  setSubscriptionData(res.data);
                } else if (
                  res.data.reports !== null &&
                  res.data.researchData !== null
                ) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                  setSubscriptionData(res.data);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    fetchData();
    loadSessionData();
  }, []);

  const fetchData = async () => {
    try {
      let reportList = await reportService.listReports();
      let productList = await productService.listProducts();
      setProducts(productList.data);
      console.log("reportList", reportList);
      let mappedDataafterfunction = mapReportsWithProducts(
        reportList.data,
        productList.data
      );
      setMappedDataForCards(mappedDataafterfunction);
    } catch (error) {}
    await researchDataService
      .listReports()
      .then((res) => {
        console.log("researchData", res.data.data);
        setDataResponse(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const mapReportsWithProducts = (reports, products) => {
    return reports.map((report) => {
      const mappedProducts = report.productIds
        .map((productId) => {
          const product = products.find((prod) => prod.id === productId);
          return product ? { id: product.id, name: product.productName } : null;
        })
        .filter(Boolean);
      return {
        reportId: report.id,
        title: report.reportName,
        description: report.reportDescription,
        sampleImage: report.sampleImage,
        products: mappedProducts,
      };
    });
  };

  // useEffect(() => {
  //   if (subscription) {
  //     console.log(subscriptionData);
  //     if (
  //       subscriptionData.reports &&
  //       subscriptionData.reports.length
  //     ) {
  //       subscriptionData.reports.forEach((report) => {
  //         setCheckedCards((prevCheckedCards) => {
  //           const newCheckedCards = new Set(prevCheckedCards);
  //           newCheckedCards.add(report.reportID);
  //           return newCheckedCards;
  //         });
  //         setReports((prevCheckedCards) => {
  //           const newCheckedCards = new Set(prevCheckedCards);
  //           newCheckedCards.add({
  //             id: report.reportID,
  //             name: report.reportName,
  //           });
  //           return newCheckedCards;
  //         });
  //       });
  //     }
  //     if (
  //       subscriptionData.researchData &&
  //       subscriptionData.researchData.length
  //     ) {
  //       subscriptionData.researchData.forEach((report) => {
  //         setCheckedCardsForData((prevCheckedCards) => {
  //           const newCheckedCards = new Set(prevCheckedCards);
  //           if (!hasObjectWithSameKey(checkedCardsForData, {
  //             id: report.id,
  //             fileName: report.fileName,
  //           })) {
  //             newCheckedCards.add({
  //               id: report.id,
  //               fileName: report.fileName,
  //             });
  //           }
  //           return newCheckedCards;
  //         });
  //       });
  //     }
  //   }
  // }, [subscription]);

  const handleCheckboxChange = (product, isChecked) => {
    setSelectedProducts((prevSelected) => {
      let newSelected;
      if (isChecked) {
        newSelected = [...prevSelected, product];
      } else {
        newSelected = prevSelected.filter((item) => item !== product);
      }
      console.log("newSelected.length", newSelected.length);
      // if (newSelected.length > 5) {
      //   setValidationErrors((prev) => ({
      //     ...prev,
      //     [reportId]: "You can select at most 5 products.",
      //   }));
      // } else {
      //   setValidationErrors((prev) => {
      //     const newErrors = { ...prev };
      //     delete newErrors[reportId];
      //     return newErrors;
      //   });
      // }
      return newSelected;
    });
  };
  console.log("validationErrors[card.reportId]",validationErrors[reportId])
  // if (validationErrors[card.reportId]) {
  //   setError(validationErrors[card.reportId]);
  // }
  //console.log("SelectedProducts",selectedProducts)

  const handleCheckboxSelect = (card, isChecked) => {
    const cardId = card.reportId;
    setCheckedCards((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);
      if (isChecked) {
        newCheckedCards.add(cardId);
      } else {
        newCheckedCards.delete(cardId);
      }
      return newCheckedCards;
    });

    const cardInfo = { id: card.reportId, name: card.title }; // Include cardId and name

    setReports((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);

      if (isChecked) {
        newCheckedCards.add(cardInfo); // Store both id and name
      } else {
        newCheckedCards.forEach((item) => {
          if (item.id === cardInfo.id) {
            newCheckedCards.delete(item); // Find and delete the card based on id
          }
        });
      }
      return newCheckedCards;
    });

    // If unchecking, remove any product selections for this card
    if (!isChecked) {
      setProductSelections((prev) => {
        const newSelections = { ...prev };
        delete newSelections[cardId];
        return newSelections;
      });
    }
  };

  const handleCheckboxSelectForData = (card, isChecked) => {
    const cardDetails = { id: card.id, fileName: card.fileName };

    setCheckedCardsForData((prevCheckedCards) => {
      const newCheckedCards = new Set(prevCheckedCards);

      if (isChecked) {
        newCheckedCards.add(cardDetails);
      } else {
        newCheckedCards.forEach((checkedCard) => {
          if (
            checkedCard.id === card.id &&
            checkedCard.fileName === card.fileName
          ) {
            newCheckedCards.delete(checkedCard);
          }
        });
      }
      return newCheckedCards;
    });
  };

  useEffect(() => {
    console.log("Checked Cards:", Array.from(checkedCards));
  }, [checkedCards]);

  console.log("error", error);

  const selectProduct = (card) => {
    setReportId(card.reportId);
    setProducts(card.products);
    // console.log("productSelections????????????", productSelections.length);
    const selectedReport =
      productSelections &&
      productSelections.length !== undefined &&
      productSelections?.length !== 0
        ? productSelections.find(
            (selection) => selection.reportId === card.reportId
          )
        : "";
    if (selectedReport) {
      setSelectedProducts(selectedReport.products);
    } else {
      setSelectedProducts([]);
    }
    setShowModal(true);
  };

  const previewImage = (id) => {
   // navigate("/ViewReport/"+id)
    console.log("preview clicked");
  };

  const handleSave = () => {
    setProductSelections((prevCheckedCards) => {
      const prevArray = Array.from(prevCheckedCards);
      const existingReport = prevArray.find(
        (item) => item.reportId === reportId
      );
      if (existingReport) {
        existingReport.products = selectedProducts;
      } else {
        prevArray.push({
          reportId: reportId,
          products: selectedProducts,
        });
      }
      setSelectedProducts([]);
      return prevArray;
    });
    setShowModal(false);
  };

  // console.log("ProductSelections", productSelections);

  const saveSessionData = (productSelections) => {
    const sessionData = {
      checkedCards: Array.from(checkedCards),
      productSelections,
    };
    sessionStorage.setItem("dashboardData", JSON.stringify(sessionData));
  };

  const loadSessionData = () => {
    const savedData = sessionStorage.getItem("dashboardData");
    if (savedData) {
      const {
        checkedCards: savedCheckedCards,
        productSelections: savedProductSelections,
      } = JSON.parse(savedData);
      setCheckedCards(new Set(savedCheckedCards));
      setProductSelections(savedProductSelections);
    }
  };

  const saveSession = async () => {
    let hasError = false;
    const newErrors = {};

    reports?.forEach((cardId) => {
      const card = mappedDataForCard?.find((c) => c.reportId === cardId.id);
      if (card && card.products.length > 0) {
        if (productSelections && productSelections.length > 0) {
          const selectedProductsForCard =
            productSelections?.find(
              (selection) => selection?.reportId === cardId.id
            )?.products || [];
          if (
            selectedProductsForCard.length === 0 
          ) {
            newErrors[
              cardId.id
            ] = `Please select atleast 1 filter for this report.`;
            hasError = true;
          }
        } else {
          newErrors[
            cardId.id
          ] = `Please select atleast 1 filter for this report.`;
          hasError = true;
        }
      }
    });
    console.log("newErrors", newErrors);

    setValidationErrors(newErrors);

    if (!hasError) {
      // Proceed with saving the session
      const email = localStorage.getItem("email");
      const res = await userService.getUserByEmail(email);
      const userId = res?.data?.id;
      const expirationDate = Date.now() + 90 * 24 * 60 * 60 * 1000;
      const createdBy = localStorage.getItem("email");
      const modifiedBy = localStorage.getItem("email");

      const reportProductMapping = Array.from(reports).map((cardId) => ({
        reportId: cardId.id,
        reportName: cardId.name,
        productIds:
          productSelections && productSelections.length > 0
            ? (
                productSelections.find(
                  (selection) => selection.reportId === cardId.id
                )?.products || []
              ).map((product) => ({
                id: product.id,
                name: product.name,
              }))
            : [], // Fallback if productSelections is not available or no matching reportId
      }));
      console.log("reportProductMapping", reportProductMapping);

      const requestBody = {
        userId,
        reportProductMapping,
        expirationDate,
        createdBy,
        modifiedBy,
      };

      setRequestBody(requestBody);
      // setActiveTab("researchData");
      if (
        checkedCardsForData &&
        checkedCardsForData.size
      ) {
        setRequestBody((prev) => ({
          ...prev,
          researchDataIds: Array.from(checkedCardsForData)
        }));
      }
    }
  };

  const handleSaveForData = async () => {
    setRequestBody((prev) => ({
      ...prev,
      researchDataIds: Array.from(checkedCardsForData),
      // Ensure it's an array
    }));
  };

  // console.log("Request Body:", requestBody);

  const handleToggleReport = (reportId) => {
    setExcludedReports((prev) =>
      prev.includes(reportId)
        ? prev.filter((id) => id !== reportId)
        : [...prev, reportId]
    );
    // Remove all associated products when a report is unchecked
    setExcludedProducts((prev) =>
      prev.filter((item) => item.reportId !== reportId)
    );
  };

  const handleToggleProduct = (reportId, productId) => {
    setExcludedProducts((prev) => {
      const reportExcluded = prev.find((item) => item.reportId === reportId);

      if (reportExcluded) {
        const newProductIds = reportExcluded.productIds.includes(productId)
          ? reportExcluded.productIds.filter((id) => id !== productId)
          : [...reportExcluded.productIds, productId];

        if (newProductIds.length === 0) {
          return prev.filter((item) => item.reportId !== reportId);
        } else {
          return prev.map((item) =>
            item.reportId === reportId
              ? { ...item, productIds: newProductIds }
              : item
          );
        }
      } else {
        return [...prev, { reportId, productIds: [productId] }];
      }
    });
  };

  const handleToggleResearch = (dataId) => {
    setExcludedResearchData((prev) =>
      prev.includes(dataId)
        ? prev.filter((id) => id !== dataId)
        : [...prev, dataId]
    );
    // When research data is unchecked, exclude all products under this research
    setExcludedProducts((prev) =>
      prev.filter((item) => item.researchId !== dataId)
    );
  };

  // const transformRequestBody = (input) => {
  //   console.log("input", input);
  //   return {
  //     userId: String(input.userId), // Convert userId to a string
  //     reportProductMapping: input.reportProductMapping.map((report) => ({
  //       reportId: report.reportId,
  //       productIds: report.productIds.map((product) => String(product.id)), // Extract and map only productIds
  //     })),
  //     researchDataIds: input.researchDataIds.map((data) => data.id), // Map only research data IDs
  //     createdBy: "userAdmin", // Set createdBy to a fixed value
  //     modifiedBy: "userAdmin", // Set modifiedBy to a fixed value
  //   };
  // };

  const transformRequestBody = async (input) => {
    const email = localStorage.getItem("email");
    const res = await userService.getUserByEmail(email);
    return {
      userId: input.userId ? String(input.userId) : res?.data?.id,
      reportProductMapping: input.reportProductMapping && 
      input.reportProductMapping
        .filter((report) => !excludedReports.includes(report.reportId)) // Exclude unchecked reports
        .map((report) => ({
          reportId: report.reportId,
          productIds: report.productIds
            .map((product) => String(product.id))
            .filter((productId) => {
              // Check if the product is not excluded in excludedProducts
              const excludedForReport = excludedProducts.find(
                (excluded) => excluded.reportId === report.reportId
              );
              return excludedForReport
                ? !excludedForReport.productIds.includes(productId)
                : true;
            }),
        })),
      researchDataIds: input?.researchDataIds
        ?.filter((data) => !excludedResearchData.includes(data.id)) // Filter out unchecked research data
        .map((data) => data.id),
      createdBy: "userAdmin",
      modifiedBy: "userAdmin",
    };
  };
  const handleSubscribe = async () => {
    setDisable(true);
    const transformedBody =await transformRequestBody(requestBody);
    console.log("transformedBody", transformedBody);
    await subscriptionService
      .createSubscription(transformedBody)
      .then((res) => {
        console.log(res);
        // window.location.reload()
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
        });

        window.location.href = "/MySubscription";
        // navigate('/MySubscription');

        //setDisable(false)
      })
      .catch((error) => {
        setDisable(false);
        console.log("Error", error);
      });
  };

  //------------------------------------------------------------------------------
  // States for Tab 1 (Business Report)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("a-z");
  const cardsPerPage = 6;

  // Handle search change for Tab 1
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  // Handle sorting change for Tab 1
  const handleSortChange = (value) => {
    setSortOrder(value);
    setCurrentPage(1); // Reset to first page on sort change
  };

  // Function to sort the data based on sortOrder
  const sortCards = (cards) => {
    switch (sortOrder) {
      case "a-z":
        return cards.sort((a, b) => a.title.localeCompare(b.title));
      case "z-a":
        return cards.sort((a, b) => b.title.localeCompare(a.title));
      case "latest":
        return cards.sort((a, b) => b.createdAt - a.createdAt); // Sorting by latest (larger timestamp first)
      case "oldest":
        return cards.sort((a, b) => a.createdAt - b.createdAt);
      default:
        return cards;
    }
  };

  // Filter and sort the data for Tab 1
  const filteredCards =
    mappedDataForCard?.length > 0
      ? sortCards(
          mappedDataForCard?.filter((card) =>
            card?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          )
        )
      : [];

  // Calculate total pages and data slice for Tab 1
  const totalPages = Math.ceil(filteredCards?.length / cardsPerPage);
  const startIndex = (currentPage - 1) * cardsPerPage;
  const currentCards = filteredCards?.slice(
    startIndex,
    startIndex + cardsPerPage
  );

  // Handle pagination for Tab 1
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //-------------------------------------------------------------------------

  // States for Tab 2 (Research Data)
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchQuery1, setSearchQuery1] = useState("");
  const cardsPerPage1 = 6;

  // Filter the data for Tab 2 based on search query
  const filteredCards1 =
    dataResponse.length > 0
      ? dataResponse?.filter((card) =>
          card?.fileName?.toLowerCase()?.includes(searchQuery1.toLowerCase())
        )
      : [];

  const totalPages1 = Math.ceil(filteredCards1?.length / cardsPerPage1);
  const startIndex1 = (currentPage1 - 1) * cardsPerPage1;
  const currentCards1 = filteredCards1?.slice(
    startIndex1,
    startIndex1 + cardsPerPage1
  );

  // Handle pagination for Tab 2
  const handlePageChange1 = (pageNumber) => {
    setCurrentPage1(pageNumber);
  };

  // Handle search change for Tab 2
  const handleSearchChange1 = (e) => {
    setSearchQuery1(e.target.value);
    setCurrentPage1(1); // Reset to first page on search change
  };
  //---------------------------------------------------------------------------

  const hasObjectWithSameKey = (inputSet, inputObj) => {
    for (let item of inputSet) {
      if (JSON.stringify(item) === JSON.stringify(inputObj)) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
     {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                
                <div className="d-md-flex justify-content-between align-items-center mb-2">
                <div className="search-tab-control position-relative">
                  <Form.Group
                    style={{ padding: "", position: "relative" }}
                    controlId="formBasicSearch"
                  >
                    <svg
                      style={{
                        position: "absolute",
                        top: "8px",
                        left: "10px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M14.5835 14.5831L18.3335 18.3331"
                        stroke="#093B60"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                        stroke="#093B60"
                        stroke-width="1.25"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Form.Control
                      type="text"
                      className="form-search-field"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => {
                        handleSearchChange(e);
                        handleSearchChange1(e);
                      }} // Update search query on input change
                    />
                  </Form.Group>
                </div>
                <div className="d-md-flex mb-0 mt-2 mt-md-0">
                      <strong style={{fontSize:'12px', marginRight:'2px'}}>Note: </strong>
                      <div>
                      <p className="" style={{fontSize:'11px'}}>
                      1. Select the checkbox next to the report's and choose product if required.</p>
                      <p style={{fontSize:'11px'}}>2. Click "Pin to Subscribe" and click on "Subscribe Now" to finish the subscription.</p>
                      </div>
                      </div>
                      </div>
                <Tabs
                  id="uncontrolled-tab-example"
                  className="mb-4"
                  activeKey={activeTab}
                  onSelect={(key) => setActiveTab(key)}
                >
                  <Tab eventKey="report" title="Business Report">
                    <Row>
                      <Col lg={2} sm={3}>

                        <Form.Select
                          aria-label="Sort by"
                          onChange={(e) => handleSortChange(e.target.value)}
                          className="mb-3 form-search-field form-search-field select-dropdown form-select"
                          value={sortOrder}
                        >
                          <option value="" disabled>
                            Sort By
                          </option>
                          <option value="latest">Latest First</option>
                          <option value="oldest">Oldest First</option>
                          <option value="a-z">A-Z</option>
                          <option value="z-a">Z-A</option>
                        </Form.Select>
                        
                      </Col>
                    </Row>
                    <Row>
                      {currentCards !== null &&
                      currentCards !== undefined &&
                      currentCards?.length !== 0 ? (
                        <>
                          {currentCards?.map((card, index) =>
                            card?.products?.length > 0 ? (
                              <>
                                <CustomCardWithProduct
                                id={card.reportID}
                                  error={validationErrors[card.reportId]}
                                  key={index}
                                  title={card.title}
                                  description={card.description}
                                  imageSrc={tdmImage}
                                  altText={card.altText}
                                  onSelectProduct={() => selectProduct(card)}
                                  onPreview={previewImage}
                                  checkbox={{
                                    checked: checkedCards.has(card.reportId),
                                    onChange: (e) =>
                                      handleCheckboxSelect(
                                        card,
                                        e.target.checked
                                      ),
                                  }}
                                />
                              </>
                            ) : (
                              <CustomCard
                                key={index}
                                title={card.title}
                                description={card.description}
                                imageSrc={
                                  card.title === `Country Fact Sheet`
                                    ? countryImage
                                    : card.title ===
                                      `Dubai Chambers Members' Exports`
                                    ? businessImage
                                    : reportImage
                                }
                                altText={card.altText}
                                onPreview={previewImage}
                                checkbox={{
                                  checked: checkedCards.has(card.reportId),
                                  onChange: (e) =>
                                    handleCheckboxSelect(
                                      card,
                                      e.target.checked
                                    ),
                                }}
                              />
                            )
                          )}
                        </>
                      ) : (
                        <div className="d-flex justify-content-center mb-3">
                          <p>No data found</p>
                        </div>
                      )}
                    </Row>
                    {currentCards !== null &&
                      currentCards !== undefined &&
                      currentCards?.length !== 0 && (
                        <Row className="align-items-center">
                          <Col lg={3}>
                            <Button
                              variant="btn"
                              className="btn-light ms-4"
                              onClick={(e) => {
                                e.preventDefault();
                                saveSession();
                              }}
                            >
                            Pin for Subscription
                            </Button>
                          </Col>

                          <Col lg={9}>
                            <Pagination style={{ justifyContent: "end" }}>
                              <Pagination.First
                                onClick={() => handlePageChange(1)}
                                disabled={currentPage === 1}
                              />
                              <Pagination.Prev
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                disabled={currentPage === 1}
                              />

                              {/* Generate page numbers dynamically */}
                              {[...Array(totalPages)].map((_, pageNumber) => (
                                <Pagination.Item
                                  key={pageNumber + 1}
                                  active={pageNumber + 1 === currentPage}
                                  onClick={() =>
                                    handlePageChange(pageNumber + 1)
                                  }
                                >
                                  {pageNumber + 1}
                                </Pagination.Item>
                              ))}

                              <Pagination.Next
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                disabled={currentPage === totalPages}
                              />
                              <Pagination.Last
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                              />
                            </Pagination>
                          </Col>
                        </Row>
                      )}
                  </Tab>

                  <Tab eventKey="researchData" title="Research Data">
                    <Row>
                      {currentCards1.length !== 0 ? (
                        <>
                          {currentCards1.map((report) => (
                            <CustomCardForData
                              key={report.id}
                              title={report.fileName}
                              description={report.fileDescription}
                              imageSrc={reportImage2}
                              onPreview={() => console.log("Preview clicked")}
                              checkbox={{
                                checked: hasObjectWithSameKey(checkedCardsForData, {
                                  id: report.id,
                                  fileName: report.fileName,
                                }),
                                onChange: (e) =>
                                  handleCheckboxSelectForData(
                                    report,
                                    e.target.checked
                                  ),
                              }}
                              imageStyle={{ objectFit: "none" }}
                            />
                          ))}
                        </>
                      ) : (
                        <div className="d-flex justify-content-center mb-3">
                          <p>No data found</p>
                        </div>
                      )}
                    </Row>
                    {currentCards1.length !== 0 && (
                      <Row className="align-items-center">
                        <Col lg={3}>
                          <Button
                            variant="btn"
                            className="btn-light"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSaveForData();
                            }}
                          >
                            Pin for Subscription
                          </Button>
                        </Col>

                        <Col lg={9}>
                          <Pagination style={{ justifyContent: "end" }}>
                            <Pagination.First
                              onClick={() => handlePageChange1(1)}
                              disabled={currentPage1 === 1}
                            />
                            <Pagination.Prev
                              onClick={() =>
                                handlePageChange1(currentPage1 - 1)
                              }
                              disabled={currentPage1 === 1}
                            />

                            {/* Generate page numbers dynamically */}
                            {[...Array(totalPages1)].map((_, index) => (
                              <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage1}
                                onClick={() => handlePageChange1(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            ))}

                            <Pagination.Next
                              onClick={() =>
                                handlePageChange1(currentPage1 + 1)
                              }
                              disabled={currentPage1 === totalPages1}
                            />
                            <Pagination.Last
                              onClick={() => handlePageChange1(totalPages1)}
                              disabled={currentPage1 === totalPages1}
                            />
                          </Pagination>
                        </Col>
                      </Row>
                    )}
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>

          {requestBody &&
            requestBody.length !== 0 &&
            (requestBody?.reportProductMapping?.length > 0 ||
              requestBody?.researchDataIds?.length > 0) && (
              <Row className="mt-4">
                <Col lg={12}>
                  <div className="card-title-danger d-flex align-items-center mb-3">
                    <h3 className="outer-card-title">Preview</h3>
                  </div>
                </Col>
                <Col lg={12}>
                  <Card className="outer-card">
                    <div className="mb-2">
                      {/* Business Report Section */}
                      {requestBody && requestBody.reportProductMapping && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <div className="card-title-success d-flex align-items-center mb-4">
                                <h3 className="outer-card-title">
                                  Business Report
                                </h3>
                                <Badge variant={""}>My Selection</Badge>
                              </div>
                            </Col>
                          </Row>
                          <Row className="preview-row">
                            {requestBody
                              ? requestBody?.reportProductMapping?.map(
                                  (report, index) => (
                                    <Col
                                      className="mb-2 mb-sm-0"
                                      key={index}
                                      xs={12}
                                      xl={3}
                                      lg={4}
                                      md={6}
                                      sm={6}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label={report.reportName}
                                        checked={
                                          !excludedReports.includes(
                                            report.reportId
                                          )
                                        }
                                        onChange={() =>
                                          handleToggleReport(report.reportId)
                                        }
                                      />
                                      {report.productIds.length > 0 && (
                                        <div className="sub-products mt-2 mt-sm-0">
                                          <Row className="ms-0">
                                            {report.productIds.map(
                                              (product, idx) => (
                                                <Col
                                                  key={idx}
                                                  xs={12}
                                                  xl={6}
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <Form.Check
                                                    type="checkbox"
                                                    label={product.name}
                                                    checked={
                                                      !excludedProducts.some(
                                                        (excluded) =>
                                                          excluded.reportId ===
                                                            report.reportId &&
                                                          excluded.productIds.includes(
                                                            String(product.id)
                                                          )
                                                      )
                                                    }
                                                    onChange={() =>
                                                      handleToggleProduct(
                                                        report.reportId,
                                                        String(product.id)
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              )
                                            )}
                                          </Row>
                                        </div>
                                      )}
                                    </Col>
                                  )
                                )
                              : ""}
                          </Row>
                        </>
                      )}
                      {requestBody && requestBody.researchDataIds && (
                        <>
                          <Row className="mt-3">
                            <Col lg={12}>
                              <div className="card-title-success d-flex align-items-center mb-4">
                                <h3 className="outer-card-title">
                                  Research Data
                                </h3>
                                <Badge variant={""}>My Selection</Badge>
                              </div>
                            </Col>
                          </Row>
                          <Row className="preview-row">
                            {requestBody?.researchDataIds?.map(
                              (research, index) => (
                                <Col key={index} xs={12} md={3}>
                                  <Form.Check
                                    type="checkbox"
                                    label={research.fileName}
                                    checked={
                                      !excludedResearchData.includes(
                                        research.id
                                      )
                                    }
                                    onChange={() =>
                                      handleToggleResearch(research.id)
                                    }
                                  />
                                </Col>
                              )
                            )}
                          </Row>{" "}
                        </>
                      )}

                      {/* Subscribe Button */}
                      <Row className="mt-4">
                        <Col>
                          <Button
                            className="fw-500 fs-14"
                            variant=""
                            onClick={(e) => {
                              e.preventDefault();
                              handleSubscribe();
                            }}
                            disabled={disable}
                            // title="You can only click this button once"
                          >
                            Subscribe Now
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
        </div>
      </div>

      <Modal
        className="product-modal product-popup"
        show={showModal}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group
              style={{ padding: "10px 10px", position: "relative" }}
              controlId="formBasicSearch"
            >
              <svg
                style={{ position: "absolute", top: "18px", left: "20px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.5835 14.5831L18.3335 18.3331"
                  stroke="#093B60"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6665 9.16687C16.6665 5.02474 13.3087 1.66687 9.1665 1.66687C5.02437 1.66687 1.6665 5.02474 1.6665 9.16687C1.6665 13.309 5.02437 16.6669 9.1665 16.6669C13.3087 16.6669 16.6665 13.309 16.6665 9.16687Z"
                  stroke="#093B60"
                  stroke-width="1.25"
                  stroke-linejoin="round"
                />
              </svg>
              <Form.Control
                type="text"
                className="form-search-field"
                placeholder="Search"
                value={productSearchQuery}
                onChange={(e) => setProductSearchQuery(e.target.value)}
              />
            </Form.Group>
            {filteredProducts.map((product, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                className="cus-form-check-lable"
                label={product.name}
                onChange={(e) => {
                  handleCheckboxChange(product, e.target.checked);
                }}
                checked={selectedProducts.includes(product)}
              />
            ))}
          </Form>
         { validationErrors[reportId]!==undefined && validationErrors[reportId]!==null &&  <Modal.Body style={{ font: "small-caption", color: "red" }}>
            {validationErrors[reportId] || null}
          </Modal.Body>}
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0">
          <Button variant="" className="btn w-100 m-0" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Dashboard;
