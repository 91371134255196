// requestBodies.js

// Authentication Request Body
export const loginRequestBody = {
  username: "",
  password: "",
};

// Registration Request Body
export const registrationRequestBody = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  role: "",
  company: "",
  memberNumber: "",
  password: "",
  createdBy: "",
  status: "",
};

// Forgot Password Request Body
export const forgotPasswordRequestBody = {
  emailId: "",
};

// Get User by ID Request Body
export const getUserByIdRequestBody = {
  id: "",
};

// List Users Request Body
export const listUsersRequestBody = {
  pageSize: "",
  pageNo: "",
};

// Create Product Request Body
export const createProductRequestBody = {
  productName: "",
  productDescription: "",
  status: "",
  createdBy: "",
};

// Update Product Request Body
export const updateProductRequestBody = {
  id: "",
  productName: "",
  productDescription: "",
  status: "",
  updatedBy: "",
};

// Create Feedback Request Body
export const createFeedbackRequestBody = {
  userId: "",
  reportId: "",
  researchDataId: "",
  feedbackText: "",
  createdBy: "",
};

// Update Feedback Request Body
export const updateFeedbackRequestBody = {
  id: "",
  feedbackText: "",
  modifiedBy: "",
};

// Create Subscription Request Body
export const createSubscriptionRequestBody = {
  userId: "",
  reportId: "",
  productIds: [""],
  researchDataId: "",
  expirationDate: "",
  createdBy: "",
  modifiedBy: "",
};

// Update Subscription Request Body
export const updateSubscriptionRequestBody = {
  id: "",
  userId: "",
  reportId: "",
  productIds: [""],
  researchDataId: "",
  expirationDate: "",
  createdBy: "",
  modifiedBy: "",
};

// Create Report Request Body
export const createReportRequestBody = {
  reportID: "",
  reportName: "",
  reportDescription: "",
  sampleImage: "",
  workspaceID: "",
  tenantID: "",
  status: "",
  createdBy: localStorage.getItem("email"),
  productIds: [""],
  productTableName: "sales_product",
  productTableColumnName: "product_id",
  productTableFilterOperator: "=",
  createdAt: new Date(),
  updatedAt: new Date(),
  updatedBy: localStorage.getItem("email"),
};

// Update Report Request Body
export const updateReportRequestBody = {
  id: "",
  reportID: "",
  reportName: "",
  reportDescription: "",
  sampleImage: "",
  workspaceID: "",
  tenantID: "",
  status: "",
  createdBy: "",
  productIds: [""],
};

// Create Research Data Request Body
export const createResearchDataRequestBody = {
  blobLocation: "",
  workspaceID: "",
  tenantID: "",
  fileName: "",
  fileDescription: "",
  status: "",
  createdBy: "",
  thumbnailFileName: "",
  thumbnailBlobLocation: "",
};

// Update Research Data Request Body
export const updateResearchDataRequestBody = {
  id: "",
  blobLocation: "",
  blobKey: "",
  workspaceID: "",
  tenantID: "",
  fileName: "",
  fileDescription: "",
  status: "",
  createdBy: "",
};
