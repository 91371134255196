import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "../main.css";
import "../home.css";
// import logo from "../assets/w-logo.png";
import logo from "../assets/dubai-chamber-commerce-small-logo.svg";
// import logo2 from "../assets/logo2.png";
import logo2 from "../assets/logo.png";
import help from "../assets/icons/headphones.png";
import Navbar from "react-bootstrap/Navbar";
import checkMailboxIcon from "../assets/icons/Check-mailbox-icon.png";
import { useSearchParams } from "react-router-dom";
import { VERIFY_EMAIL_WITH_TOKEN } from "../services/apiConfig";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import externalIntegration from "../services/externalIntegration";
import { toast, ToastContainer } from "react-toastify";
const EmailVerify = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  console.log("token", token);
  const [verify, setVerify] = useState(false);
  const [verifyFalse, setVerifyFalse] = useState(false);
  const [input, setInput] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [successSent, setSuccessSent] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [errors, seterror] = useState(false);
  useEffect(() => {
    fetchToken();
  }, [token]);

  const fetchToken = async () => {
    await axios
      .get(VERIFY_EMAIL_WITH_TOKEN + token)
      .then((res) => {
        console.log("res", res);
        if (res?.data?.data?.isVerified) {
          setVerify(true);
        } else {
          setVerifyFalse(true);
        }
      })
      .catch((error) => {
        toast.error(error.message || "Error Occured", {
          position: "top-right",
          autoClose: 3000,
        });
        seterror(true);
        console.log("error", error.message);
      });
  };

  const generateLink = async () => {
    setLoader(true);
    setLoader1(true);
    console.log("email", email);
    externalIntegration
      .generateLinkToken(token)
      .then((res) => {
        setSuccessSent(true);
        toast.success("Link Sent !", {
          position: "top-right",
          autoClose: 3000,
        });
      })
      .catch((error) => {
        toast.error("Error Occured", {
          position: "top-right",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoader(false);
        setLoader1(false);
      });
  };

  return (
    <div className="page-wrap wrap-page d-flex flex-column min-vh-100">
      <ToastContainer />
      <Navbar
        sticky="top"
        expand="lg"
        className="dc-header bg-white headerbg-body-tertiary justify-content-between"
      >
        <Container className="navbar-container">
          <Navbar.Brand href="#home">
            <Image src={logo2} alt="Dubai Chambers Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>

      {/* Main Content */}
      <div className="mainPage flex-grow-1">
        <div
          className="main-content banner-section d-flex align-items-center"
          style={{ width: "100%", height: "500px" }}
        >
          <Container>
            <Row className="align-items-center justify-content-center">
              {!verify && !verifyFalse && !errors && (
                <div
                  style={{
                    fontSize: "150px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Spinner animation="grow" />
                </div>
              )}

              {!verify && !verifyFalse && errors && (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {/* <Row>
                    <span
                      className="fa-solid fa-triangle-exclamation"
                      style={{ fontSize: "150px" }}
                    ></span>
                  </Row> */}
                  <Row>
                    <span style={{ fontSize: "100px" }}>Error!</span>
                  </Row>
                </div>
              )}

              {verify && (
                <>
                <Col xl={5} lg={6} md={8}>
                <div className="bg-white p-5 outer-card" style={{ borderRadius: '8px' }}>
                  <Row
                    className="align-items-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Col lg={12}>
                    {/* <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "80px",
                        marginBottom: "10px",
                      }}
                      className="fa-solid fa-circle-check"
                    ></span> */}
                      <div className="text-center mb-3">
                          <Image
                            src={checkMailboxIcon}
                            height={54}
                            alt="Check mailbox icon"
                          />
                      </div>
                    </Col>
                  </Row>

                  <Row
                    className="align-items-center text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Col lg={12}>
                    <h1 style={{ fontSize: "18px", textAlign: "center", fontWeight: '500' }}>
                      Verified
                    </h1>
                    {/* <Button className="mt-3 w-50" href="/login" style={{ textAlign: "center"}}>Login</Button> */}
                    <p className="text-2 text-muted text-center mt-3">
                        <a  href="/login" className="text-danger">
                          Login
                        </a>
                      </p>
                    </Col>
                  </Row>
                  </div>
                </Col>     
                </>
              )}
              {verifyFalse && (
                <>


                  <Col xl={5} lg={6} md={8}>
                    <div className="bg-white p-5 outer-card" style={{ borderRadius: '8px' }}>
                      <Row
                        className="align-items-center"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="text-center mb-3">
                          <Image
                            src={checkMailboxIcon}
                            height={54}
                            alt="Check mailbox icon"
                          />
                        </div>

                        <h1 className="text-12 mb-0 text-center mb-2 mt-2" style={{ fontSize: '18px', fontWeight: '500' }}>
                          {!input && !loader1 && !successSent
                            ? "Your Verification Link is Expired"
                            : loader1
                              ? "Waiting For Response"
                              : !loader1 && successSent
                                ? ""
                                : "Enter Your Registered Email Address"}
                        </h1>
                      </Row>
                      {!input && (
                        <Row
                          className="align-items-center"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          {!successSent && !loader1 ? (
                            <Button
                              className="align-items-center w-50"
                              style={{ justifyContent: "center" }}
                              onClick={(e) => {
                                e.preventDefault();
                                generateLink();
                                setInput(false);
                                // setInput(true);
                              }}
                            >
                              Resend Link
                            </Button>
                          ) : loader1 ? (
                            <Spinner animation="grow" />
                          ) : (
                            <>
                              <h3 style={{ textAlign: "center", fontSize: "18px" }}>
                                Verification Link Sent Successfully
                              </h3>

                            </>
                          )}
                        </Row>
                      )}
                      {input && (
                        <Row
                          className="align-items-center"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            style={{
                              width: "30%",
                              marginTop: "2px",
                              marginBottom: "2px",
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                          ></input>
                          <br />
                          <Button
                            className="align-items-center"
                            style={{ justifyContent: "center", width: "10%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              generateLink();
                              setInput(false);
                            }}
                            disabled={loader}
                          >
                            {loader && <Spinner />}
                            Submit
                          </Button>
                        </Row>
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="d-flex justify-content-between align-items-center d-flex justify-content-between align-items-center">
        <Container>
          <Row>
            <Col lg={3}>
              <div className="footer-logo">
                <Image src={logo} alt="" />
              </div>
            </Col>
            <Col lg={3}>
              <div>
                <h5 className="mt-4 mt-lg-0 mb-4">Contact Us</h5>
                <div className="d-flex">
                  <i
                    class="fa-solid fa-phone text-white me-2"
                    style={{ fontSize: "12px", lineHeight: "125%" }}
                  ></i>
                  <div>
                    <p>International: (+971) 4 228 0000 </p>
                    <p>Fax: (+971) 4 202 8888 </p>
                    <p>Email: customercare@dubaichamber.com</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <h5 className="mt-4 mt-lg-0 mb-4">Address:</h5>
                <div className="d-flex">
                  <i
                    class="fa-solid fa-location-dot text-white me-2"
                    style={{ fontSize: "12px", lineHeight: "125%" }}
                  ></i>
                  <p>
                    Dubai Chambers, Baniyas Road, Deira, Dubai, U.A.E <br />
                    P.O Box: 1457 <br />
                    Makani: 3035894820
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="mt-4 mt-lg-0">
                <Button
                  style={{ fontSize: "14px" }}
                  className="btn btn-red"
                  href="/Contact"
                >
                  <Image
                    style={{ width: "16px" }}
                    className="me-2"
                    src={help}
                  />
                  Help
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default EmailVerify;
